<mat-dialog-content class="mat-typography">

    <app-form-search-page *ngIf="!ValoresPesquisa" [ValoresSelect]="ValoresSelect" [Tabela]="Formulario.tabela" (EventPesquisar)="activePesquisar()" (ValoresPesquisa)="setValoresPesquisa($event)" (ValidadeFormulario)="setValidadeFormulario($event)">
    </app-form-search-page>

    <app-table-search-page class="table-container" *ngIf="ValoresPesquisa" [ColunasTabela]="ColunasPesquisa" [ValoresTabela]="ValoresPesquisa" (Select)="Select($event)" (ResultadoPesquisa)="setResultadoPesquisa($event)">
    </app-table-search-page>

</mat-dialog-content>
<mat-dialog-actions>
    <button *ngIf="resultadoPesquisa" mat-raised-button color="warn" style="width: 100%" (click)="Voltar()">Voltar</button>

    <button *ngIf="!resultadoPesquisa" style="width: 100%; height: 38px;" mat-raised-button cdk-focus-start (click)="Pesquisar()" color="primary" [disabled]="realizandoPesquisa || !ValidadeFormulario">
        <div *ngIf="!realizandoPesquisa">
          Pesquisar
        </div>
        <div *ngIf="realizandoPesquisa">
          <mat-spinner disabled [diameter]="28" style="margin-left: 45%" class="mat-spinner-color"></mat-spinner>
        </div>
    </button>

</mat-dialog-actions>