export class Regex{

    static cnpj(valor: string, retirar: boolean = false){

        if(retirar){
            return valor.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/, "$1$2$3$4$5")
        }

        if(valor.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/, "$1$2$3$4$5").length == 14){
            return valor.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/, "$1.$2.$3/$4-$5");      
          } else {
            return valor.replace(/(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{1})/, "$1$2$3$4$5");
        }
    }

    static cep(valor: string, retirar: boolean = false){

        if(retirar){
            return valor.replace(/(\d{5})-?(\d{2})/, "$1$2")
        }

        if(/\d{5}-?\d{3}/.test(valor)){
            return valor.replace(/(\d{5})-?(\d{3})/, "$1-$2")
        } else {
            return valor.replace(/(\d{5})-?(\d{2})/, "$1$2")
        }
    }

}