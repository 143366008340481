import { Categoria } from 'src/app/consts/categoria';
import { firstValueFrom } from 'rxjs';
import { RevisaoService } from './../../../../../../services/revisao.service';
import { Produto } from './../../../../../../consts/produtos';
import {
  ClienteProdutoQtd,
  ProdutoRevisao,
} from './../../../../../../consts/revisao';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThrowStmt } from '@angular/compiler';
import { indexOf } from 'lodash';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';

@Component({
  selector: 'app-detalhar-sugestoes',
  templateUrl: './detalhar-sugestoes.component.html',
  styleUrls: ['./detalhar-sugestoes.component.css'],
})
export class DetalharSugestoesComponent implements OnInit {
  dataSourceProdutos: MatTableDataSource<ProdutoRevisao>;
  formularioProdutos: FormGroup;
  ColunasProdutos = [
    'NomeProduto',
    'Codigo',
    'Interno',
    'Ncm',
    'DataCadastro',
    'Excluir',
  ];
  ProdutosExcluidos: ProdutoRevisao[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public formBuilder: FormBuilder,
    public revisaoService: RevisaoService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetalharSugestoesComponent>
  ) {
    this.dataSourceProdutos = new MatTableDataSource(data.Dados);

    this.formularioProdutos = this.formBuilder.group({
      Categoria: data.Categoria.Categoria,
      idCategoria: data.Categoria.CategoriaId,
      QuantidadeProduto: data.Categoria.QuantidadeProduto,
    });
  }

  ngOnInit() {}

  retirarProduto(Produto) {
    this.dataSourceProdutos.data.splice(
      this.dataSourceProdutos.data.indexOf(Produto),
      1
    );
    this.dataSourceProdutos._updateChangeSubscription();

    this.ProdutosExcluidos.push(Produto);
  }

  abrirPaginaErro(tipo, mensagem){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }

  revisarProduto() {
    let arrayRevisar = {
      CategoriaId: this.formularioProdutos.value.idCategoria,
      ListaProdutoId: [],
    };

    let ArrayDeletar = {
      CategoriaId: this.formularioProdutos.value.idCategoria,
      ListaProdutoId: [],
    };

    this.ProdutosExcluidos.forEach((a) => {
      ArrayDeletar.ListaProdutoId.push(a.ProdutoId);
    });
    this.dataSourceProdutos.data.forEach((produto) =>
      arrayRevisar.ListaProdutoId.push(produto.ProdutoId)
    );



    firstValueFrom(this.revisaoService.deleteRevisao(ArrayDeletar)).then(
      (response) => {
        firstValueFrom(
          this.revisaoService.postRevisarProdutos(arrayRevisar)
        ).then((response) => {
          this.abrirPaginaErro('sistema', response)
          if(response){
            let objetoRetorno = {
              formulario: this.formularioProdutos.value,
              produtosRevisados: this.dataSourceProdutos.data
            }
            this.dialogRef.close(objetoRetorno);
          }
        });
      }
    );
  }
}
