import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-departamento-entregaveis',
  templateUrl: './departamento-entregaveis.component.html',
  styleUrls: ['./departamento-entregaveis.component.css']
})
export class DepartamentoEntregaveisComponent implements OnInit {

  formulario: FormGroup
  tipoEntregavel: any[] =
    [{
      Nome: "Pessoa Física",
      Valor: "F"
    },
    {
      Nome: "Pessoa Jurídica",
      Valor: "J"
    }]

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DepartamentoEntregaveisComponent>,
    @Inject(MAT_DIALOG_DATA) public data,) {

    this.formulario = this.formBuilder.group({
      Id: [0],
      Descricao: [""],
      Tipo: [""],
      idDepartamento: [0]
    })
  }

  ngOnInit() {
    this.verificaInject()
  }

  public adicionarEntregavel(): void {
    if (this.formulario.status == "VALID") {
      this.dialogRef.close(this.formulario.value)
    }
    else {
      console.log('preencha todos os campos do formulario')
    }
  }

  public preencheEntregaveis(entregavel): void {

    this.formulario.patchValue({
      Id: entregavel.Id,
      Descricao: entregavel.Descricao,
      Tipo: entregavel.Tipo,
      idDepartamento: entregavel.idDepartamento,
    })
  }
  public fecharJanela(): void {
    this.dialogRef.close()
  }
  public verificaInject(): void {
    if (this.data) {
      this.preencheEntregaveis(this.data.entregavel)
    }
  }
}
