<form [formGroup]="formularioPesquisa">
    <div style="margin: 15px;">
        <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
            <mat-expansion-panel [hideToggle]="false" [expanded]="true">
                <mat-expansion-panel-header>Revisão</mat-expansion-panel-header>
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Cliente</mat-label>
                            <input formControlName="Cliente" matInput type="text" readonly>
                            <button *ngIf="formularioPesquisa.value.Cliente == null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCliente()">
                <mat-icon>search</mat-icon>
              </button>
                            <button *ngIf="formularioPesquisa.value.Cliente != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="apagarCampoCliente()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                            <mat-label>Ncm</mat-label>
                            <input formControlName="Ncm" matInput type="text">
                            <button *ngIf="formularioPesquisa.value.Ncm == null || formularioPesquisa.value.Ncm == ''" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarNcm()">
                              <mat-icon>search</mat-icon>
                            </button>
                            <button *ngIf="formularioPesquisa.value.Ncm != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparNcm()">
                              <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                            <mat-label>Código</mat-label>
                            <input formControlName="Codigo" matInput type="text">
                        </mat-form-field>
                    </div>


                </div>
                <div class="row">
                    <div class="col-sm-2 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Data de inicio</mat-label>
                            <input [matDatepicker]="picker" (focus)="picker.open()" formControlName="DataInicio" matInput readonly>
                            <button *ngIf="formularioPesquisa.value.DataInicio != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparDataInicio()">
                              <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>

                    <div class="align-self-center col-sm-1 col-12">
                        <p class="ate">até</p>
                    </div>


                    <div class="col-sm-2 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Data Final</mat-label>
                            <input [matDatepicker]="picker1" (focus)="picker1.open()" formControlName="DataFim" matInput readonly>
                            <button *ngIf="formularioPesquisa.value.DataFim != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparDataFim()">
                              <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-datepicker #picker1></mat-datepicker>
                    </div>

                    <div class="col-sm-7 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                            <mat-label>Produto</mat-label>
                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let palavra of palavrasChaves" [selectable]="selectable" [removable]="removable" (removed)="remove(palavra)">
                                    {{palavra}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Nova Palavra...(Max: 5 palavras)" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                </div>
                <br>
                <div class="row justify-content-end" style="height: 10%;">
                    <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button color="primary" (click)="getTabela()">Pesquisar</button></div>
                    <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button (click)="limparFiltro()">Limpar Filtro</button></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <mat-card>
        <app-tabela-revisao [dataSourceEmpresas]="dataSourceClientes" [filtroPesquisa]="formularioPesquisa.value">
        </app-tabela-revisao>
        <mat-paginator [pageSizeOptions]="[10,25,100]" aria-label="Selecione nº de Clientes"></mat-paginator>
    </mat-card>
</form>