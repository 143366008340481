<mat-toolbar class="header">

    <div class="header__title">
        <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">arrow_back</mat-icon>
    </button>
        <img src="./assets/login/Logo - Como Tributar.png" alt="logo" class="logoimg" (click)="carregaPrincipal()">
    </div>

    <mat-divider aria-orientation="vertical"></mat-divider>
    <div class="header-toolbar">
        <app-user [usuario]="usuario" (navegarAlterarSenha)="navigateAlterarSenha()" (signOut)="signOut()"></app-user>
        <!-- <h6 style="vertical-align: middle;">{{usuario}}</h6> -->
    </div>

</mat-toolbar>