import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastros',
  template: `<router-outlet> </router-outlet>`,
})
export class CadastrosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
