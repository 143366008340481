<form class="form" [formGroup]="form" (ngSubmit)="login()">
    <div class="white-field">
        <mat-form-field [color]="color" appearance="outline" class="form__input">
            <mat-label>Login</mat-label>
            <input matInput placeholder="Login" type="text" formControlName="Login">
        </mat-form-field>

        <mat-form-field class="form__input" appearance="outline">
            <mat-label>Senha</mat-label>
            <input matInput placeholder="Senha" type="password" formControlName="Senha">
        </mat-form-field>
    </div>
    <div class="form-actions">
        <button class="form-actions__login" style="cursor: pointer !important;" [disabled]="ativaLogin" mat-raised-button color="logoColor" type="submit">Login</button>
    </div>
</form>