import { environment } from 'src/environments/environment.hmr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SistemaService {

  API = environment.API

constructor(private httpCliente: HttpClient) {

}

post(corpo){
  return this.httpCliente.post(`${this.API}Sistema`, corpo)
}

get(id){
  return this.httpCliente.get(`${this.API}Sistema/${id}`)
}

delete(id){
  return this.httpCliente.delete(`${this.API}Sistema/${id}`)
}

}
