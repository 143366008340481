import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Resolve } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { AuthService } from '../auth/services';
import { permissoes, routes } from '../consts';
import { menu } from '../consts'
import { MontaMenuService } from '../services/monta-menu.service';

export interface ItemMenu {
  IdUsuario: number,
  cdFuncao: number,
  dsFuncao: string,
  Permissao: number,
  nComponente: number,
  ListaParametros: Array<any>
}


@Injectable({
  providedIn: 'root'
})

export class PagesResolve implements Resolve<any> {

  public routes: typeof routes = routes;
  public menu = menu
  public permissoes = permissoes

  constructor(private montaMenuService: MontaMenuService,
    private authService: AuthService) {

    this.authService.loggedIn.subscribe(response => this.resetaMenu(response))
  }

  async resolve() {

    this.permissoes.listaPermissoes.forEach(itemMenu => {

      let componente = Math.round(itemMenu.cdFuncao / 1000)

      switch (componente) {
        case 1: this.AdicionarItemMenu("Cadastros", itemMenu)
          break;
        case 2: this.AdicionarItemMenu("Operações", itemMenu)
          break;
        case 3: this.AdicionarItemMenu("Relatórios", itemMenu)
          break;
        case 4: this.AdicionarItemMenu("Segurança", itemMenu)
          break;
      }

      itemMenu.nComponente = componente

    })

  }

  public AdicionarItemMenu(componente, rotina) {
    for (let i = 0; i < this.menu.length; i++) {

      if (this.menu[i].nome == componente) {

        this.menu[i].submenu.push(
          {
            nome: rotina.dsFuncao,
            link: "/pages/" + this.FormatarString(componente) + "/" + this.FormatarString(rotina.dsFuncao)
          }
        )

      }
    }

  }

  public FormatarString(valor) {
    return valor.replace(/ /g, "-").normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase()
  }

  // DECLARAR VARIAVEL PARA CHAMAR ESSA FUNCAO CASO ELA MUDE O VALOR
  public resetaMenu(response) {

    if (response.loggedStatus == false) {
      for (let i = 0; i < this.menu.length; i++) {
        if (menu[i].submenu) {
          menu[i].submenu = []
        }
      }
    }
  }



}

