import { VideoComponent } from 'src/app/shared/custom-components/video/video.component';
import { AuditoriaComponent } from './custom-components/auditoria/auditoria.component';
import { SpinnerComponent } from './custom-components/spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderModule } from './header/header.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { SettingsMenuComponent } from './ui-elements/settings-menu/settings-menu.component';
import { DateMenuComponent } from './ui-elements/date-menu/date-menu.component';
import { LayoutComponent } from './layout/layout.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { EmpresaClienteComponent } from './forms/empresa-cliente/empresa-cliente.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ErrorPageComponent } from './custom-components/error-page/error-page.component';
import { ConfirmPageComponent } from './custom-components/confirm-page/confirm-page.component';
import { MatTableModule } from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import { SearchPageModule } from './custom-components/search-page/search-page.module';
import { FormSearchPageComponent } from './custom-components/search-page/form-search-page/form-search-page.component';
import { PipesModule } from '../pipes/pipes.module';
import { CrudButtonsComponent } from './custom-components/crud-buttons/crud-buttons.component';
import { DepartamentoEntregaveisComponent } from './forms/departamento_entregaveis/departamento-entregaveis.component';
import { InfoUsuarioComponent } from './custom-components/info-usuario/info-usuario.component';

const internalComponentes = [
  FooterComponent,
  SettingsMenuComponent,
  DateMenuComponent,
  LayoutComponent,
  EmpresaClienteComponent,
  CrudButtonsComponent,
  DepartamentoEntregaveisComponent,
  SpinnerComponent,
  AuditoriaComponent,
  VideoComponent
]

const materialModules = [
  MatListModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatSidenavModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatProgressSpinnerModule
]

@NgModule({
  declarations: [
    ...internalComponentes,
    ErrorPageComponent,
    ConfirmPageComponent,
    InfoUsuarioComponent
  ],
  imports: [
    ...materialModules,
    PipesModule,
    HeaderModule,
    SidebarModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SearchPageModule,
],
  exports: [
    HeaderModule,
    SidebarModule,
    SidebarComponent,
    FooterComponent,
    SettingsMenuComponent,
    DateMenuComponent,
    LayoutComponent,
    SearchPageModule,
    EmpresaClienteComponent,
    CrudButtonsComponent,
    InfoUsuarioComponent,
    AuditoriaComponent
  ]
})
export class SharedModule { }
