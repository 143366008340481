import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '../services';
import { Observable } from 'rxjs';
import { routes } from 'src/app/consts';
import { MontaMenuService } from 'src/app/services/monta-menu.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  public routers: typeof routes = routes;

  constructor(
    private router: Router,
    private authService: AuthService,
    private montaMenuService: MontaMenuService
  ) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      await this.montaMenuService.insertPermissoes();
      return true;
    }
    else {
      this.router.navigate([this.routers.LOGIN]);
      return false
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      return true;
    }
    else {
      this.router.navigate([this.routers.LOGIN]);
      return false
    }
  }
}
