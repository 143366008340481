const menu = [
    {
      icone: "home",
      nome: "Principal"
    },
    {
      icone: "create",
      nome: "Cadastros",
      submenu: []
    },
    {
      icone: "laptop",
      nome: "Operações",
      submenu: []
    },
    {
      icone: "laptop",
      nome: "Relatórios",
      submenu: []
    },
    {
      icone: "lock_outline",
      nome: "Segurança",
      submenu: []
    }  
  ]  
export { menu }

