import { Sistema } from './../../../consts/sistema';
import { Cliente } from './../../../consts/cliente';
import { MensagemResposta } from './../../../consts/mensagem';
import { listaTipoDeNegocio } from './../../../consts/ListasSelects';
import { ExternosService } from 'src/app/services/externos.service';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ClienteService } from 'src/app/services/cliente.service';
import { FormValidations } from 'src/app/form-validations/form-validations';
import { CadastrosComponent } from './../cadastros.component';
import { FormGroup, FormBuilder, Form, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { masks } from 'src/app/consts';
import { MatAccordion } from '@angular/material/expansion';
import * as moment from 'moment';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { SistemaService } from 'src/app/services/sistema.service';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
})
export class ClientesComponent implements OnInit {
  public formularioCliente: FormGroup;
  public masks = masks;
  public RevisaoChecked = false;
  public pesquisaCepRealizada: boolean = false;

  public pesquisaSistemaRealizada: boolean = false;

  public listaTipoDeNegocio = listaTipoDeNegocio;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  public step = 0;

  constructor(
    public formBuilder: FormBuilder,
    public clienteService: ClienteService,
    public dialog: MatDialog,
    public externosService: ExternosService,
    public sistemaService: SistemaService
  ) {
    this.formularioCliente = this.formBuilder.group({
      Id: [0],
      Nome: ['', [Validators.required]],
      CNPJ: ['', [Validators.required]],
      Uf: ['', [Validators.required]],
      Endereco: ['', [Validators.required]],
      Bairro: ['', [Validators.required]],
      CEP: ['', [Validators.required]],
      Complemento: [''],
      Cidade: ['', [Validators.required]],
      Email: ['', [Validators.required]],
      TelefonePrimario: ['', [Validators.required]],
      TelefoneSecundario: [''],
      Honorario: [''],
      DataCadastro: [null],
      DataDistrato: [null],
      UsuarioCadastro: [''],
      UsuarioAlteracao: [''],
      QtdLoja: ['', [Validators.required]],
      idSistema: [0],
      Sistema: [''],
      TipoNegocio: ['', [Validators.required]],
      BloqueioRevisao: [false],
      TotaldeProdutos: [0],
    });
  }

  ngOnInit() {
    this.formularioCliente.valueChanges.forEach((a) => {});
  }

  post() {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    this.formataFormularioPost(this.formularioCliente.value);

    firstValueFrom(this.clienteService.post(this.formularioCliente.value)).then(
      (response: MensagemResposta) => {
        if (response) {
          this.abrirPaginaErro('sistema', response);
          if (response[0].Codigo == 'CRM00001') {
            this.limpar();
          }
          dialogRef.close();
        }
      }
    );
  }

  delete() {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})
    firstValueFrom(
      this.clienteService.delete(this.formularioCliente.value.Id)
    ).then((response: MensagemResposta) => {
      if (response) {
        this.abrirPaginaErro('sistema', response);
        if (response[0].Codigo == 'CRM00003') {
          this.limpar();
        }
        dialogRef.close();
      }
    });
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }

  limpar() {
    this.formularioCliente.patchValue({
      Id: 0,
      Nome: '',
      Uf: '',
      Endereco: '',
      Bairro: '',
      CEP: '',
      CNPJ: '',
      Complemento: '',
      Cidade: '',
      Email: '',
      TelefonePrimario: '',
      TelefoneSecundario: '',
      Honorario: '',
      DataCadastro: null,
      DataDistrato: null,
      QtdLoja: 0,
      idSistema: 0,
      Sistema: '',
      TipoNegocio: '',
      BloqueioRevisao: false,
      TotaldeProdutos: 0,
    });
  }

  formataFormularioPost(formulario) {
    //Formata a data
    if (this.formularioCliente.value.DataCadastro != null) {
      let dateCadastro = moment(formulario.DataCadastro).format();
      this.formularioCliente.value.DataCadastro = dateCadastro;
    }

    if (this.formularioCliente.value.DataDistrato != null) {
      let dateDistrato = moment(formulario.DataDistrato).format();
      this.formularioCliente.value.DataDistrato = dateDistrato;
    }
  }

  pesquisarSistema() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Sistemas',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((sistemaEscolhido) => {
        if (sistemaEscolhido) {
          this.pesquisaSistemaRealizada = true
          this.formularioCliente.patchValue({
            Sistema: sistemaEscolhido.Nome,
            idSistema: sistemaEscolhido.Id,
          });
        }
      });
  }

  pesquisarCliente() {
    this.limpar();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Clientes',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((clienteEscolhido) => {
        if (clienteEscolhido) {
          firstValueFrom(this.clienteService.get(clienteEscolhido.Id)).then(
            (response: Array<Cliente>) => {
              this.formataFormulario(response[0]);
              if (response[0].IdSistema != 0) {
                firstValueFrom(
                  this.sistemaService.get(response[0].IdSistema)
                ).then((response: Array<Sistema>) => {
                  this.formularioCliente.patchValue({
                    IdSistema: response[0].Id,
                    Sistema: response[0].Nome,
                  });
                });
              }
            }
          );
        }
      });
  }

  formataFormulario(cliente) {
    this.formularioCliente.patchValue({
      Id: cliente.Id,
      Nome: cliente.Nome,
      Uf: cliente.Uf,
      Endereco: cliente.Endereco,
      Bairro: cliente.Bairro,
      CEP: cliente.CEP,
      CNPJ: cliente.CNPJ,
      Complemento: cliente.Complemento,
      Cidade: cliente.Cidade,
      Email: cliente.Email,
      TelefonePrimario: cliente.TelefonePrimario,
      TelefoneSecundario: cliente.TelefoneSecundario,
      Honorario: cliente.Honorario,
      DataCadastro: cliente.DataCadastro,
      DataAlteracao: cliente.DataAlteracao,
      UsuarioCadastro: cliente.UsuarioCadastro,
      UsuarioAlteracao: cliente.UsuarioAlteracao,
      DataDistrato: cliente.DataDistrato,
      QtdLoja: cliente.QtdLoja,
      idSistema: cliente.idSistema,
      Sistema: cliente.Sistema,
      TipoNegocio: cliente.TipoNegocio,
      BloqueioRevisao: cliente.BloqueioRevisao,
      TotaldeProdutos: cliente.TotaldeProdutos,
    });

    this.formularioCliente.value.DataAlteracao = cliente.DataAlteracao
  }

  public buscarEndereco(event): void {
    event.preventDefault();

    this.externosService
      .getViaCep(this.formularioCliente.value.CEP)
      .subscribe((response: any) => {
        if (response) {
          this.formularioCliente.patchValue({
            Endereco: response.logradouro,
            Complemento: response.complemento,
            Bairro: response.bairro,
            Cidade: response.localidade,
            Uf: response.uf,
          });
        }
      });
  }

  public removerEndereco(): void {
    this.pesquisaCepRealizada = false;

    this.formularioCliente.patchValue({
      CEP: '',
      Endereco: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      UF: '',
      Regiao: '',
    });
  }

  public limparSistema(){
    this.pesquisaSistemaRealizada = false
    this.formularioCliente.patchValue({
      Sistema: '',
      idSistema: 0
    })
  }

  public limparDataDistrato() {
    this.formularioCliente.patchValue({
      DataDistrato: '',
    });
  }
}
