import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.hmr';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  API = environment.API

constructor(private httpClient: HttpClient) {

}

  get(id){
    return this.httpClient.get(`${this.API}Categoria/${id}`)
  }

  post(corpo){
    return this.httpClient.post(`${this.API}Categoria`, corpo)
  }

  delete(id){
    return this.httpClient.delete(`${this.API}Categoria/${id}`)
  }

  postInformativo(corpo){
    return this.httpClient.post(`${this.API}InformativoCategoria`, corpo)
  }

  getInformativo(id){
    return this.httpClient.get(`${this.API}InformativoCategoria/${id}`)
  }

  deleteInformativo(id){
    return this.httpClient.delete(`${this.API}InformativoCategoria/${id}`)
  }
}
