import { Cliente } from './../../../consts/cliente';
import { Usuario } from './../../../consts/usuario';
import { ClienteService } from './../../../services/cliente.service';
import { MensagemResposta } from './../../../consts/mensagem';
import { firstValueFrom } from 'rxjs';
import { UsuarioService } from './../../../services/usuario.service';
import { FormValidations } from 'src/app/form-validations/form-validations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
})
export class UsuariosComponent implements OnInit {
  formulario: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public usuarioService: UsuarioService,
    public dialog: MatDialog,
    public clienteService: ClienteService
  ) {
    this.formulario = this.formBuilder.group({
      Id: [0],
      Nome: [null],
      Login: [null],
      Senha: [null],
      Email: [null],
      idCliente: [0],
      Cliente: [null],
      DataAlteracao: [null],
      UsuarioAlteracao: [null],
      UsuarioCadastro: [null],
      DataCadastro: [null],
      Bloqueado: [0]
    },
    { validators: FormValidations.userChanges() });
  }

  ngOnInit() {
    this.formulario.updateValueAndValidity();
  }

  public post(): void {
    console.log(this.formataPost(this.formulario.value));
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})
    firstValueFrom(this.usuarioService.post([this.formataPost(this.formulario.value)])).then(
      (response: MensagemResposta) => {
        if (response) {
          this.abrirPaginaErro('sistema', response);

          if (response[0].Codigo == 'CRM00001') {
            this.limpar();

          }
          dialogRef.close();
        }
      }
    );
  }

  formataPost(formulario){
    if(formulario.Bloqueado == false){
      formulario.Bloqueado = 0
    }
    else{
      formulario.Bloqueado = 1
    }
    return formulario
  }

  public delete(): void {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    firstValueFrom(this.usuarioService.delete(this.formulario.value.Id)).then(
      (response: MensagemResposta) => {
        if (response) {
          dialogRef.close();
          this.abrirPaginaErro('sistema', response);
          if (response[0].Codigo == 'CRM00003') {
            this.limpar();
          }

        }
      }
    );
  }

  public limpar(): void {
    this.formulario.patchValue({
      Id: 0,
      Nome: null,
      Login: null,
      Senha: null,
      Email: null,
      idCliente: 0,
      Cliente: null,
      DataCadastro: null,
      DataAlteracao: null,
      UsuarioCadastro: null,
      UsuarioAlteracao: null,
      Bloqueado: false
    });
    this.formulario.updateValueAndValidity()
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }

  pesquisarUsuario() {
    this.limpar();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Usuarios',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((usuarioEscolhido) => {
        if (usuarioEscolhido) {
          firstValueFrom(this.usuarioService.get(usuarioEscolhido.Id)).then(
            (response: Usuario) => {
              this.formulario.patchValue({
                Id: response[0].Id,
                Email: response[0].Email,
                Login: response[0].Login,
                Nome: response[0].Nome,
                Senha: response[0].Senha,
                idCliente: response[0].idCliente,
                DataAlteracao: response[0].DataAlteracao,
                UsuarioAlteracao: response[0].UsuarioAlteracao,
                DataCadastro: response[0].DataCadastro,
                UsuarioCadastro: response[0].UsuarioCadastro
              });

              if(response[0].Bloqueado == 1){
                this.formulario.patchValue({
                  Bloqueado: true
                })
              }
              else{
                this.formulario.patchValue({
                  Bloqueado: false
                })
              }

              if (response[0].idCliente == 0) {
                this.formulario.patchValue({
                  Cliente: 'Como Tributar'
                })
              }

              else{
                firstValueFrom(
                  this.clienteService.get(this.formulario.value.idCliente)
                ).then((response: Cliente) => {
                  this.formulario.patchValue({
                    Cliente: response[0].Nome,
                  });
                });

              }
            }
          );
        }
        this.formulario.updateValueAndValidity()
      });

  }

  limparCliente(){
    this.formulario.patchValue({
      idCliente: 0,
      Cliente: null
    })
  }


  pesquisarCliente(){

    const dialogConfig = new MatDialogConfig()

    dialogConfig.data = {
      tabela: 'Clientes',
    };

    this.dialog.open(SearchPageComponent, dialogConfig)
    .afterClosed()
    .subscribe((cliente) => {

      this.formulario.patchValue({
        Cliente: cliente.Nome,
        idCliente: cliente.Id
      })
    })
  }
}
