import { Usuario } from './../../../../consts/usuario';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Component, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';

import { routes } from '../../../../consts';
import { AuthService, EmailService } from 'src/app/auth/services';
import { Email, User } from 'src/app/auth/models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();
  public user$: Observable<User>
  public emails$: Observable<Email[]>
  public routers: typeof routes = routes;
  public usuario;

  constructor(
    private userService: AuthService,
    private emailService: EmailService,
    private router: Router,
    private usuarioService: UsuarioService
  ) {
    // this.user$ = this.userService.getUser();
    this.emails$ = this.emailService.loadEmails();
    firstValueFrom(this.usuarioService.getLoggedUser())
    .then((response: Array<Usuario>) => {
      this.usuario = response[0].Nome
    })
  }

  ngAfterViewInit(): void {
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;

    this.isShowSidebar.emit(this.isMenuOpened);
  }

  public signOut(): void {
    this.userService.signOut();

    this.router.navigate([this.routers.LOGIN]);
  }

  public navigateAlterarSenha(){
    this.router.navigate(['pages/alterar-senha'])
  }

  public carregaPrincipal(): void {
    this.router.navigate(['pages/principal'])
  }
}
