import { firstValueFrom } from 'rxjs';
import { RevisaoService } from './../../../../../services/revisao.service';
import { DetalharSugestoesComponent } from './detalhar-sugestoes/detalhar-sugestoes.component';
import { SugestaoRevisao } from './../../../../../consts/revisao';
import { Cliente } from './../../../../../consts/cliente';
import { MatTableDataSource } from '@angular/material/table';
import {
  Component,
  Inject,
  OnInit,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-tabela-sugestao-cliente',
  templateUrl: './tabela-sugestao-cliente.component.html',
  styleUrls: ['./tabela-sugestao-cliente.component.css'],
})
export class TabelaSugestaoClienteComponent implements OnInit, AfterViewInit {
  ColunasCategorias = ['Categoria', 'QuantidadeProduto', 'Ncm', 'Detalhar'];
  formularioCategorias: FormGroup;
  dataSourceSugestoes: MatTableDataSource<SugestaoRevisao>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  filtroDePesquisa;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public revisaoService: RevisaoService,
    public dialogRef: MatDialogRef<TabelaSugestaoClienteComponent>
  ) {

    this.filtroDePesquisa = data.filtroPesquisa

    this.formularioCategorias = this.formBuilder.group({
      Cliente: [data.Cliente.Nome],
      ClienteId: [data.Cliente.Id],
      QuantidadeSugestao: [data.Cliente.QuantidadeSugestao],
      Ncm: [null],
    });

    this.dataSourceSugestoes = new MatTableDataSource<SugestaoRevisao>(
      data.Dados
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.dataSourceSugestoes.sort = this.sort;
  }

  detalheProduto(categoria) {

    firstValueFrom(
      this.revisaoService.getProdutosSugestao(categoria.CategoriaId, this.formularioCategorias.value.ClienteId)
    ).then((response) => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.data = {
        Cliente: this.formularioCategorias.value,
        Categoria: categoria,
        Dados: response,
      };

      dialogConfig.height = '90Vh';
      dialogConfig.width = '90vw';
      this.dialog
        .open(DetalharSugestoesComponent, dialogConfig)
        .afterClosed()
        .subscribe((response) => {
          if (response) {
            firstValueFrom(this.revisaoService.postSugestaoQtdProdutos(this.filtroDePesquisa))
            .then((response: any) => {
              let totalQtd = 0

              response.forEach(element => {
                totalQtd = totalQtd + element.QuantidadeProduto
              });

              this.formularioCategorias.patchValue({
                QuantidadeSugestao: totalQtd

              });

              this.dataSourceSugestoes = new MatTableDataSource<SugestaoRevisao>(
                response
              )
            })

            this.dataSourceSugestoes.data.splice(this.dataSourceSugestoes.data.indexOf(categoria), 1);
            this.dataSourceSugestoes._updateChangeSubscription()
          }
        });
    });
  }
}
