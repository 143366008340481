<form [formGroup]="formulario">
  <div>
    <mat-form-field class="w-50 mr-2" appearence="outline">
      <mat-label>Entregavel</mat-label>
      <input type="text" formControlName="Descricao" matInput>
    </mat-form-field>
    
    <mat-form-field class="input-select-large ml-2" appearance="fill">
      <mat-label>Tipo Entregavel</mat-label>
      <mat-select formControlName="Tipo">
        <mat-option *ngFor="let option of tipoEntregavel" [value]="option.Valor">{{option.Nome}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <hr>
  <div class="d-flex justify-content-end">
    <button mat-raised-button (click)="adicionarEntregavel()">Adicionar empresa</button>
    <button mat-raised-button color="warn" style="margin-left: 1%;" (click)="fecharJanela()">Fechar</button>
  </div>
</form>
