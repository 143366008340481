import { SemPermissaoComponent } from './sem-permissao/sem-permissao.component';
import { PagesGuard } from './pages-guard.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardPageComponent } from './dashboard/containers';
import { NotFoundComponent } from './not-found/not-found.component';
import { PagesComponent } from './pages.component';
import { PagesResolve } from './pages-resolve';
import { ClientesComponent } from './cadastros/clientes/clientes.component';
import { CadastrosComponent } from './cadastros/cadastros.component';
import { AuthGuard } from '../auth/guards';
import { AuthPageComponent } from '../auth/containers';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { GraficosResolver } from './dashboard/components/graficos/graficos.resolve';


const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'cadastros',
        canActivate:[PagesGuard],
        loadChildren: () => import('./cadastros/cadastros.module').then(m => m.CadastrosModule),
      },
      {
        path: 'seguranca',
        loadChildren: () => import('./seguranca/seguranca.module').then(m => m.SegurancaModule),
      },
      {
        path: 'operacoes',
        loadChildren: () => import('./operacoes/operacoes.module').then(m => m.OperacoesModule),
      },
      {
        path: 'principal',
        pathMatch: 'full',
        component: DashboardPageComponent,
        resolve: {DadosGraficos: GraficosResolver}
      },
      {
        path: 'sem-permissao',
        component: SemPermissaoComponent
      },
      {
        path: 'alterar-senha',
        component: AlterarSenhaComponent
      },
      {
        path: '404',
        component: NotFoundComponent
      },
      {
        path:'**',
        redirectTo: 'login',
        pathMatch: 'full'
      },
    ],
  }];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})

export class PagesRoutingModule {
}
