const jsonSelectTemp = [
    {
        Id: 1,
        Tabela: "PerfilAcesso",
        ListaCampos: [
            {
                Id: 1,
                Coluna: "DSPerfil",
                Campo: "Nome do Perfil",
                TipoDado: "text",
                idTabela: 1
            },
            {
                Id: 1,
                Coluna: "Id",
                Campo: "Id",
                TipoDado: "number",
                idTabela: 1
            },
            {
                Id: 1,
                Coluna: "Usuario",
                Campo: "Usuario",
                TipoDado: "text",
                idTabela: 1
            }
        ]
    }
]

export { jsonSelectTemp }