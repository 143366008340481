import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { AfterContentInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'item-menu',
  templateUrl: './item-menu.component.html',
  styleUrls: ['./item-menu.component.scss'],
})
export class ItemMenuComponent implements OnInit, AfterContentInit {
  @Input('infoMenu') infoMenu;
  public showSubmenu: boolean = false;
  public isShowing = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {}

  public mostraModulo(item): boolean {
      if (!item.submenu || item.submenu.length > 0) {
        return true;
      }
    return false;
  }

  routePrincipal(route){
    if (route.nome == 'Principal') {
      this.router.navigate(['/pages/principal']);
    }
  }

}
