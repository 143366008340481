import { Coluna } from './../../../../consts/graficos';
import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { jsonSelectTemp, rotinas } from 'src/app/consts';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-form-search-page',
  templateUrl: './form-search-page.component.html',
  styleUrls: ['./form-search-page.component.css'],
})
export class FormSearchPageComponent implements AfterContentInit {
  public formulario: FormGroup;
  public rotinas = rotinas;
  public TipoDado = 'text';
  @Input('ValoresSelect') ValoresSelect = [];
  @Input('Tabela') Tabela;
  @Output() ValoresPesquisa = new EventEmitter();
  @Output() ValidadeFormulario = new EventEmitter();
  @Output() EventPesquisar = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public searchService: SearchService
  ) {
    this.CriarFormulario();
    this.EmiteValidadeFormuario();
  }

  async ngAfterContentInit(): Promise<void> {
   await this.preencheCampo();
  }

  public CriarFormulario(): void {
    this.formulario = this.formBuilder.group({
      Coluna: [this.ValoresSelect[0], [Validators.required]],
      Filtro: [''],
    });
  }

  async preencheCampo(): Promise<void> {

    firstValueFrom(this.searchService.getCamposPesquisa(this.Tabela)).then(
      (response) => {
        this.ValoresSelect = response[0].ListaCampos

        this.formulario.patchValue({
          Coluna: response[0].ListaCampos[0].Coluna
        })
        this.formulario.updateValueAndValidity()
      }
    );
  }

  public MudaTipoCampo(evento): void {

    this.ValoresSelect.forEach((cp: any) => {
      if (cp.Coluna == evento) {
        this.formulario.patchValue({
          Filtro: '',
          Coluna: evento
        });
        this.TipoDado = cp.TipoDado;
      }
    });
    this.formulario.updateValueAndValidity()
  }

  public EmitirValoresPesquisa(): void {
    this.ValoresPesquisa.emit(this.formulario.value);
  }

  public EmiteValidadeFormuario(): void {
    this.formulario.statusChanges.subscribe(() =>
      this.ValidadeFormulario.emit(this.formulario.valid)
    );
  }

  public EmitePesquisar(): void{
    this.EventPesquisar.emit(true)
  }
}
