import { AfterContentChecked, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom, observable } from 'rxjs';
import {ChangeDetectorRef } from '@angular/core';

import { TestBed } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { NivelPermissao, permissoes, rotinas, typeMesages } from 'src/app/consts';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { PerfilAcessoService } from 'src/app/services/perfil-acesso.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { FormValidations } from 'src/app/form-validations/form-validations';

interface Parametro{
  Chave: string,
  Descricao: string,
  Componentes: Array<number>
}

export const ListaParametros: Array<Parametro> = [
  {
    Chave: 'VisualizarDocumentosAuditoria',
    Descricao: 'Visualizar documentos disponibilizados pelo departamento de auditoria',
    Componentes: [2001, 2002]
  },
  {
    Chave: 'VisualizarDocumentosFiscal',
    Descricao: 'Visualizar documentos disponibilizados pelo departamento fiscal',
    Componentes: [2001, 2002]
  },
  {
    Chave: 'VisualizarDocumentosDepartamentoPessoal',
    Descricao: 'Visualizar documentos disponibilizados pelo departamento pessoal',
    Componentes: [2001, 2002]
  },
  {
    Chave: 'VisualizarTodosDocumentosColaboradores',
    Descricao: 'Visualizar documentos de outros colaboradores da empresa',
    Componentes: [2001]
  },
]

@Component({
  selector: 'app-perfil-acesso',
  templateUrl: './perfil-acesso.component.html',
  styleUrls: ['./perfil-acesso.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class PerfilAcessoComponent implements OnInit, AfterContentChecked {

  //VARIÁVEIS DE CONTROLE SOBRE AS INFORMAÇÕES DO USUÁRIO QUE ESTÁ UTILIZANDO ESTE COMPONENTE
  public user: any;
  public permissao: NivelPermissao

  //VARIÁVEIS DE CONTROLE DO FORMULÁRIO
  public formulario: FormGroup;

  //VARIÁVEIS DE CONTROLE DA TABELA
  public colunas: string[] = ['dsFuncao', 'Parametro','Permissao'];
  public registros = []
  public expandir: boolean = false;

  public rotinasAdministradorCliente = [
    4001,
    4002,
    2001,
    2002
  ]

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private perfiLAcessoService: PerfilAcessoService,
              private clienteService: ClienteService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private cdref: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.criarFormulario();
    this.determinaPapel();
    this.inserePermissao()
  }

  //#region MÉTODOS DE CONTROLE DO COMPONENTE
  public determinaPapel(): void {

    this.user = this.route.snapshot.data.dadosPerfilAcesso.user

    this.insereClienteUsuario();
    this.insereFuncoesSistema();

  }

  public insereFuncoesSistema(): void{

    let listaPerfilAcesso = []



    for(let i = 0; i < listaPerfilAcesso.length; i++){

      let registro = {
        Id: 0,
        idFuncao: listaPerfilAcesso[i].Id,
        cdFuncao: listaPerfilAcesso[i].cdFuncao,
        idPerfil: 0,
        Permissao: 0,
        dsFuncao: listaPerfilAcesso[i].dsFuncao,
        ListaParametroPermissaoPerfilAcesso: []
      };

      for(let j = 0; j < ListaParametros.length; j++){

        if(ListaParametros[j].Componentes.includes(listaPerfilAcesso[i].cdFuncao)){
          registro.ListaParametroPermissaoPerfilAcesso
            .push({
              Id: 0,
              Chave: ListaParametros[j].Chave,
              Descricao: ListaParametros[j].Descricao,
              Valor: "0",
              idPermissaoPerfilAcesso: 0
            })
        }

      }

      this.registros.push(registro)
    }

    this.registros = [...this.registros]

  }

  public insereClienteUsuario(): void{

    if(this.user[0].idCliente){
      this.procurarClienteEspecifico(this.user[0].idCliente)
    }

  }


  //#endregion

  //#region MÉTODOS DE CONTROLE DO FORMULÁRIO
  public criarFormulario(): void{
    this.formulario = this.formBuilder.group({
      Id: [0],
      DSPerfil: ["", [Validators.required, FormValidations.caracteresEspeciaisParcialValidator]],
      idCliente: [0],
      Cliente: [""],
      ListaPermissoes: [[]]
    })

  }

  public limparFormulario(): void{

    Object.keys(this.formulario.controls).forEach(key => {
      this.formulario.get(key).setErrors(null) ;
    });

    this.registros = []
    this.insereFuncoesSistema();


  }

  public preencherFormulario(perfil: any): void{
    this.formulario.patchValue({
      Id: perfil.Id,
      DSPerfil: perfil.DSPerfil,
      idCliente: perfil.idCliente,
      ListaPermissoes: perfil.ListaPermissoes
    })

    this.preencherTabelaPermissoes(perfil.ListaPermissoes);
    if(perfil.idCliente > 0){
      this.procurarClienteEspecifico(perfil.idCliente);
    }
  }
  //#endregion

  //#region MÉTODOS DE CONTROLE DE PERFIL DE ACESSO
  public procurarPerfilAcesso(): void{
    const dialogConfig = new MatDialogConfig;

    dialogConfig.minWidth = "300px"

    dialogConfig.data = {
      tabela: rotinas.PERFIL_DE_ACESSO,
    };

    this.dialog.open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe(response => this.procurarPerfilAcessoEspecifico(response.Id))
  }

  public procurarPerfilAcessoEspecifico(id: number): void{
    firstValueFrom(this.perfiLAcessoService.get(id))
      .then(response => this.preencherFormulario(response[0]))
  }

  public removerPerfilAcesso(): void{
    this.formulario.patchValue({
      Id: 0,
      DSPerfil: ""
    })

    this.removerCliente();
  }
  //#endregion

  //#region MÉTODOS DO CONTROLE DO CLIENTE
  public procurarCliente(): void{
    const dialogConfig = new MatDialogConfig

    dialogConfig.data = {
      tabela: rotinas.CLIENTE,
    };

    dialogConfig.minWidth = "300px"

    this.dialog.open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe(response => this.adicionarCliente(response))
  }

  public procurarClienteEspecifico(id: number): void{
    firstValueFrom(this.clienteService.get(id))
      .then(response => this.adicionarCliente(response[0]))
  }

  public adicionarCliente(cliente: any): void{
    if(cliente){
      this.formulario.patchValue({
        idCliente: cliente.Id,
        Cliente: cliente.Nome
      })
    }
  }

  public removerCliente(): void{
    this.formulario.patchValue({
      idCliente: 0,
      Cliente: ""
    })
  }
  //#endregion

  //#region MÉTODOS DE CONTROLE DA TABELA
  public preencherTabelaPermissoes(permissoes): void{

    permissoes.forEach(permissao => {
      let index = this.registros.map(registro => registro.cdFuncao).indexOf(permissao.cdFuncao)

      this.registros[index].Id = permissao.Id;
      this.registros[index].Permissao = permissao.Permissao;
      this.registros[index].idPerfil = permissao.idPerfil;

      this.registros[index].ListaParametroPermissaoPerfilAcesso
        .forEach(parametro => {

          let linhaParametro = permissao.ListaParametroPermissaoPerfilAcesso.filter(p => p.Chave == parametro.Chave)

          if(linhaParametro.length > 0){
            parametro.Valor = linhaParametro[0].Valor
            parametro.idPermissaoPerfilAcesso = linhaParametro[0].idPermissaoPerfilAcesso
            parametro.Id = linhaParametro[0].Id
          }

        })

    })

    this.registros = [...this.registros]

  }

  public alterarPermissao(permissao, cdFuncao, indexRegistro): void{

    if(permissao == 0){



      if(this.formulario.value.ListaPermissoes.length > 0){
        this.formulario.value.ListaPermissoes.splice(this.formulario.value.ListaPermissoes.map(p => p.cdFuncao).indexOf(cdFuncao), 1)
      }

      this.registros[indexRegistro].ListaParametroPermissaoPerfilAcesso
        .forEach(p => p.Valor = "0")

      this.registros = [...this.registros]
    }

    if(permissao != 0){



      if(this.formulario.value.ListaPermissoes.map(p => p.cdFuncao).includes(cdFuncao)){
        this.formulario.value.ListaPermissoes[this.formulario.value.ListaPermissoes.map(p => p.cdFuncao).indexOf(cdFuncao)].Permissao = permissao
      } else {
        this.formulario.value.ListaPermissoes
          .push(
            {
              Id: 0,
              idFuncao: this.registros[indexRegistro].idFuncao,
              dsFuncao: this.registros[indexRegistro].dsFuncao,
              cdFuncao: this.registros[indexRegistro].cdFuncao,
              idPerfil: this.formulario.value.Id,
              Permissao: permissao,
              ListaParametroPermissaoPerfilAcesso: []
            }
          )
      }

    }

    this.registros[indexRegistro].Permissao = permissao

  }

  public alterarParametro(permissao, parametro, indexRegistro){

    /*CASO O CHECKBOX DO PARÂMETRO SEJA MARCADO SEM QUE TENHA SIDO ESCOLHIDO UMA PERMISSÃO,
    SERÁ CRIADO UMA PERMISSÃO COM VALOR 1*/
    if(permissao.Permissao == 0){

      this.registros[indexRegistro].Permissao = 1

      this.formulario.value.ListaPermissoes
        .push(
          {
            Id: 0,
            idFuncao: permissao.Id,
            cdFuncao: permissao.cdFuncao,
            idPerfil: this.formulario.value.Id,
            Permissao: 1,
            dsFuncao: permissao.dsFuncao,
            ListaParametroPermissaoPerfilAcesso: []
          }
        )
    }

    let index = this.formulario.value.ListaPermissoes.map(p => p.cdFuncao).indexOf(permissao.cdFuncao)

    /*CASO O PARÂMETRO JÁ ESTEJA NA LISTA DE PARÂMETROS DA PERMISSÃO, ESTE SERÁ EXCLUIDO
    CASO NÃO ESTEJA, SERÁ ADICIONADO*/
    if(this.formulario.value.ListaPermissoes[index].ListaParametroPermissaoPerfilAcesso
        .map(p => p.Chave).includes(parametro.Chave)){

          this.formulario.value.ListaPermissoes[index].ListaParametroPermissaoPerfilAcesso
            .splice(this.formulario.value.ListaPermissoes[index].ListaParametroPermissaoPerfilAcesso
                    .map(p => p.Chave).indexOf(parametro.Chave), 1)

    } else {

      this.formulario.value.ListaPermissoes[index].ListaParametroPermissaoPerfilAcesso
        .push(
          {
            Id: 0,
            Chave: parametro.Chave,
            Valor: '1',
            idPermissaoPerfilAcesso: this.formulario.value.ListaPermissoes[index].Id
          }
        )

    }

  }
  //#endregion

  //#region MÉTODOS DE CRUD DE PERFIL DE ACESSO
  public post(): void {

    firstValueFrom(this.perfiLAcessoService.post([this.formulario.value]))
      .then(r => {
        this.abrirPagMensagem(r, typeMesages.SISTEMA)
        if(r[0].Codigo == 'CRM00001'){
          this.limparFormulario()
        }

      })
      .catch(err => this.abrirPagMensagem(err, typeMesages.ERRO))

  }

  public delete(): void {
    firstValueFrom(this.perfiLAcessoService.delete(this.formulario.value.Id))
      .then(r => {
        this.abrirPagMensagem(r, typeMesages.SISTEMA)
        this.limparFormulario()
      })
      .catch(err => this.abrirPagMensagem(err, typeMesages.ERRO))
  }
  //#endregion

  public abrirPagMensagem(mensagem, tipo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mensagem: mensagem,
      tipo: tipo
    }

    this.dialog.open(ErrorPageComponent, dialogConfig)
  }

  public inserePermissao(){
    switch(permissoes.listaPermissoes.filter(permissao => permissao.cdFuncao == 4001)[0].Permissao){

      case 1: this.permissao = NivelPermissao.VISUALIZAR;
              this.formulario.disable();
              break;
      case 2: this.permissao = NivelPermissao.GRAVAR;
              break;
      case 3: this.permissao = NivelPermissao.EXCLUIR;
              break;

    }
  }

  public validaPermissao(componente?: string): boolean{

    switch(componente){

      case "btnGravar":

        switch(this.permissao){

          case NivelPermissao.VISUALIZAR: return false;
          case NivelPermissao.GRAVAR: return true;
          case NivelPermissao.EXCLUIR: return true;

        }

      case "btnExcluir":

        switch(this.permissao){

          case NivelPermissao.VISUALIZAR: return false;
          case NivelPermissao.GRAVAR: return false;
          case NivelPermissao.EXCLUIR: return true;

        }

      default:

        switch(this.permissao){

          case NivelPermissao.VISUALIZAR: return false;
          case NivelPermissao.GRAVAR: return true;
          case NivelPermissao.EXCLUIR: return true;

        }

    }


  }



}
