import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataSemHora'
})
export class DataSemHoraPipe implements PipeTransform {

  transform(value: string): unknown {

    if(value){
      return value.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/, "$3/$2/$1")
    }

    return value
  }

}
