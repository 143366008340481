import { ListaPalavrasChaves } from './../../../../../../consts/categoria';
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Categoria, ListaCSTICMS, ListasCSTPisCofinsFederal, ListaSituacaoPISCOFINS } from 'src/app/consts/categoria';
import { ListaUF } from 'src/app/consts/ListasSelects';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-detalhar-categoria',
  templateUrl: './detalhar-categoria.component.html',
  styleUrls: ['./detalhar-categoria.component.css']
})
export class DetalharCategoriaComponent implements OnInit {

  ObjetoCategoria: Categoria
  FormCategoria: FormGroup
  palavrasChaves: ListaPalavrasChaves

  readonly separatorKeysCodes = [ENTER, COMMA] as const
  addOnBlur = true

  ListaSituacaoIcms = ListaCSTICMS.SituacaoICMS
  ListasCSTPisCofinsFederal = ListasCSTPisCofinsFederal;
  ListaCSTICMS = ListaCSTICMS;
  ListaSituacaoPISCOFINS = ListaSituacaoPISCOFINS;
  ListaUf = ListaUF;

  constructor(@Inject(MAT_DIALOG_DATA) data,
  public formBuilder:FormBuilder) {

    let Estados = data.Categoria.ListaTributacaoEstadual.map((element) =>
      this.buildTributacaoEstadual(element)
    );

    this.FormCategoria = this.formBuilder.group({
      Id: [data.Categoria.Id],
      Nome: [data.Categoria.Nome],
      ListaPalavraChave: [data.Categoria.ListaPalavraChave],
      Ncm: [data.Categoria.Ncm],
      Cest: [data.Categoria.Cest],
      NaturezaReceita: [data.Categoria.NaturezaReceita],
      AliquotaPisCumulativoEntrada: [data.Categoria.AliquotaPisCumulativoEntrada],
      AliquotaPisNaoCumulativoEntrada: [data.Categoria.AliquotaPisNaoCumulativoEntrada],
      AliquotaCofinsCumulativoEntrada: [data.Categoria.AliquotaCofinsCumulativoEntrada],
      AliquotaCofinsNaoCumulativoEntrada: [data.Categoria.AliquotaCofinsNaoCumulativoEntrada],
      AliquotaPisCumulativoSaida: [data.Categoria.AliquotaPisCumulativoSaida],
      AliquotaPisNaoCumulativoSaida: [data.Categoria.AliquotaPisNaoCumulativoSaida],
      AliquotaCofinsCumulativoSaida: [data.Categoria.AliquotaCofinsCumulativoSaida],
      AliquotaCofinsNaoCumulativoSaida: [data.Categoria.AliquotaCofinsNaoCumulativoSaida],
      CstPisCumulativoEntrada: [data.Categoria.CstPisCumulativoEntrada],
      CstPisNaoCumulativoEntrada: [data.Categoria.CstPisNaoCumulativoEntrada],
      CstCofinsCumulativoEntrada: [data.Categoria.CstCofinsCumulativoEntrada],
      CstCofinsNaoCumulativoEntrada: [data.Categoria.CstCofinsNaoCumulativoEntrada],
      CstPisCumulativoSaida: [data.Categoria.CstPisCumulativoSaida],
      CstPisNaoCumulativoSaida: [data.Categoria.CstPisNaoCumulativoSaida],
      CstCofinsCumulativoSaida: [data.Categoria.CstCofinsCumulativoSaida],
      CstCofinsNaoCumulativoSaida: [data.Categoria.CstCofinsNaoCumulativoSaida],
      SituacaoPisCofinsEntrada: [data.Categoria.SituacaoPisCofinsEntrada],
      SituacaoPisCofinsSaida: [data.Categoria.SituacaoPisCofinsSaida],
      SituacaoCsosnEntrada: [data.Categoria.SituacaoCsosnEntrada],
      SituacaoCsosnSaida: [data.Categoria.SituacaoCsosnSaida],
      CstIcmsEntrada: [data.Categoria.CstIcmsEntrada],
      CstIcmsSaida: [data.Categoria.CstIcmsSaida],
      AliquotaIcmsEntrada: [data.Categoria.AliquotaIcmsEntrada],
      AliquotaIcmsSaida: [data.Categoria.AliquotaIcmsSaida],
      AliquotaIcmsStEntrada: [data.Categoria.AliquotaIcmsStEntrada],
      AliquotaIcmsStSaida: [data.Categoria.AliquotaIcmsStSaida],
      MvaInternoEntrada: [data.Categoria.MvaInternoEntrada],
      MvaInternoSaida: [data.Categoria.MvaInternoSaida],
      MvaExterno12Entrada: [data.Categoria.MvaExterno12Entrada],
      MvaExterno12Saida: [data.Categoria.MvaExterno12Saida],
      MvaExterno4Entrada: [data.Categoria.MvaExterno4Entrada],
      MvaExterno4Saida: [data.Categoria.MvaExterno4Saida],
      SituacaoIcmsEntrada: [data.Categoria.SituacaoIcmsEntrada],
      SituacaoIcmsSaida: [data.Categoria.SituacaoIcmsSaida],
      MvaExterno7Entrada: [data.Categoria.MvaExterno7Entrada],
      MvaExterno7Saida: [data.Categoria.MvaExterno7Saida],
      AliquotaCalculoDesoneracao: [data.Categoria.AliquotaCalculoDesoneracao],
      CsosnEntrada: [data.Categoria.CsosnEntrada],
      CsosnSaida: [data.Categoria.CsosnSaida],
      Fecp: [data.Categoria.Fecp],
      MotivoDesoneracao: [data.Categoria.MotivoDesoneracao],
      ReducaoBaseCalculo: [data.Categoria.ReducaoBaseCalculo],
      TipoDesoneracao: [data.Categoria.TipoDesoneracao],
      DataAlteracao: [data.Categoria.DataAlteracao],
      ListaTributacaoEstadual: this.formBuilder.array(Estados),
    })

    this.palavrasChaves = data.Categoria.ListaPalavraChave

    this.EstadualControls.forEach((a) => {
      a.patchValue({ Uf: this.ListaUf.find((b) => b.idUF == a.value.Uf) });
    });
    this.formataDados()
  }

  ngOnInit() {
  }

  get EstadualControls() {
    return (<FormArray>this.FormCategoria.get('ListaTributacaoEstadual'))
      .controls;
  }

  buildTributacaoEstadual(a): FormGroup {
    return this.formBuilder.group({
      Id: [a.Id],
      CategoriaId: [a.CategoriaId],
      Uf: [a.Uf],
      CstIcmsEntrada: [a.CstIcmsEntrada],
      CstIcmsSaida: [a.CstIcmsSaida],
      AliquotaIcmsEntrada: [a.AliquotaIcmsEntrada],
      AliquotaIcmsSaida: [a.AliquotaIcmsSaida],
      AliquotaIcmsStEntrada: [a.AliquotaIcmsStEntrada],
      AliquotaIcmsStSaida: [a.AliquotaIcmsStSaida],
      MvaInternoEntrada: [a.MvaInternoEntrada],
      MvaInternoSaida: [a.MvaInternoSaida],
      MvaExterno12Entrada: [a.MvaExterno12Entrada],
      MvaExterno12Saida: [a.MvaExterno12Saida],
      MvaExterno4Entrada: [a.MvaExterno4Entrada],
      MvaExterno4Saida: [a.MvaExterno4Saida],
      SituacaoIcmsEntrada: [a.SituacaoIcmsEntrada],
      SituacaoIcmsSaida: [a.SituacaoIcmsSaida],
      MvaExterno7Entrada: [a.MvaExterno7Entrada],
      MvaExterno7Saida: [a.MvaExterno7Saida],
      AliquotaCalculoDesoneracao: [a.AliquotaCalculoDesoneracao],
      CsosnEntrada: [a.CsosnEntrada],
      CsosnSaida: [a.CsosnSaida],
      Fecp: [a.Fecp],
      MotivoDesoneracao: [a.MotivoDesoneracao],
      ReducaoBaseCalculo: [a.ReducaoBaseCalculo],
      SituacaoCsosnEntrada: [a.SituacaoCsosnEntrada],
      SituacaoCsosnSaida: [a.SituacaoCsosnSaida],
      TipoDesoneracao: [a.TipoDesoneracao],
      DataAlteracao: [a.DataAlteracao],
    })
  }

  formataDados(){
    let situacaoPis = this.FormCategoria.get('SituacaoPisCofinsEntrada').value
    let ValorPisCofins = ListaSituacaoPISCOFINS.filter(s => s.Id == situacaoPis)

    this.FormCategoria.patchValue({
      SituacaoPisCofinsEntrada: ValorPisCofins[0].Nome
    })

    this.EstadualControls.forEach(e => {
      e.patchValue({
        SituacaoIcmsEntrada: this.ListaSituacaoIcms.filter(si => si.Id == e.value.SituacaoIcmsEntrada)[0].Nome,
        SituacaoCsosnEntrada: this.ListaCSTICMS.SituacaoCSOSN.filter(si => si.Id == e.value.SituacaoCsosnEntrada)[0].Nome,
        CsosnEntrada: e.value.CsosnEntrada + this.ListaCSTICMS.ListaCSOSN.filter(si => si.Id == e.value.CsosnEntrada)[0].Descricao,
      })

      if(e.value.MotivoDesoneracao != null && e.value.MotivoDesoneracao != 0){
        e.patchValue({
          MotivoDesoneracao: this.ListaCSTICMS.MotivoDesoneracao.filter(si => si.Id == e.value.MotivoDesoneracao)[0].Nome
        })
      }
    })
  }
}
