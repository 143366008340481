import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-select-permissao',
  templateUrl: './select-permissao.component.html',
  styleUrls: ['./select-permissao.component.css']
})
export class SelectPermissaoComponent {

  @Input('valor') valor;
  @Input('desabilitar') desabilitar;
  @Output() onChange = new EventEmitter();

  public opcoes = [
    {
      valor: 0,
      descricao: "Sem Permissão"
    },
    {
      valor: 1,
      descricao: "Consultar"
    },
    {
      valor: 2,
      descricao: "Gravar"
    },
    {
      valor: 3,
      descricao: "Excluir"
    }
  ]

  constructor() {}
 
  teste(){
    this.onChange.emit(this.valor)
  }

}
