import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-usuario',
  templateUrl: './info-usuario.component.html',
  styleUrls: ['./info-usuario.component.css']
})
export class InfoUsuarioComponent implements OnInit {

  @Input('UsuarioCadastro') UsuarioCadastro: string = null;
  @Input('UsuarioAlteracao') UsuarioAlteracao: string = null;
  @Input('DataCadastro') DataCadastro: string = null;
  @Input('DataAlteracao') DataAlteracao: string = null; 

  constructor() { }

  ngOnInit(): void {
  }

}
