import { Component } from '@angular/core';

@Component({
  selector: 'app-operacoes',
  template:`
  <router-outlet></router-outlet>
  `,
})
export class OperacoesComponent {

}
