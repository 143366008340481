import { Coluna } from './../../../consts/graficos';
import { Component, Inject, OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';

interface FormularioPesquisa{
  tabela: string,
  coluna: string
  valor: string,
  condicaoPadrao: string
}

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.css']
})

export class SearchPageComponent implements OnInit, AfterContentInit {

  public ColunasPesquisa = [];
  public ValoresPesquisa = null;
  public ValoresSelect = [];

  public Formulario: FormularioPesquisa;
  public ValidadeFormulario: boolean = false;

  public resultadoPesquisa: boolean = false;
  public realizandoPesquisa: boolean = false;
  public eventoPesquisar: boolean = false;

  @ViewChild('btnRef') buttonRef: MatButton;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<SearchPageComponent>,
    private searchService: SearchService
  ) {

    this.Formulario = {
      tabela: data.tabela,
      coluna: "",
      valor: "",
      condicaoPadrao: data.condicaoPadrao ? data.condicaoPadrao : ""
    }

  }

  ngOnInit(): void {
    firstValueFrom(this.searchService.getCamposPesquisa(this.Formulario.tabela))
    .then((response: any) => {
      this.insereColunasEValores(response);
  })
  }

  ngAfterContentInit(): void {
  }

  public insereColunasEValores(response): void{

    response[0].ListaCampos.forEach(v => this.ColunasPesquisa.push({valor: v.Coluna, visualizacao: v.Coluna, tamanho: v.Tamanho}))

    this.ColunasPesquisa = this.ColunasPesquisa.filter(v => v.tamanho != null)

    // this.ValoresSelect = response.map(v => v.ListaCampos)[0]
  }

  public setValoresPesquisa(valores): void{
    this.Formulario.coluna = valores.Coluna
    this.Formulario.valor = valores.Filtro
  }

  public setValidadeFormulario(validade): void{
    this.ValidadeFormulario = validade;
  }

  public setResultadoPesquisa(pesquisaRealizada): void{
    this.resultadoPesquisa = pesquisaRealizada;
  }

  public Select(valor): void{
    this.dialogRef.close(valor)
  }

  public Voltar(): void{
    this.ValoresPesquisa = null
    this.Formulario.valor = ''
  }

  async Pesquisar(): Promise<any>{
    this.realizandoPesquisa = true;
    this.ValidadeFormulario = !this.ValidadeFormulario

    await firstValueFrom(this.searchService.search(this.Formulario))
      .then(response => this.PreencherDadosTabela(response))
  }

  public PreencherDadosTabela(valores): void{
    this.ValoresPesquisa = valores;
    this.realizandoPesquisa = false
  }

  async activePesquisar(){
    this.realizandoPesquisa = true;
    this.ValidadeFormulario = !this.ValidadeFormulario

    await firstValueFrom(this.searchService.search(this.Formulario))
      .then(response => {
        this.PreencherDadosTabela(response)
      })
  }

  //TableSearch

  //FormSearch

}
