import { IfStmt } from '@angular/compiler';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { permissoes } from 'src/app/consts';

@Component({
  selector: 'app-crud-buttons',
  templateUrl: './crud-buttons.component.html',
  styleUrls: ['./crud-buttons.component.scss']
})
export class CrudButtonsComponent implements OnInit, AfterViewInit {

  @Input("cdComponente") cdComponente: number = 0;

  @Input("labelBotaoPost") labelBotaoPost: string;
  @Input("labelBotaoPut") labelBotaoPut: string;
  @Input("labelBotaoAuxUm") labelBotaoAuxUm: string;

  @Input("disablePost") disablePost: boolean = false;
  @Input("disablePut") disablePut: boolean = false;
  @Input("disableDelete") disableDelete: boolean = false;
  @Input("disableAuxUm") disableAuxUm: boolean = false;

  @Input("showPost") showPost: boolean = true;
  @Input("showPut") showPut: boolean = true;
  @Input("showDelete") showDelete: boolean = true;
  @Input("showAuxUm") showAuxUm: boolean = true;

  @Output() OnClickButtonPost = new EventEmitter();
  @Output() OnClickButtonPut = new EventEmitter();
  @Output() OnClickButtonDelete = new EventEmitter();
  @Output() OnClickButtonAuxUm = new EventEmitter();

  permissao;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
      if(this.cdComponente > 0){
        this.showPost = this.verificaPermissaoGravar() && this.showPost
        this.showPut = this.verificaPermissaoGravar() && this.showPut
        this.showDelete = this.verificaPermissaoExcluir() && this.showDelete
      }
  }
  public verificaPermissaoGravar(): boolean{
    return permissoes.listaPermissoes
      .filter(p => p.Permissao > 1)
      .map(p => p.cdFuncao)
      .includes(this.cdComponente)
  }

  public verificaPermissaoExcluir(): boolean{
    return permissoes.listaPermissoes
      .filter(p => p.Permissao > 2)
      .map(p => p.cdFuncao)
      .includes(this.cdComponente)
  }

  public emitClickButtonPost(event): void{
    this.OnClickButtonPost.emit(event)
  }

  public emitClickButtonPut(event): void{
    this.OnClickButtonPut.emit(event)
  }

  public emitClickButtonDelete(event): void{
    this.OnClickButtonDelete.emit(event)
  }

  public emitClickButtonAuxUm(event): void{
    this.OnClickButtonAuxUm.emit(event)
  }

}
