import { environment } from 'src/environments/environment.hmr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RevisaoService {

  API = environment.API

constructor(private httpCliente: HttpClient) { }

  getClientesQtdProdutos(corpo){
    return this.httpCliente.post(`${this.API}RevisaoProduto/GetQtdRevisaoProduto`, corpo)
  }

  getProdutosCliente(corpo){
    return this.httpCliente.post(`${this.API}RevisaoProduto/GetProdutosRevisao`, corpo)
  }

  getProdutosSugestao(id, idCliente){

    let httpParams = new HttpParams({
      fromObject: {
        CategoriaId: id,
        ClienteId: idCliente
      }
    })
    return this.httpCliente.get(`${this.API}RevisaoProduto/GetProdutosSugestao`, {params: httpParams})
  }

  postRevisarProdutos(corpo){
    return this.httpCliente.post(`${this.API}RevisaoProduto/AtualizaProduto`, corpo)
  }

  postSugestaoQtdProdutos(corpo){
    return this.httpCliente.post(`${this.API}RevisaoProduto/GetQtdSugestaoCategoria`, corpo)
  }

  deleteRevisao(corpo){
    return this.httpCliente.post(`${this.API}RevisaoProduto/DeletaSugestao`, corpo)
  }
}
