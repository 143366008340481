import { MensagemResposta } from './../../consts/mensagem';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Component, OnInit } from '@angular/core';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {

  formAlterarSenha: FormGroup

  constructor(public usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public userService: AuthService) {
      this.formAlterarSenha = this.formBuilder.group({
        SenhaAntigo: [null, Validators.required],
        SenhaNova: [null, Validators.required],
        VerificacaoSenha: [null, Validators.required]
      })
    }

  ngOnInit() {

  }

  postAlterarSenha(){
    firstValueFrom(this.usuarioService.alterarSenha(this.formAlterarSenha.value))
    .then((response: MensagemResposta) => {
        this.abrirPaginaErro('sistema', response)
    })
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }

}
