import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'src/app/auth/models';

import { routes } from '../../../../consts';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  @Input() user: User;
  @Input() usuario: string;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  @Output() navegarAlterarSenha: EventEmitter<void> = new EventEmitter<void>()
  public routes: typeof routes = routes;
  public flatlogicEmail: string = "https://flatlogic.com";

  public signOutEmit(): void {
    this.signOut.emit();
  }

  public navigateAlterarSenha(){
    this.navegarAlterarSenha.emit()
  }
}
