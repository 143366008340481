<form [formGroup]="formularioProdutos">
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-6 col-12">
                <mat-form-field class="f-14" appearance="fill" style="width: 100%">
                    <mat-label>Cliente</mat-label>
                    <input formControlName="Cliente" matInput type="text" readonly>
                </mat-form-field>
            </div>

            <div class="col-sm-6 col-12">
                <mat-form-field class="f-14" appearance="fill" style="width: 100%">
                    <mat-label>Quantidade de produtos para revisão</mat-label>
                    <input formControlName="QuantidadeProduto" matInput type="text" readonly>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-12">
            <mat-form-field class="f-14" appearance="fill" style="width: 100%">
                <mat-label>Filtrar Produtos</mat-label>
                <input formControlName="Filtrar" matInput type="text">
            </mat-form-field>
        </div>
        </div>

        <table style="width: 100%; height: 100%; overflow: auto;" mat-table [dataSource]="dataSourceProdutos" matSort>
            <ng-container matColumnDef="Select">
                <th class="w-5" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="NomeProduto">
                <th class="w-40" mat-header-cell *matHeaderCellDef mat-sort-header>Produto</th>
                <td class="f-12" mat-cell *matCellDef="let element">{{ element.NomeProduto }}</td>
            </ng-container>

            <ng-container matColumnDef="Codigo">
                <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Código</th>
                <td class="f-12" mat-cell *matCellDef="let element">{{ element.Codigo }}</td>
            </ng-container>

            <ng-container matColumnDef="Interno">
                <th class="w-5" mat-header-cell *matHeaderCellDef>Interno</th>
                <td class="f-12" mat-cell *matCellDef="let element">{{element.Interno}}</td>
            </ng-container>

            <ng-container matColumnDef="Ncm">
                <th class="w-10" mat-header-cell *matHeaderCellDef>Ncm</th>
                <td class="f-12" mat-cell *matCellDef="let element">{{element.Ncm}}</td>
            </ng-container>

            <ng-container matColumnDef="DataCadastro">
                <th class="w-20" mat-header-cell *matHeaderCellDef mat-sort-header>Data de Cadastro</th>
                <td class="f-12" mat-cell *matCellDef="let element">{{element.DataCadastro | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <tr style="height: 40px;" mat-header-row *matHeaderRowDef="ColunasProdutos"></tr>
            <tr style="height: 60px;" mat-row *matRowDef="let row; columns: ColunasProdutos"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,100]" aria-label="Selecione nº de Clientes"></mat-paginator>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center">

        <mat-form-field appearance="fill" style="width: 45%">
            <mat-label>Categoria</mat-label>
            <input formControlName="Categoria" matInput type="text" readonly>
            <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCategoria()">
        <mat-icon>search</mat-icon>
      </button>
        </mat-form-field>
        <div style="width: 5%;"></div>
        <mat-form-field appearance="fill" style="width: 45%">
            <mat-label>Situação Icms</mat-label>
            <input formControlName="SituacaoIcms" matInput type="text" readonly>
        </mat-form-field>

        <div class="justify-content-end" style="display: flex; width: 100%;">
            <button mat-raised-button color="detalhar" [disabled]="btnDetalhar" (click)="abrirDetalheCategoria()">Detalhar Categoria</button>
            <button mat-raised-button color="primary" (click)="revisarProduto()" [disabled]="formularioProdutos.value.idCategoria == '00000000-0000-0000-0000-000000000000'">Revisar</button>
        </div>

    </mat-dialog-actions>
</form>
