<form [formGroup]="FormProduto" *ngIf="FormProduto">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="matCard-Font">Dados de tributação</mat-card-title>
        </mat-card-header>
        <br>
        <mat-card-content>
            <div class="row">
                <div class="col-sm-1 col-12"></div>
                <div class="col-sm-4 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Produto</mat-label>
                        <input formControlName="Produto" matInput type="text" readonly>
                    </mat-form-field>
                </div>
                <div class="col-sm-4 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Código</mat-label>
                        <input formControlName="Codigo" matInput type="text" readonly>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-1 col-12"></div>
                <div class="col-sm-4 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Categoria</mat-label>
                        <input formControlName="Nome" matInput type="text" readonly>

                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>NCM</mat-label>
                        <input maxlength="8" formControlName="Ncm" matInput type="text" readonly>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Cest</mat-label>
                        <input formControlName="Cest" matInput type="text" readonly>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-12 align-item-center">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Natureza de receita</mat-label>
                        <input formControlName="NaturezaReceita" matInput type="text" readonly>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-1 col-12"></div>
                <div class="col-sm-10 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                        <mat-label>Palavras chave</mat-label>
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let palavra of palavrasChaves">
                                {{palavra.Palavra}}
                            </mat-chip>
                            <input placeholder="Nova Palavra..." [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" readonly>
                        </mat-chip-list>
                    </mat-form-field>
                </div>
            </div>
            <mat-card class="mat-elevation-z0">
                <mat-card-title class="matCard-Font">Informativos da Tributação</mat-card-title>
                <br>
                <div class="row" style="margin-left: 5%;">
                    <div class="col-sm-5">
                        <div style="margin-top: 2%;" *ngFor="let element of InformativoVideos">
                            <button mat-icon-button color="primary" (click)="abrirVideo(element)"><mat-icon>play_circle_outline</mat-icon></button>&nbsp;&nbsp;{{element.Descricao}}
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div style="margin-top: 2%;" *ngFor="let element of InformativoArquivos">
                            <a mat-icon-button color="primary" target="_blank" [href]="element.LinkConteudo">
                                <mat-icon>archive</mat-icon>
                            </a>&nbsp;&nbsp;{{element.Descricao}}
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="mat-elevation-z0">
                <mat-card-title class="matCard-Font">Tributação Federal</mat-card-title>
                <br>

                <mat-form-field class="formField-Font" appearance="fill" style="width: 42.5%; margin-left: 6.6%;">
                    <mat-label>Situação do Pis / Cofins</mat-label>
                    <input formControlName="SituacaoPisCofinsEntrada" type="text" matInput readonly>
                </mat-form-field>

                <div class="row justify-content-center">
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color:#f8f8ff;">
                        <p class="f-15">Informações Cumulativas - Entrada</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <input formControlName="CstPisCumulativoEntrada" type="text" matInput readonly>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <input formControlName="CstCofinsCumulativoEntrada" type="text" matInput readonly>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisCumulativoEntrada" matInput type="number" readonly>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsCumulativoEntrada" matInput type="number" readonly>
                            </mat-form-field>
                        </div>
                    </mat-card>
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #fff0f5;">
                        <p class="f-15">Informações Cumulativas - Saída</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <input formControlName="CstPisCumulativoSaida" matInput type="text" readonly>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <input formControlName="CstCofinsCumulativoSaida" matInput type="text" readonly>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisCumulativoSaida" matInput type="number" readonly>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsCumulativoSaida" matInput type="number" readonly>
                            </mat-form-field>
                        </div>


                    </mat-card>
                </div>

                <div class="row justify-content-center">
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #f8f8ff;">
                        <p class="f-15">Informações não cumulativas - Entrada</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <input formControlName="CstPisNaoCumulativoEntrada" matInput type="text" readonly>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <input formControlName="CstCofinsNaoCumulativoEntrada" matInput type="text" readonly>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisNaoCumulativoEntrada" matInput type="number" readonly>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsNaoCumulativoEntrada" matInput type="number" readonly>
                            </mat-form-field>
                        </div>
                    </mat-card>
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color:	#fff0f5;">
                        <p class="f-15">Informações não cumulativas - Saída</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <input formControlName="CstPisNaoCumulativoSaida" matInput type="text" readonly>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <input formControlName="CstCofinsNaoCumulativoSaida" matInput type="text" readonly>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisNaoCumulativoSaida" matInput type="number">
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsNaoCumulativoSaida" matInput type="number">
                            </mat-form-field>
                        </div>
                    </mat-card>
                </div>
            </mat-card>
        </mat-card-content>
    </mat-card>

    <div formArrayName="ListaTributacaoEstadual" *ngFor="let estado of EstadualControls, let i = index">
        <form [formGroup]="estado">
            <div style="margin: 15px;">
                <mat-accordion class="example-headers-align" displayMode="default" [multi]="true" [hideToggle]="false">
                    <mat-expansion-panel [hideToggle]="false" [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="tE-Font" *ngIf="estado.value.Id != '00000000-0000-0000-0000-000000000000'">
                                <strong>Tributação Estadual - {{estado.value.Uf.UF}}</strong></mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-card class="mat-elevation-z0">

                            <div class="row justify-content-center">
                                <mat-form-field class="formField-Font" appearance="fill" style="width: 87%;">
                                    <mat-label>Situação ICMS</mat-label>
                                    <input formControlName="SituacaoIcmsEntrada" matInput type="text" readonly>
                                </mat-form-field>
                                <mat-form-field class="formField-Font" appearance="fill" style="width: 87%;">
                                    <mat-label>Situação CSOSN</mat-label>
                                    <input formControlName="SituacaoCsosnEntrada" matInput type="text" readonly>
                                </mat-form-field>
                                <div class="row justify-content-center">
                                    <mat-form-field class="formField-Font" appearance="fill" style="width: 75.8%;">
                                        <mat-label>CSOSN</mat-label>
                                        <input formControlName="CsosnEntrada" matInput type="text" readonly>
                                    </mat-form-field>

                                    <mat-slide-toggle formControlName="Fecp" style="width:15%;">Fecp</mat-slide-toggle>
                                </div>
                                <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #f8f8ff;">
                                    <p class="f-15">Informações da Entrada - ICMS</p>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>CST</mat-label>
                                            <input formControlName="CstIcmsEntrada" matInput type="text" readonly>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq.</mat-label>
                                            <input formControlName="AliquotaIcmsEntrada" matInput type="text" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq. ST</mat-label>
                                            <input formControlName="AliquotaIcmsStEntrada" matInput type="number" readonly>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Interno</mat-label>
                                            <input formControlName="MvaInternoEntrada" matInput type="number" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 12%</mat-label>
                                            <input formControlName="MvaExterno12Entrada" matInput type="number" readonly>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 7%</mat-label>
                                            <input formControlName="MvaExterno7Entrada" matInput type="number" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 4%</mat-label>
                                            <input formControlName="MvaExterno4Entrada" matInput type="number" readonly>
                                        </mat-form-field>
                                    </div>
                                </mat-card>
                                <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #fff0f5;">
                                    <p class="f-15">Informações da Saída - ICMS</p>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>CST</mat-label>
                                            <input formControlName="CstIcmsSaida" matInput type="text" readonly>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq.</mat-label>
                                            <input formControlName="AliquotaIcmsSaida" matInput type="text" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq. ST</mat-label>
                                            <input formControlName="AliquotaIcmsStSaida" matInput type="number" readonly>
                                            <mat-hint *ngIf="estado.errors?.valorAliquotaStSaidaInvalido">Obrigatório</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Interno</mat-label>
                                            <input formControlName="MvaInternoSaida" matInput type="number" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 12%</mat-label>
                                            <input formControlName="MvaExterno12Saida" matInput type="number" readonly>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 7%</mat-label>
                                            <input formControlName="MvaExterno7Saida" matInput type="number" readonly>
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 4%</mat-label>
                                            <input formControlName="MvaExterno4Saida" matInput type="number" readonly>
                                        </mat-form-field>
                                    </div>


                                </mat-card>
                                <div class="row justify-content-center">

                                    <mat-form-field class="formField-Font" appearance="fill" style="width: 44.6%;">
                                        <mat-label>Redução base de Cáculo</mat-label>
                                        <input formControlName="ReducaoBaseCalculo" matInput type="number" readonly>
                                    </mat-form-field>
                                    <mat-form-field class="formField-Font" appearance="fill" style="width: 44.6%;">
                                        <mat-label>Motivo Desoneração</mat-label>
                                        <input formControlName="MotivoDesoneracao" matInput type="text" readonly>
                                    </mat-form-field>
                                </div>

                                <mat-form-field class="formField-Font" appearance="fill" style="width: 43.7%;">
                                    <mat-label>Alíquota Desoneração</mat-label>
                                    <input formControlName="AliquotaCalculoDesoneracao" matInput type="number" readonly>
                                </mat-form-field>

                                <mat-form-field class="formField-Font" appearance="fill" style="width: 43.7%;">
                                    <mat-label> Tipo de Desoneração</mat-label>
                                    <input formControlName="TipoDesoneracao" matInput type="text" readonly>
                                </mat-form-field>

                            </div>


                        </mat-card>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </form>
    </div>

</form>