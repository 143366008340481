import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { PerfilAcessoService } from "src/app/services/perfil-acesso.service";
import { UsuarioService } from "src/app/services/usuario.service";


@Injectable({
    providedIn: 'root'
})

export class PerfilAcessoResolve implements Resolve<any> {
    
    public listaPerfilAcesso: any = []
    public user = null;

    constructor(private perfilAcessoService: PerfilAcessoService,
                private usuarioService: UsuarioService) {}

    async resolve() {
        
        await firstValueFrom(this.usuarioService.getLoggedUser())
            .then(response => this.user = response)

        await firstValueFrom(this.perfilAcessoService.getTabelaPerfilAcesso())
            .then(response => this.listaPerfilAcesso = response)

        return {listaPerfilAcesso: this.listaPerfilAcesso, user: this.user}
    }
}