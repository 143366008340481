export interface InformativoCategoria {
  CategoriaId: string;
  ListaLinks: Array<Link>;
}

export interface Link {
  Id: number;
  Link: string;
  Tipo: string;
  Descricao: string;
  Categoria?: any;
}

export const TipoArquivo = ['Video', 'Arquivo'];

export interface Categoria {
  UsuarioAlteracao: any;
  UsuarioCadastro: any;
  DataAlteracao: any;
  DataCadastro: any;

  Id: string;
  Nome: string;
  ListaPalavraChave: ListaPalavrasChaves;
  Ncm: string;
  Cest: string;
  NaturezaReceita: string;
  CstIcmsEntrada: string;
  CstIcmsSaida: string;
  AliquotaIcmsEntrada: number;
  AliquotaIcmsSaida: number;
  AliquotaIcmsStEntrada: number;
  AliquotaIcmsStSaida: number;
  MvaInternoEntrada: number;
  MvaInternoSaida: number;
  MvaExterno12Entrada: number;
  MvaExterno12Saida: number;
  MvaExterno4Entrada: number;
  MvaExterno4Saida: number;
  SituacaoIcmsEntrada: number;
  SituacaoIcmsSaida: number;
  AliquotaPisCumulativoEntrada: number;
  AliquotaPisNaoCumulativoEntrada: number;
  AliquotaCofinsCumulativoEntrada: number;
  AliquotaCofinsNaoCumulativoEntrada: number;
  AliquotaPisCumulativoSaida: number;
  AliquotaPisNaoCumulativoSaida: number;
  AliquotaCofinsCumulativoSaida: number;
  AliquotaCofinsNaoCumulativoSaida: number;
  CstPisCumulativoEntrada: string;
  CstPisNaoCumulativoEntrada: string;
  CstCofinsCumulativoEntrada: string;
  CstCofinsNaoCumulativoEntrada: string;
  CstPisCumulativoSaida: string;
  CstPisNaoCumulativoSaida: string;
  CstCofinsCumulativoSaida: string;
  CstCofinsNaoCumulativoSaida: string;
  SituacaoPisCofinsEntrada: number;
  SituacaoPisCofinsSaida: number;
  CsosnEntrada: string;
  CsosnSaida: string;
  SituacaoCsosnEntrada: number;
  SituacaoCsosnSaida: number;
  Fecp: Boolean;
  ReducaoBaseCalculo: number;
  AliquotaCalculoDesoneracao: number;
  MotivoDesoneracao: number;
  TipoDesoneracao: string;
  UltimaModificacao: string;
  ListaTributacaoEstadual: TributacaoEstadual[];
}

export interface TributacaoEstadual {
  Id: string;
  CategoriaId: string;
  Uf: number;
  CstIcmsEntrada: string;
  CstIcmsSaida: string;
  AliquotaIcmsEntrada: number;
  AliquotaIcmsSaida: number;
  AliquotaIcmsStEntrada: number;
  AliquotaIcmsStSaida: number;
  MvaInternoEntrada: number;
  MvaInternoSaida: number;
  MvaExterno12Entrada: number;
  MvaExterno12Saida: number;
  MvaExterno4Entrada: number;
  MvaExterno4Saida: number;
  SituacaoIcmsEntrada: number;
  SituacaoIcmsSaida: number;
  MvaExterno7Entrada: number;
  MvaExterno7Saida: number;
  AliquotaCalculoDesoneracao: number;
  CsosnEntrada: string;
  CsosnSaida: string;
  Fecp: boolean;
  MotivoDesoneracao: number;
  ReducaoBaseCalculo: number;
  SituacaoCsosnEntrada: number;
  SituacaoCsosnSaida: number;
  TipoDesoneracao: string;
  UltimaModificacao: Date;
}

export interface PalavraChave {
  Id: number;
  Palavra: string;
  CategoriaId: string;
}

export interface ListaPalavrasChaves extends Array<PalavraChave> {}

export const ListaSituacaoPISCOFINS = [
  {
    Nome: 'Isento',
    Id: 0,
  },
  {
    Nome: 'Tributado',
    Id: 1,
  },
  {
    Nome: 'Monofásico',
    Id: 2,
  },
  {
    Nome: 'Substituição Tributária',
    Id: 3,
  }
];

export const ListasCSTPisCofinsFederal = {
  ListaCTSPISCOFINSEntrada: ['50', '51', '70', '73', '75', '99'],
  ListaCTSPISCOFINSSaida: ['01', '02', '03', '04', '05', '06', '07', '08'],
};

export const ListaCSTICMS = {
  ListaCSTIcms: [
    '000',
    '010',
    '020',
    '030',
    '040',
    '041',
    '040',
    '041',
    '050',
    '051',
    '060',
    '070',
    '090',
  ],
  AliquotaICMS: [0, 4, 9, 12, 14, 17, 17.5, 18, 20, 22],
  SituacaoICMS: [
    {
      Nome: 'Diferido',
      Id: 0,
    },
    {
      Nome: 'Isento',
      Id: 1,
    },
    {
      Nome: 'Tributado',
      Id: 2,
    },
    {
      Nome: 'Subs. Tributária',
      Id: 3,
    },
    {
      Nome: 'Cesta básica',
      Id: 4,
    },
    {
      Nome:'Não Tributado',
      Id: 5,
    }
  ],
  TipoDesoneracao: [
    null,
    'RJ801016',
    'RJ801137',
    'RJ802164',
    'RJ802266',
    'RJ802328',
    'RJ802408',
    'RJ801359',
  ],
  MotivoDesoneracao: [{Nome: '', Id: 0},{ Nome: 'Outros', Id: 9 }],
  SituacaoCSOSN: [
    {
      Nome: 'Simples Nacional',
      Id: 0,
    },
    {
      Nome: 'Subs. Tributária',
      Id: 1,
    },
    {
      Nome: 'Outras',
      Id: 2,
    },
  ],
  ListaCSOSN: [
    {
      Id: '0102',
      Descricao: ' - Tributada pelo Simples Nacional sem permissão de crédito',
    },
    {
      Id: '0500',
      Descricao:
        ' - ICMS cobrado anteriormente por substituição tributária ou por antecipação',
    },
  ],
};
