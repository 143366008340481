import { MomentPipe } from './../../../pipes/Moment.pipe';
import { CadastroNCM } from './../../../consts/cadastroncm';
import { MensagemResposta } from './../../../consts/mensagem';
import { firstValueFrom } from 'rxjs';
import { CadastroNcmService } from './../../../services/cadastro-ncm.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

@Component({
  selector: 'app-cadastro-ncm',
  templateUrl: './cadastro-ncm.component.html',
  styleUrls: ['./cadastro-ncm.component.css'],
})
export class CadastroNcmComponent implements OnInit {
  formularioNCM: FormGroup;

  minDate = new Date().toISOString();
  maxDate = new Date().toISOString();

  constructor(
    public formBuilder: FormBuilder,
    public cadastroNcmService: CadastroNcmService,
    public dialog: MatDialog
  ) {
    this.formularioNCM = this.formBuilder.group({
      Id: ['00000000-0000-0000-0000-000000000000'],
      Codigo: ['', Validators.required],
      Descricao: ['', Validators.required],
    });
  }

  ngOnInit() {}

  post() {
    const dialogRef = this.dialog.open(SpinnerComponent, {
      panelClass: 'spinner',
    });
    this.formataFormularioPost(this.formularioNCM);
    console.log(this.formularioNCM.value)
    firstValueFrom(this.cadastroNcmService.post(this.formularioNCM.value)).then(
      (response: MensagemResposta) => {
        if (response) {
          this.abrirPaginaErro('sistema', response);
          if (response[0].Codigo == 'CRM00001') {
            this.limpar();
          }
          dialogRef.close();
        }
      }
    );
  }

  get() {
    firstValueFrom(
      this.cadastroNcmService.get(this.formularioNCM.value.Id)
    ).then((response: CadastroNCM) => {
      this.formularioNCM.patchValue({
        Id: response.Id,
        Codigo: response.Codigo,
        Descricao: response.Descricao,
      });
    });
  }

  delete() {
    const dialogRef = this.dialog.open(SpinnerComponent, {
      panelClass: 'spinner',
    });
    firstValueFrom(
      this.cadastroNcmService.delete(this.formularioNCM.value.Id)
    ).then((response: MensagemResposta) => {
      if (response) {
        this.abrirPaginaErro('sistema', response);
        if (response[0].Codigo == 'CRM00003') {
        }
      }
      dialogRef.close();
    });
  }

  limpar() {
    this.formularioNCM.patchValue({
      Id: '00000000-0000-0000-0000-000000000000',
      Codigo: '',
      Descricao: '',
    });
  }

  formataFormularioPost(formulario) {
    this.formularioNCM.value.Codigo = this.formularioNCM.value.Codigo.toString();
  }


  pesquisarNcm() {
    this.limpar();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Ncms',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((codigoEscolhido) => {
        if (codigoEscolhido) {
          this.formularioNCM.patchValue({
            Id: codigoEscolhido.Id,
            Descricao: codigoEscolhido.Descricao,
            Codigo: codigoEscolhido.Codigo,
            InicioVigencia: codigoEscolhido.InicioVigencia,
            FimVigencia: codigoEscolhido.FimVigencia,
          });
        }
      });
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }
}
