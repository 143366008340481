import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InjectFlags } from '@angular/compiler/src/core';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  public link
  constructor(@Inject(MAT_DIALOG_DATA) data,
  private sanitizer: DomSanitizer) {

    this.link = this.getSanitizedURL(data.Link.LinkConteudo)
  }

  ngOnInit() {
  }

  getSanitizedURL(url){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

}
