import { ConteudoTributacaoComponent } from './conteudo-tributacao/conteudo-tributacao.component';
import { CadastroNcmComponent } from './cadastro-ncm/cadastro-ncm.component';
import { SistemasComponent } from './sistemas/sistemas.component';
import { CategoriasComponent } from './Categorias/Categorias.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from 'src/app/auth/guards';

import { CadastrosComponent } from './cadastros.component';
import { ClientesComponent } from './clientes/clientes.component';

const routes: Routes = [
  {
    path: '',
    component: CadastrosComponent,
    children: [
      {
        path: 'clientes',
        component: ClientesComponent,
      },
      {
        path: 'usuarios',
        component: UsuariosComponent
      },
      {
        path: 'categorias',
        component: CategoriasComponent
      },
      {
        path: 'sistemas',
        component: SistemasComponent
      },
      {
        path: 'ncm',
        component: CadastroNcmComponent
      },
      {
        path: 'informativo-de-categoria',
        component: ConteudoTributacaoComponent
      }
    ],
  },
];


@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class CadastrosRoute {
}
export const routedComponents = [
  ClientesComponent,
]
