<form [formGroup]="formularioCliente">

    <mat-card>
        <mat-card-title class="matCard-Font">Cadastro de Cliente</mat-card-title>

    </mat-card>
    <div style="margin: 15px;">
        <mat-accordion class="example-headers-align" displayMode="default" [multi]="true" [hideToggle]="false">
            <mat-expansion-panel [hideToggle]="false" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>Dados do Cliente</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>group</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-sm-5 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Razão Social</mat-label>
                            <input formControlName="Nome" matInput type="text" maxlength="200">
                            <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCliente()">
                              <mat-icon>search</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-7 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>CNPJ</mat-label>
                            <input formControlName="CNPJ" matInput type="text" [mask]="masks.cnpj">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>E-mail</mat-label>
                            <input formControlName="Email" matInput type="text">
                        </mat-form-field>
                    </div>

                    <div class="col-sm-4 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">

                            <mat-label>Telefone Primário</mat-label>
                            <input formControlName="TelefonePrimario" matInput type="text" [mask]="masks.tel">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Telefone Secundário</mat-label>
                            <input formControlName="TelefoneSecundario" matInput type="text" [mask]="masks.tel">
                        </mat-form-field>
                    </div>

                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [hideToggle]="false" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>Endereço do Cliente</mat-panel-title>
                    <mat-panel-description *ngIf="this.formularioCliente.value.Id == 0">
                        <mat-icon>location_on</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>CEP</mat-label>
                            <input formControlName="CEP" matInput type="text" [mask]="masks.cep" [value]="formularioCliente.value.CEP">
                            <button *ngIf="!pesquisaCepRealizada" matSuffix mat-icon-button aria-label="Clear" (click)="buscarEndereco($event)">
                <mat-icon>search</mat-icon>
              </button>
                            <button *ngIf="pesquisaCepRealizada" matSuffix mat-icon-button aria-label="Clear" (click)="removerEndereco()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-1 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Uf</mat-label>
                            <input formControlName="Uf" matInput type="text" maxlength="3">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Bairro</mat-label>
                            <input formControlName="Bairro" matInput type="text" maxlength="50">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-5 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Endereço</mat-label>
                            <input formControlName="Endereco" matInput type="text" maxlength="100">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-4 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Complemento</mat-label>
                            <input formControlName="Complemento" matInput type="text" maxlength="50">
                        </mat-form-field>
                    </div>
                    <div class="col-sm-4 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Cidade</mat-label>
                            <input formControlName="Cidade" matInput type="text" maxlength="50">
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [hideToggle]="false" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>Dados de Serviço</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>settings</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Data de distrato</mat-label>
                            <input [matDatepicker]="picker2" (focus)="picker2.open()" formControlName="DataDistrato" matInput readonly>
                            <button *ngIf="formularioCliente.value.DataDistrato != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparDataDistrato()">
                              <mat-icon>close</mat-icon>
                            </button>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Quantidade de Lojas</mat-label>
                            <input formControlName="QtdLoja" matInput type="number">
                        </mat-form-field>
                    </div>

                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width:100%">
                            <mat-label>Tipo de Negócio</mat-label>
                            <mat-select formControlName="TipoNegocio">
                                <mat-option *ngFor="let tipoNegocio of listaTipoDeNegocio" [value]="tipoNegocio">
                                    {{tipoNegocio}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Sistema</mat-label>
                            <input formControlName="Sistema" matInput type="text" readonly>
                            <button *ngIf="!pesquisaSistemaRealizada" matSuffix mat-icon-button aria-label="Clear" (click)="pesquisarSistema()">
                              <mat-icon>search</mat-icon>
                            </button>
                            <button *ngIf="pesquisaSistemaRealizada" matSuffix mat-icon-button aria-label="Clear" (click)="limparSistema()">
                              <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Total de Produtos</mat-label>
                            <input formControlName="TotaldeProdutos" matInput type="number" readonly min="0">
                            <mat-hint>Apenas para leitura</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;" hidden>
                            <mat-label>Honorário</mat-label>
                            <input formControlName="Honorario" matInput type="text" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                        </mat-form-field>
                    </div>


                    <div class="row">
                        <div class="col-sm-3 col-12">
                            <mat-checkbox class="example-margin" style="margin-top: 7%; margin-left: 4%;" formControlName="BloqueioRevisao" [(ngModel)]="RevisaoChecked">Bloquear atualização no cliente
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</form>

<app-auditoria *ngIf="formularioCliente.value.Id != 0" [DataCadastro]="formularioCliente.value.DataCadastro" [DataAlteracao]="formularioCliente.value.DataAlteracao" [UsuarioCadastro]="formularioCliente.value.UsuarioCadastro" [UsuarioModificacao]="formularioCliente.value.UsuarioAlteracao"></app-auditoria>

<app-crud-buttons [showPost]="formularioCliente.value.Id == 0" [showPut]="formularioCliente.value.Id != 0" [showDelete] [labelBotaoPost]="'Gravar'" [labelBotaoPut]="'Gravar'" [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="formularioCliente.status == 'INVALID'"
    [disablePut]="formularioCliente.status == 'INVALID'" [disableDelete]="formularioCliente.value.Id == 0" (OnClickButtonPost)="post()" (OnClickButtonPut)="post()" (OnClickButtonAuxUm)="limpar()" (OnClickButtonDelete)="delete()">
</app-crud-buttons>