import { TabelaSugestaoClienteComponent } from './tabela-sugestao-cliente/tabela-sugestao-cliente.component';
import { TabelaClienteRevisaoComponent } from './tabela-cliente-revisao/tabela-cliente-revisao.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Cliente } from './../../../../consts/cliente';
import { RevisaoService } from './../../../../services/revisao.service';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { ClienteProdutoQtd } from './../../../../consts/revisao';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Component, Input, OnInit, ViewChild, AfterViewInit, AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

@Component({
  selector: 'app-tabela-revisao',
  templateUrl: './tabela-revisao.component.html',
  styleUrls: ['./tabela-revisao.component.css']
})
export class TabelaRevisaoComponent implements OnInit, OnChanges {

  @Input() filtroPesquisa;
  @Input() dataSourceEmpresas: MatTableDataSource<ClienteProdutoQtd>;
  @Input() eventoSort: Observable<MatSort>
  @ViewChild(MatPaginator, { static: true }) paginatorCliente: MatPaginator;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public ColunasEmpresas: String[] = ['Nome', 'QuantidadeProduto', 'QuantidadeSugestao', 'Sugestao', 'Detalhe']


  constructor(public revisaoService: RevisaoService,
    public dialog: MatDialog) {
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.dataSourceEmpresas.sort == undefined){
      this.dataSourceEmpresas.sort = this.sort
    }

  }


  abrirTabelaCliente(dataProdutosCliente,Cliente){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      dados: dataProdutosCliente,
      dadosCliente: Cliente
    }

    dialogConfig.maxHeight = '90vh'
    dialogConfig.width = '90vw'

    this.dialog.open(TabelaClienteRevisaoComponent, dialogConfig)
    .afterClosed()
    .subscribe(response => {
    })
  }

  detalheCliente(cliente){
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    let filtro = {
      DataFim: this.filtroPesquisa.DataFim,
      DataInicio: this.filtroPesquisa.DataInicio,
      Ncm: this.filtroPesquisa.Ncm,
      Produtos: this.filtroPesquisa.Produtos,
      Codigo: this.filtroPesquisa.Codigo,
      idCliente: cliente.Id
    }

    firstValueFrom(this.revisaoService.getProdutosCliente(filtro))
    .then(response => {
      if(response){
        this.abrirTabelaCliente(response, cliente)
        dialogRef.close()
      }
    })
  }

  sugestaoCategoria(cliente){
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    let filtro = {
      DataFim: this.filtroPesquisa.DataFim,
      DataInicio: this.filtroPesquisa.DataInicio,
      Ncm: this.filtroPesquisa.Ncm,
      Produtos: this.filtroPesquisa.Produtos,
      Codigo: this.filtroPesquisa.Codigo,
      idCliente: cliente.Id
    }


    firstValueFrom(this.revisaoService.postSugestaoQtdProdutos(filtro))
    .then(response => {
      if(response){
        this.abrirTabelaSugestao(response, cliente, filtro)
        dialogRef.close()
      }
    })
  }

  abrirTabelaSugestao(dataProdutosCliente, cliente, filtro){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      Dados: dataProdutosCliente,
      Cliente: cliente,
      filtroPesquisa: filtro
    }

    dialogConfig.height = '90vh'
    dialogConfig.width = '90vw'

    this.dialog.open(TabelaSugestaoClienteComponent,dialogConfig)
  }
}
