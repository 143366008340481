import { permissoes } from 'src/app/consts';
import { MensagemResposta } from './../../../consts/mensagem';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { firstValueFrom } from 'rxjs';
import { CategoriasService } from './../../../services/categorias.service';
import { ListaUF } from './../../../consts/ListasSelects';
import { FormCategorias } from './../../../form-validations/form-categorias';
import {
  Categoria,
  ListaCSTICMS,
  ListasCSTPisCofinsFederal,
  ListaSituacaoPISCOFINS,
  TributacaoEstadual,
  ListaPalavrasChaves,
  PalavraChave
} from './../../../consts/categoria';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  FormArray,
} from '@angular/forms';
import { checkServerIdentity } from 'tls';
import { MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { SelectionChange } from '@angular/cdk/collections';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';
import { MontaMenuService } from 'src/app/services/monta-menu.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css'],
})
export class CategoriasComponent implements OnInit {
  ColunasTabelaFederal: string[] = ['position', 'name', 'weight', 'symbol'];

  guidInicial = '00000000-0000-0000-0000-000000000000';

  permissoes: any

  ListasCSTPisCofinsFederal = ListasCSTPisCofinsFederal;
  ListaCSTICMS = ListaCSTICMS;
  ListaSituacaoPISCOFINS = ListaSituacaoPISCOFINS;
  ListaUf = ListaUF;

  //mat chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true
  readonly separatorKeysCodes = [ENTER, COMMA] as const
  palavrasChaves: ListaPalavrasChaves = []

  public Fecp = false;

  formularioCategorias: FormGroup;
  formListaTributacaoEstadual: FormArray;

  constructor(
    public formBuilder: FormBuilder,
    public categoriasService: CategoriasService,
    public dialog: MatDialog,
    public montaMenuService: MontaMenuService
  ) {

    firstValueFrom(this.montaMenuService.get())
    .then((response: any[]) => {
        console.log(response)
        let permCategoria = response.filter(item => item.dsFuncao == 'Categorias')[0];
        this.permissoes = permCategoria;
        console.log(this.permissoes);
    })

  }

  ngOnInit() {
    this.formularioCategorias = this.formBuilder.group(
      {
        Id: [this.guidInicial],
        Nome: ['', Validators.required],
        ListaPalavraChave: [''],
        Ncm: [null, Validators.required],
        Cest: [null],
        NaturezaReceita: [null],
        AliquotaPisCumulativoEntrada: [null],
        AliquotaPisNaoCumulativoEntrada: [null],
        AliquotaCofinsCumulativoEntrada: [null],
        AliquotaCofinsNaoCumulativoEntrada: [null],
        AliquotaPisCumulativoSaida: [null],
        AliquotaPisNaoCumulativoSaida: [null],
        AliquotaCofinsCumulativoSaida: [null],
        AliquotaCofinsNaoCumulativoSaida: [null],
        CstPisCumulativoEntrada: [null, Validators.required],
        CstPisNaoCumulativoEntrada: [null, Validators.required],
        CstCofinsCumulativoEntrada: [null, Validators.required],
        CstCofinsNaoCumulativoEntrada: [null, Validators.required],
        CstPisCumulativoSaida: [null, Validators.required],
        CstPisNaoCumulativoSaida: [null, Validators.required],
        CstCofinsCumulativoSaida: [null, Validators.required],
        CstCofinsNaoCumulativoSaida: [null, Validators.required],
        SituacaoPisCofinsEntrada: [null, Validators.required],
        SituacaoPisCofinsSaida: [null],
        SituacaoCsosnEntrada: [null],
        SituacaoCsosnSaida: [null],
        CstIcmsEntrada: [null],
        CstIcmsSaida: [null],
        AliquotaIcmsEntrada: [null],
        AliquotaIcmsSaida: [null],
        AliquotaIcmsStEntrada: [null],
        AliquotaIcmsStSaida: [null],
        MvaInternoEntrada: [null],
        MvaInternoSaida: [null],
        MvaExterno12Entrada: [null],
        MvaExterno12Saida: [null],
        MvaExterno4Entrada: [null],
        MvaExterno4Saida: [null],
        SituacaoIcmsEntrada: [null],
        SituacaoIcmsSaida: [null],
        MvaExterno7Entrada: [null],
        MvaExterno7Saida: [null],
        AliquotaCalculoDesoneracao: [null],
        CsosnEntrada: [null],
        CsosnSaida: [null],
        Fecp: [null],
        MotivoDesoneracao: [null],
        ReducaoBaseCalculo: [null],
        TipoDesoneracao: [null],
        DataCadastro: [null],
        DataAlteracao: [null],
        UsuarioCadastro: [null],
        UsuarioAlteracao: [null],

        ListaTributacaoEstadual: this.formBuilder.array([]),
      },
      { validators: FormCategorias.naturezaValidator() }
    );
    this.formValueChanges();
  }

  formValueChanges(){
    this.EstadualControls.forEach(a => {
      a.statusChanges.subscribe(b => this.atualizaFormStatus())
    })
  }

  atualizaFormStatus(){
    this.formularioCategorias.get('ListaTributacaoEstadual').updateValueAndValidity()
  }

  async formataFormulario(response: Categoria) {
    let Estados = response.ListaTributacaoEstadual.map((element) =>
      this.buildTributacaoEstadual(element)
    );

    this.palavrasChaves = response.ListaPalavraChave? response.ListaPalavraChave : []

    this.formularioCategorias = this.formBuilder.group(
      {
        Id: response.Id,
        Nome: response.Nome,
        ListaPalavraChave: response.ListaPalavraChave,
        Ncm: response.Ncm,
        Cest: response.Cest,
        NaturezaReceita: response.NaturezaReceita,
        AliquotaPisCumulativoEntrada: response.AliquotaPisCumulativoEntrada,
        AliquotaPisNaoCumulativoEntrada:
          response.AliquotaPisNaoCumulativoEntrada,
        AliquotaCofinsCumulativoEntrada:
          response.AliquotaCofinsCumulativoEntrada,
        AliquotaCofinsNaoCumulativoEntrada:
          response.AliquotaCofinsNaoCumulativoEntrada,
        AliquotaPisCumulativoSaida: response.AliquotaPisCumulativoSaida,
        AliquotaPisNaoCumulativoSaida: response.AliquotaPisNaoCumulativoSaida,
        AliquotaCofinsCumulativoSaida: response.AliquotaCofinsCumulativoSaida,
        AliquotaCofinsNaoCumulativoSaida:
          response.AliquotaCofinsNaoCumulativoSaida,
        CstPisCumulativoEntrada: response.CstPisCumulativoEntrada,
        CstPisNaoCumulativoEntrada: response.CstPisNaoCumulativoEntrada,
        CstCofinsCumulativoEntrada: response.CstCofinsCumulativoEntrada,
        CstCofinsNaoCumulativoEntrada: response.CstCofinsNaoCumulativoEntrada,
        CstPisCumulativoSaida: response.CstPisCumulativoSaida,
        CstPisNaoCumulativoSaida: response.CstPisNaoCumulativoSaida,
        CstCofinsCumulativoSaida: response.CstCofinsCumulativoSaida,
        CstCofinsNaoCumulativoSaida: response.CstCofinsNaoCumulativoSaida,
        SituacaoPisCofinsEntrada: response.SituacaoPisCofinsEntrada,
        SituacaoPisCofinsSaida: response.SituacaoPisCofinsEntrada,
        AliquotaCalculoDesoneracao: response.AliquotaCalculoDesoneracao,
        Fecp: response.Fecp,
        MotivoDesoneracao: response.MotivoDesoneracao,
        ReducaoBaseCalculo: response.ReducaoBaseCalculo,
        TipoDesoneracao: response.TipoDesoneracao,
        DataCadastro: response.DataCadastro,
        DataAlteracao: response.DataAlteracao,
        UsuarioCadastro:  response.UsuarioCadastro,
        UsuarioAlteracao: response.UsuarioAlteracao,
        ListaTributacaoEstadual: this.formBuilder.array(Estados),
      },
      { validators: FormCategorias.naturezaValidator() }
    );
    this.EstadualControls.forEach((a) => {
      a.patchValue({ Uf: this.ListaUf.find((b) => b.idUF == a.value.Uf) });
    });
  }

  post() {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})
    firstValueFrom(
      this.categoriasService.post([
        this.formataDadosGravar(this.formularioCategorias),
      ])
    ).then((response: MensagemResposta) => {
      if (response) {
        this.abrirPaginaErro('sistema', response);
        if (response[0].Codigo == 'CRM00001') {
          this.limpar();
          dialogRef.close();
        }
        else {
          this.EstadualControls.forEach((a) => {
              a.patchValue({ Uf: this.ListaUf.find((b) => b.idUF == a.value.Uf) });
            dialogRef.close();
          });
        }
      }
    });
  }

  delete() {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    firstValueFrom(this.categoriasService.delete(this.formularioCategorias.value.Id))
    .then((response: MensagemResposta) => {
      if (response) {
        this.abrirPaginaErro('sistema', response);
        if (response[0].Codigo == 'CRM00003') {
          this.limpar();
        }
        dialogRef.close()
      }
    })
  }

  limpar() {

    this.palavrasChaves = []

    this.formularioCategorias.patchValue({
      Id: this.guidInicial,
      Nome: '',
      ListaPalavraChave: [],
      Ncm: null,
      Cest: null,
      NaturezaReceita: null,
      AliquotaPisCumulativoEntrada: null,
      AliquotaPisNaoCumulativoEntrada: null,
      AliquotaCofinsCumulativoEntrada: null,
      AliquotaCofinsNaoCumulativoEntrada: null,
      AliquotaPisCumulativoSaida: null,
      AliquotaPisNaoCumulativoSaida: null,
      AliquotaCofinsCumulativoSaida: null,
      AliquotaCofinsNaoCumulativoSaida: null,
      CstPisCumulativoEntrada: null,
      CstPisNaoCumulativoEntrada: null,
      CstCofinsCumulativoEntrada: null,
      CstCofinsNaoCumulativoEntrada: null,
      CstPisCumulativoSaida: null,
      CstPisNaoCumulativoSaida: null,
      CstCofinsCumulativoSaida: null,
      CstCofinsNaoCumulativoSaida: null,
      SituacaoPisCofinsEntrada: null,
      SituacaoPisCofinsSaida: null,
      SituacaoCsosnEntrada: null,
      SituacaoCsosnSaida: null,
      CstIcmsEntrada: null,
      CstIcmsSaida: null,
      AliquotaIcmsEntrada: null,
      AliquotaIcmsSaida: null,
      AliquotaIcmsStEntrada: null,
      AliquotaIcmsStSaida: null,
      MvaInternoEntrada: null,
      MvaInternoSaida: null,
      MvaExterno12Entrada: null,
      MvaExterno12Saida: null,
      MvaExterno4Entrada: null,
      MvaExterno4Saida: null,
      SituacaoIcmsEntrada: null,
      SituacaoIcmsSaida: null,
      MvaExterno7Entrada: null,
      MvaExterno7Saida: null,
      AliquotaCalculoDesoneracao: null,
      CsosnEntrada: null,
      CsosnSaida: null,
      Fecp: null,
      MotivoDesoneracao: null,
      ReducaoBaseCalculo: null,
      TipoDesoneracao: null,
      DataAlteracao: null,
      ListaTributacaoEstadual: [],
    })
    this.EstadualControls.splice(0, this.EstadualControls.length)


  }

  buildTributacaoEstadual(a): FormGroup {
    return this.formBuilder.group(
      {
        Id: [a.Id],
        CategoriaId: [a.CategoriaId],
        Uf: [a.Uf],
        CstIcmsEntrada: [a.CstIcmsEntrada, Validators.required],
        CstIcmsSaida: [a.CstIcmsSaida, Validators.required],
        AliquotaIcmsEntrada: [a.AliquotaIcmsEntrada],
        AliquotaIcmsSaida: [a.AliquotaIcmsSaida],
        AliquotaIcmsStEntrada: [a.AliquotaIcmsStEntrada],
        AliquotaIcmsStSaida: [a.AliquotaIcmsStSaida],
        MvaInternoEntrada: [a.MvaInternoEntrada],
        MvaInternoSaida: [a.MvaInternoSaida],
        MvaExterno12Entrada: [a.MvaExterno12Entrada],
        MvaExterno12Saida: [a.MvaExterno12Saida],
        MvaExterno4Entrada: [a.MvaExterno4Entrada],
        MvaExterno4Saida: [a.MvaExterno4Saida],
        SituacaoIcmsEntrada: [a.SituacaoIcmsEntrada, Validators.required],
        SituacaoIcmsSaida: [a.SituacaoIcmsSaida],
        MvaExterno7Entrada: [a.MvaExterno7Entrada],
        MvaExterno7Saida: [a.MvaExterno7Saida],
        AliquotaCalculoDesoneracao: [a.AliquotaCalculoDesoneracao],
        CsosnEntrada: [a.CsosnEntrada],
        CsosnSaida: [a.CsosnSaida],
        Fecp: [a.Fecp],
        MotivoDesoneracao: [a.MotivoDesoneracao],
        ReducaoBaseCalculo: [a.ReducaoBaseCalculo],
        SituacaoCsosnEntrada: [a.SituacaoCsosnEntrada, Validators.required],
        SituacaoCsosnSaida: [a.SituacaoCsosnSaida],
        TipoDesoneracao: [a.TipoDesoneracao],
        DataAlteracao: [a.DataAlteracao],
      },
      {
        validators: [
          FormCategorias.aliquotaIcmsEntradaValidator('AliquotaIcmsEntrada', 'CstIcmsEntrada'),
          FormCategorias.aliquotaIcmsSaidaValidator('AliquotaIcmsSaida', 'CstIcmsSaida'),
          FormCategorias.aliquotaStEntradaValidator('AliquotaIcmsStEntrada','CstIcmsEntrada'),
          FormCategorias.aliquotaStSaidaValidator('AliquotaIcmsStSaida', 'CstIcmsSaida'),
          FormCategorias.alqDesoneracaoValidator(),
          FormCategorias.motivoDesoneracaoValidator(),
          FormCategorias.reducaoBaseCalculoValidator(),
        ],
      }
    );
  }

  addNovoEstado() {
    this.EstadualControls.unshift(this.NewControlGroup());
    this.formValueChanges();
  }

  excluirEstado(index){
    this.EstadualControls.splice(index,1)
    this.formValueChanges();
  }

  NewControlGroup(): FormGroup {
    return this.formBuilder.group(
      {
        Id: [this.guidInicial],
        CategoriaId: [this.guidInicial],
        Uf: [null],
        CstIcmsEntrada: [null, Validators.required],
        CstIcmsSaida: [null, Validators.required],
        AliquotaIcmsEntrada: [null, Validators.required],
        AliquotaIcmsSaida: [null, Validators.required],
        AliquotaIcmsStEntrada: [null],
        AliquotaIcmsStSaida: [null],
        MvaInternoEntrada: [null],
        MvaInternoSaida: [null],
        MvaExterno12Entrada: [null],
        MvaExterno12Saida: [null],
        MvaExterno4Entrada: [null],
        MvaExterno4Saida: [null],
        SituacaoIcmsEntrada: [null, Validators.required],
        SituacaoIcmsSaida: [null],
        MvaExterno7Entrada: [null],
        MvaExterno7Saida: [null],
        AliquotaCalculoDesoneracao: [null],
        CsosnEntrada: [null],
        CsosnSaida: [null],
        Fecp: [false],
        MotivoDesoneracao: [null],
        ReducaoBaseCalculo: [null],
        SituacaoCsosnEntrada: [null, Validators.required],
        SituacaoCsosnSaida: [null],
        TipoDesoneracao: [null],
        DataAlteracao: [null],
      },
      {
        validators: [
          FormCategorias.aliquotaIcmsEntradaValidator('AliquotaIcmsEntrada', 'CstIcmsEntrada'),
          FormCategorias.aliquotaIcmsSaidaValidator('AliquotaIcmsSaida', 'CstIcmsSaida'),
          FormCategorias.aliquotaStEntradaValidator('AliquotaIcmsStEntrada','CstIcmsEntrada'),
          FormCategorias.aliquotaStSaidaValidator('AliquotaIcmsStSaida', 'CstIcmsSaida'),
          FormCategorias.alqDesoneracaoValidator(),
          FormCategorias.motivoDesoneracaoValidator(),
          FormCategorias.reducaoBaseCalculoValidator(),
          FormCategorias.tipoDesoneracaoValidator()
        ],
      }
    );
  }

  get EstadualControls() {
    return (<FormArray>this.formularioCategorias.get('ListaTributacaoEstadual'))
      .controls;
  }

  pesquisarCategorias() {
    this.limpar();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Categorias',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((categoriaEscolhida) => {
        if (categoriaEscolhida) {
          firstValueFrom(
            this.categoriasService.get(categoriaEscolhida.Id)
          ).then((response: Categoria) => {
            this.formataFormulario(response);
          });
        }
      });
  }

  formataDadosGravar(array): Categoria {

    let arrayEstado = [];

    this.EstadualControls.forEach((a) => {
      if (a.value.Uf.idUF) {
        let idUF = a.value.Uf.idUF;
        a.patchValue({ Uf: idUF });
      }
    });


    this.EstadualControls.forEach((a) => {
      if (a) {
        arrayEstado.push({
          Id: a.value.Id,
          CategoriaId: a.value.CategoriaId,
          Uf: a.value.Uf,
          CstIcmsEntrada: a.value.CstIcmsEntrada,
          CstIcmsSaida: a.value.CstIcmsSaida,
          AliquotaIcmsEntrada: a.value.AliquotaIcmsEntrada,
          AliquotaIcmsSaida: a.value.AliquotaIcmsSaida,
          AliquotaIcmsStEntrada: a.value.AliquotaIcmsStEntrada,
          AliquotaIcmsStSaida: a.value.AliquotaIcmsStSaida,
          MvaInternoEntrada: a.value.MvaInternoEntrada,
          MvaInternoSaida: a.value.MvaInternoSaida,
          MvaExterno12Entrada: a.value.MvaExterno12Entrada,
          MvaExterno12Saida: a.value.MvaExterno12Saida,
          MvaExterno4Entrada: a.value.MvaExterno4Entrada,
          MvaExterno4Saida: a.value.MvaExterno4Saida,
          SituacaoIcmsEntrada: a.value.SituacaoIcmsEntrada,
          SituacaoIcmsSaida: a.value.SituacaoIcmsEntrada,
          MvaExterno7Entrada: a.value.MvaExterno7Entrada,
          MvaExterno7Saida: a.value.MvaExterno7Saida,
          AliquotaCalculoDesoneracao: a.value.AliquotaCalculoDesoneracao,
          CsosnEntrada: a.value.CsosnEntrada,
          CsosnSaida: a.value.CsosnEntrada,
          Fecp: a.value.Fecp,
          MotivoDesoneracao: a.value.MotivoDesoneracao,
          ReducaoBaseCalculo: a.value.ReducaoBaseCalculo,
          SituacaoCsosnEntrada: a.value.SituacaoCsosnEntrada,
          SituacaoCsosnSaida: a.value.SituacaoCsosnEntrada,
          TipoDesoneracao: a.value.TipoDesoneracao,
          DataAlteracao: a.value.DataAlteracao,
        });
      }
    });
    let arrayForm: Categoria = {
      Id: array.value.Id,
      Nome: array.value.Nome,
      Ncm: array.value.Ncm,
      ListaPalavraChave: this.palavrasChaves,
      Cest: array.value.Cest,
      NaturezaReceita: array.value.NaturezaReceita,
      CstIcmsEntrada: null,
      CstIcmsSaida: null,
      AliquotaIcmsEntrada: null,
      AliquotaIcmsSaida: null,
      AliquotaIcmsStEntrada: null,
      AliquotaIcmsStSaida: null,
      MvaInternoEntrada: null,
      MvaInternoSaida: null,
      MvaExterno12Entrada: null,
      MvaExterno12Saida: null,
      MvaExterno4Entrada: null,
      MvaExterno4Saida: null,
      SituacaoIcmsEntrada: null,
      SituacaoIcmsSaida: null,
      AliquotaPisCumulativoEntrada: array.value.AliquotaPisCumulativoEntrada,
      AliquotaPisNaoCumulativoEntrada: array.value.AliquotaPisNaoCumulativoEntrada,
      AliquotaCofinsCumulativoEntrada: array.value.AliquotaCofinsCumulativoEntrada,
      AliquotaCofinsNaoCumulativoEntrada: array.value.AliquotaCofinsNaoCumulativoEntrada,
      AliquotaPisCumulativoSaida: array.value.AliquotaPisCumulativoSaida,
      AliquotaPisNaoCumulativoSaida: array.value.AliquotaPisNaoCumulativoSaida,
      AliquotaCofinsCumulativoSaida: array.value.AliquotaCofinsCumulativoSaida,
      AliquotaCofinsNaoCumulativoSaida: array.value.AliquotaCofinsNaoCumulativoSaida,
      CstPisCumulativoEntrada: array.value.CstPisCumulativoEntrada,
      CstPisNaoCumulativoEntrada: array.value.CstPisNaoCumulativoEntrada,
      CstCofinsCumulativoEntrada: array.value.CstCofinsCumulativoEntrada,
      CstCofinsNaoCumulativoEntrada: array.value.CstCofinsNaoCumulativoEntrada,
      CstPisCumulativoSaida: array.value.CstPisCumulativoSaida,
      CstPisNaoCumulativoSaida: array.value.CstPisNaoCumulativoSaida,
      CstCofinsCumulativoSaida: array.value.CstCofinsCumulativoSaida,
      CstCofinsNaoCumulativoSaida: array.value.CstCofinsNaoCumulativoSaida,
      SituacaoPisCofinsEntrada: array.value.SituacaoPisCofinsEntrada,
      SituacaoPisCofinsSaida: array.value.SituacaoPisCofinsEntrada,
      CsosnEntrada: null,
      CsosnSaida: null,
      SituacaoCsosnEntrada: null,
      SituacaoCsosnSaida: null,
      Fecp: null,
      ReducaoBaseCalculo: array.value.ReducaoBaseCalculo,
      AliquotaCalculoDesoneracao: null,
      MotivoDesoneracao: null,
      TipoDesoneracao: null,
      ListaTributacaoEstadual: arrayEstado,
      UsuarioAlteracao: array.value.UsuarioAlteracao,
      UsuarioCadastro: array.value.UsuarioCadastro,
      DataAlteracao: array.value.DataAlteracao,
      DataCadastro: array.value.DataCadastro,
      UltimaModificacao: array.value.UltimaModificacao
    };

    return arrayForm;
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }

  preenchePisCofins(event){

    switch(event.value){
      case 0:
        this.formularioCategorias.patchValue({
          AliquotaPisCumulativoEntrada: 0,
        AliquotaPisNaoCumulativoEntrada: 0,
        AliquotaCofinsCumulativoEntrada: 0,
        AliquotaCofinsNaoCumulativoEntrada: 0,
        AliquotaPisCumulativoSaida: 0,
        AliquotaPisNaoCumulativoSaida: 0,
        AliquotaCofinsCumulativoSaida: 0,
        AliquotaCofinsNaoCumulativoSaida: 0,
        CstPisCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[3],
        CstPisNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[3],
        CstCofinsCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[3],
        CstCofinsNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[3],
        CstPisCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[5],
        CstPisNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[5],
        CstCofinsCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[5],
        CstCofinsNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[5],
        })
      break;

      case 1:
        this.formularioCategorias.patchValue({
        AliquotaPisCumulativoEntrada: 0.65,
        AliquotaPisNaoCumulativoEntrada: 1.65,
        AliquotaCofinsCumulativoEntrada: 3,
        AliquotaCofinsNaoCumulativoEntrada: 7.60,
        AliquotaPisCumulativoSaida: 0.65,
        AliquotaPisNaoCumulativoSaida: 1.65,
        AliquotaCofinsCumulativoSaida: 3,
        AliquotaCofinsNaoCumulativoSaida: 7.60,
        CstPisCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[0],
        CstPisNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[0],
        CstCofinsCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[0],
        CstCofinsNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[0],
        CstPisCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[0],
        CstPisNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[0],
        CstCofinsCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[0],
        CstCofinsNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[0],
        })
        break;

        case 2:
          this.formularioCategorias.patchValue({
            AliquotaPisCumulativoEntrada: 0,
          AliquotaPisNaoCumulativoEntrada: 0,
          AliquotaCofinsCumulativoEntrada: 0,
          AliquotaCofinsNaoCumulativoEntrada: 0,
          AliquotaPisCumulativoSaida: 0,
          AliquotaPisNaoCumulativoSaida: 0,
          AliquotaCofinsCumulativoSaida: 0,
          AliquotaCofinsNaoCumulativoSaida: 0,
          CstPisCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[2],
          CstPisNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[2],
          CstCofinsCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[2],
          CstCofinsNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[2],
          CstPisCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[3],
          CstPisNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[3],
          CstCofinsCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[3],
          CstCofinsNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[3],
          })
        break;

        case 3:
          this.formularioCategorias.patchValue({
            AliquotaPisCumulativoEntrada: 0,
          AliquotaPisNaoCumulativoEntrada: 0,
          AliquotaCofinsCumulativoEntrada: 0,
          AliquotaCofinsNaoCumulativoEntrada: 0,
          AliquotaPisCumulativoSaida: 0,
          AliquotaPisNaoCumulativoSaida: 0,
          AliquotaCofinsCumulativoSaida: 0,
          AliquotaCofinsNaoCumulativoSaida: 0,
          CstPisCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[4],
          CstPisNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[4],
          CstCofinsCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[4],
          CstCofinsNaoCumulativoEntrada: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada[4],
          CstPisCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[4],
          CstPisNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[4],
          CstCofinsCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[4],
          CstCofinsNaoCumulativoSaida: ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida[4],
          })
        break;
    }
  }

  //Chips Events
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value

    if((value || '').trim()){
      this.palavrasChaves.push({
        Id: 0,
        Palavra: value,
        CategoriaId: this.formularioCategorias.value.Id
      })
    }
    if(input){
      input.value = ''
    }
  }

  remove(palavra: PalavraChave): void {
    const index = this.palavrasChaves.indexOf(palavra);
    if(index >= 0){
      this.palavrasChaves.splice(index, 1);
    }
  }

  limparNcm(){
    this.formularioCategorias.patchValue({
      Ncm: ''
    })
  }

  pesquisarNcm(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Ncms',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((codigoEscolhido) => {
        if (codigoEscolhido) {
          this.formularioCategorias.patchValue({
            Ncm: codigoEscolhido.Codigo
          })
        }
      });

}
}
