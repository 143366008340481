import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Input('color') color = "#000000"
  @Output() sendLoginForm = new EventEmitter<void>();

  public form: FormGroup;
  ativaLogin = false
  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({
      Login: ["",[Validators.required]],
      Senha: ["",[Validators.required]],
      Ambiente: 'ct-revisao',
    })
  }

  async login(): Promise<void> {
    if (this.form.valid) {
      this.ativaLogin = true
      await this.sendLoginForm.emit(this.form.value);
    }
  }


}
