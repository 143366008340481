import { Usuario } from './../../consts/usuario';
import { UsuarioService } from 'src/app/services/usuario.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { userInfo } from 'os';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { routes } from 'src/app/consts';
import { PagesResolve } from 'src/app/pages/pages-resolve';
import { environment } from 'src/environments/environment.hmr';

import { User } from '../models';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  API = environment.API;
  public routers: typeof routes = routes;
  public loggedIn = new BehaviorSubject<any>(false);
  private usuarioLogado;

  constructor(
    private http: HttpClient,
    private router: Router,
    private usuarioService: UsuarioService
  ) {

    this.loggedIn.subscribe((r) => {
      if (r.loggedStatus) {
        localStorage.setItem('userInfo', r.userInfo);
        this.getUsuarioLogado();
      }
    });




    this.autoLogOut();
  }

  ngAfterViewInit(): void {

  }

  async getUsuarioLogado(){
    await firstValueFrom(this.usuarioService.getLoggedUser())
    .then(response => {
      this.usuarioLogado = response[0]
      localStorage.setItem('usuarioCompleto', this.usuarioLogado.idCliente)


    })
  }

  public login(corpo) {
    return this.http.post(`${this.API}account/login`, corpo);
  }

  public sign(): void {
    localStorage.setItem('token', 'token');
  }

  public signOut(): void {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userClient');
    localStorage.removeItem('usuarioCompleto')
    let obj = {
      loggedStatus: false,
    };
    this.loggedIn.next(obj);
    // declarar outra funcao para resetar valor de MENU em pages resolve
  }

  public autoLogOut(): void {
    setTimeout(() => {
      this.customLogOut();
    }, 43200000);
  }

  public customLogOut(): void {
    this.signOut();
    this.router.navigate([this.routers.LOGIN]);
  }
}
