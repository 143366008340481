import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Regex } from '../regex/regex';

@Pipe({
  name: 'cnpj'
})

@Injectable({
  providedIn: 'root'
})

export class CnpjPipe implements PipeTransform {

  transform(value): string {
    return Regex.cnpj(value);
  }




}
