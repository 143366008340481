import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.hmr';

@Injectable({
  providedIn: 'root'
})
export class CadastroNcmService {

API = environment.API

constructor(private httpClient: HttpClient) {

}

 post(corpo){
  return this.httpClient.post(`${this.API}Ncms`, corpo)
 }

 get(id){
  return this.httpClient.get(`${this.API}Ncms/${id}`)
 }

 delete(id){
  return this.httpClient.delete(`${this.API}Ncms/${id}`)
 }

}
