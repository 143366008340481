import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-search-page',
  templateUrl: './table-search-page.component.html',
  styleUrls: ['./table-search-page.component.css']
})
export class TableSearchPageComponent implements OnInit, OnDestroy {

  public colunasExibidas: any[] = []

  @Input('ColunasTabela') ColunasTabela;
  @Input('ValoresTabela') ValoresTabela = [];
  @Output() Select = new EventEmitter();
  @Output() ResultadoPesquisa = new EventEmitter();

  ngOnInit(): void {
    this.ResultadoPesquisa.emit(true)
    this.colunasExibidas = this.ColunasTabela.map(v => v.valor)
  }

  ngOnDestroy(): void {
    this.ResultadoPesquisa.emit(false)
  }

  public SelecionarLinha(linha): void{
    this.Select.emit(linha)
  }

}
