import { ConsultaProdutoService } from './../../../../services/consulta-produto.service';
import { firstValueFrom } from 'rxjs';
import { Produto } from './../../../../consts/produtos';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';

@Component({
  selector: 'app-alterar-produto',
  templateUrl: './alterar-produto.component.html',
  styleUrls: ['./alterar-produto.component.css'],
})
export class AlterarProdutoComponent implements OnInit {
  FormAlterarProduto: FormGroup;
  selectStatus = [
    { Id: 0, Status: 'Revisão Pendente' },
    { Id: 1, Status: 'Revisado' },
    { Id: 2, Status: 'Atualização pendente' },
    { Id: 3, Status: 'Bloqueado' },
  ];

  closeDialog = false

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public consultaProdutoService: ConsultaProdutoService
  ) {
    this.FormAlterarProduto = this.formBuilder.group({
      CategoriaAnterior: data.Produto.Categoria,
      idCategoriaAnterior: data.Produto.CategoriaId,
      CategoriaNova: [null],
      idCategoriaNova: ['00000000-0000-0000-0000-000000000000'],
      Cliente: data.Produto.Cliente,
      idCliente: data.Produto.ClienteId,
      Codigo: data.Produto.Codigo,
      ProdutoId: data.Produto.ProdutoId,
      Produto: data.Produto.Produto,
      Status: data.Produto.Status,
    });

  }

  ngOnInit() {
    this.montaSelectStatus(this.FormAlterarProduto.value);

  }

  pesquisarCategorias() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Categorias',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((categoriaEscolhida) => {
        if (categoriaEscolhida) {
          this.FormAlterarProduto.patchValue({
            idCategoriaNova: categoriaEscolhida.Id,
            CategoriaNova: categoriaEscolhida.Nome,
          });

          this.selectStatus = [
            {
              Id: 2,
              Status:'Atualização Pendente'
            }
          ]

          this.FormAlterarProduto.patchValue({
            Status: 2
          })
        }
      });
  }

  montaSelectStatus(produto) {
    switch (produto.Status) {
      case 0:
        this.selectStatus = [{ Id: 0, Status: 'Revisão Pendente' }];
        break;

      case 1:
        this.selectStatus = [
          {
            Id: 1,
            Status: 'Revisado',
          },
          {
            Id: 2,
            Status: 'Atualização pendente',
          },
          {
            Id: 3,
            Status: 'Bloqueado',
          },
        ];
        break;

      case 2:
        this.selectStatus = [
          {
            Id: 2,
            Status: 'Atualização Pendente'
          },
          {
            Id: 3,
            Status: 'Bloqueado',
          },
        ];
        break;
      case 3:
        this.selectStatus = [
          {
            Id: 3,
            Status: 'Bloqueado'
          },
          {
            Id: 2,
            Status: 'Atualização pendente',
          },
        ];
        break;
    }
  }

  alterar(atualizar) {
    let objetoAlterar

    if(this.FormAlterarProduto.value.idCategoriaNova == '00000000-0000-0000-0000-000000000000'){
      objetoAlterar = {
        CategoriaId: this.FormAlterarProduto.value.idCategoriaAnterior,
        ProdutoId: this.FormAlterarProduto.value.ProdutoId,
        Status: this.FormAlterarProduto.value.Status,
        ClienteId: this.FormAlterarProduto.value.idCliente
      }

    }

    if(this.FormAlterarProduto.value.idCategoriaNova != '00000000-0000-0000-0000-000000000000'){
      objetoAlterar = {
        CategoriaId: this.FormAlterarProduto.value.idCategoriaNova,
        ProdutoId: this.FormAlterarProduto.value.ProdutoId,
        Status: 2,
        ClienteId: this.FormAlterarProduto.value.idCliente
      }
    }


    firstValueFrom(this.consultaProdutoService.alterarProduto(objetoAlterar))
    .then(response => {
      if(response){
        this.abrirPaginaErro('sistema', response)
        this.closeDialog = true
      }
    })

  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }
}
