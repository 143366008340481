import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Input, OnInit, AfterContentInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.css'],
})
export class AuditoriaComponent implements OnInit, AfterContentInit {
  formAuditoria: FormGroup;
  @Input('DataAlteracao') DataAlteracao;
  @Input('UsuarioModificacao') UsuarioModificacao;
  @Input('DataCadastro') DataCadastro;
  @Input('UsuarioCadastro') UsuarioCadastro;

  constructor(public formBuilder: FormBuilder) {

    this.formAuditoria = this.formBuilder.group({
      DataAlteracao: [''],
      UsuarioAlteracao: [''],
      DataCadastro: [''],
      UsuarioCadastro: [''],
    });
  }

  ngOnInit() {

  }

  ngAfterContentInit(): void {
    this.formataData()

    this.formAuditoria.patchValue({
      DataAlteracao: [this.DataAlteracao],
      UsuarioAlteracao: [this.UsuarioModificacao],
      DataCadastro: [this.DataCadastro],
      UsuarioCadastro: [this.UsuarioCadastro],
    });

  }





  formataData(){
    if(this.DataAlteracao != null){
      this.DataAlteracao = moment(this.DataAlteracao).format("DD/MM/YYYY")
    }

    if(this.DataCadastro != null){
      this.DataCadastro = moment(this.DataCadastro).format("DD/MM/YYYY")
    }
  }
}
