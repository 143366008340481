import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  FormGroup,
} from '@angular/forms';

export class FormCategorias {
  static naturezaValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const validNatureza = formGroup.get('NaturezaReceita')?.value;
      const validSituacao = formGroup.get('SituacaoPisCofinsEntrada')?.value;

      if (validSituacao == 0 || validSituacao == 2) {
        if (validNatureza == '' || validNatureza == null) {
          return { valorNaturezaInvalido: true };
        }
      } else {
        return null;
      }
    };
  }

  static aliquotaIcmsEntradaValidator(Aliquota, Cst): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup2 = control as FormGroup;
      const validAliquota = formGroup2.get(Aliquota)?.value;
      const validCst = formGroup2.get(Cst)?.value;

      if (Aliquota == 'AliquotaIcmsEntrada') {
        if (validCst == '000' || validCst == '020') {
          if (validAliquota == null || validAliquota == '')
            return { valorAliquotaEntradaInvalido: true };
        } else {
          return null;
        }
      }
    };
  }

  static aliquotaIcmsSaidaValidator(Aliquota, Cst): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const validAliquota = formGroup.get(Aliquota)?.value;
      const validCst = formGroup.get(Cst).value;

      if (validCst == '000' || validCst == '020') {
        if (validAliquota == null || validAliquota == '')
          return { valorAliquotaSaidaInvalido: true };
      } else {
        return null;
      }
    };
  }

  static aliquotaStEntradaValidator(Aliquota, Cst): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup3 = control as FormGroup;
      const validAliquotaSt = formGroup3.get(Aliquota)?.value;
      const validCstIcms = formGroup3.get(Cst)?.value;

      if (validCstIcms == '060') {
        if (validAliquotaSt == null || validAliquotaSt == '') {
          return { valorAliquotaStEntradaInvalido: true };
        } else {
          return null;
        }
      }
    };
  }

  static aliquotaStSaidaValidator(Aliquota, Cst): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const validAliquotaSt = formGroup.get(Aliquota)?.value;
      const validCstIcms = formGroup.get(Cst)?.value;

      if (validCstIcms == '060') {
        if (validAliquotaSt == null || validAliquotaSt == '') {
          return { valorAliquotaStSaidaInvalido: true };
        } else {
          return null;
        }
      }
    };
  }

  static alqDesoneracaoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup4 = control as FormGroup;
      const validAliquotaDes = formGroup4.get(
        'AliquotaCalculoDesoneracao'
      )?.value;
      const validCstIcms = formGroup4.get('CstIcmsEntrada')?.value;

      if (
        validCstIcms == '020' ||
        validCstIcms == '040' ||
        validCstIcms == '051'
      ) {
        if (validAliquotaDes == null || validAliquotaDes == '') {
          return { valorAliquotaDesInvalido: true };
        } else {
          return null;
        }
      }
    };
  }

  static motivoDesoneracaoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup5 = control as FormGroup;
      const validCstIcms = formGroup5.get('CstIcmsEntrada')?.value;
      const validMtDesoneracao = formGroup5.get('MotivoDesoneracao')?.value;

      if (
        validCstIcms == '020' ||
        validCstIcms == '040' ||
        validCstIcms == '051'
      ) {
        if (validMtDesoneracao == null || validMtDesoneracao == '') {
          return { MotivoDesoneracaoInvalido: true };
        } else {
          return null;
        }
      }
    };
  }

  static tipoDesoneracaoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const validCstIcms = formGroup.get('CstIcmsEntrada')?.value;
      const validTipoDesoneracao = formGroup.get('TipoDesoneracao')?.value;

      if (
        validCstIcms == '020' ||
        validCstIcms == '040' ||
        validCstIcms == '051'
      ) {
        if (validTipoDesoneracao == null || validTipoDesoneracao == '') {
          return { TipoDesoneracaoInvalido: true };
        } else {
          return null;
        }
      }
    };
  }

  static reducaoBaseCalculoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const validCstIcms = formGroup.get('CstIcmsEntrada')?.value;
      const validReducaoBC = formGroup.get('ReducaoBaseCalculo')?.value;

      if (validCstIcms == '020') {
        if (validReducaoBC == '' || validReducaoBC == null) {
          return { ReducaoBaseCalculoInvalido: true };
        } else {
          return null;
        }
      }
    };
  }

  static consultaProdutoActivate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const idCliente = formGroup.get('idCliente')?.value;
      const idCategoria = formGroup.get('idCategoria')?.value;
      const Produtos = formGroup.get('Produtos')?.value;
      const Codigo = formGroup.get('Codigo')?.value;

      if(idCliente != null || idCategoria != '00000000-0000-0000-0000-000000000000' || Produtos != null || Codigo != '')
      {
        return null
      }
      else{
        return { filtroCPInvalido: true }
      }
    };
  }
}
