<form [formGroup]="filtrosPesquisa">
    <div style="margin: 15px;">
        <mat-accordion displayMode="default" [multi]="true" [hideToggle]="false">
            <mat-expansion-panel [hideToggle]="false" [expanded]="filtrosExpanded">
                <mat-expansion-panel-header>Consulta de produtos</mat-expansion-panel-header>
                <div class="row">
                    <div class="col-sm-6 col-12" *ngIf="idClienteUsuario == 0">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Cliente</mat-label>
                            <input formControlName="Cliente" matInput type="text" readonly>
                            <button *ngIf="filtrosPesquisa.value.Cliente == null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCliente()">
                <mat-icon>search</mat-icon>
              </button>
                            <button *ngIf="filtrosPesquisa.value.Cliente != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="apagarCampoCliente()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-6 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                            <mat-label>Categoria</mat-label>
                            <input formControlName="Categoria" matInput type="text" readonly>
                            <button *ngIf="filtrosPesquisa.value.Categoria == null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCategorias()">
                <mat-icon>search</mat-icon>
              </button>
                            <button *ngIf="filtrosPesquisa.value.Categoria != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="apagarCampoCategoria()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-9 col-12">
                        <mat-form-field class="formField-Font" class="formField-Font" appearance="fill" style="width: 100%">
                            <mat-label>Produto</mat-label>
                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let palavra of palavrasChaves" [selectable]="selectable" [removable]="removable" (removed)="remove(palavra)">
                                    {{palavra}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Nova Palavra...(Max: 5 palavras)" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>

                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                            <mat-label>Código do Produto</mat-label>
                            <input formControlName="Codigo" matInput type="text" maxlength="50">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Data de inicio</mat-label>
                            <input [matDatepicker]="picker" (focus)="picker.open()" formControlName="DataInicio" matInput readonly>
                            <button *ngIf="filtrosPesquisa.value.DataInicio != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparDataInicio()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>

                    <div class="align-self-center col-sm-1 col-12">
                        <p class="ate">até</p>
                    </div>


                    <div class="col-sm-2 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Data Final</mat-label>
                            <input [matDatepicker]="picker1" (focus)="picker1.open()" formControlName="DataFim" matInput readonly>
                            <button *ngIf="filtrosPesquisa.value.DataFim != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparDataFim()">
                <mat-icon>close</mat-icon>
              </button>
                        </mat-form-field>
                        <mat-datepicker #picker1></mat-datepicker>
                    </div>

                    <div class="col-sm-3 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                            <mat-label>Ncm</mat-label>
                            <input formControlName="Ncm" matInput type="text" maxlength="8">
                        </mat-form-field>
                    </div>

                    <div class="col-sm-4 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="Status">
                                <mat-option *ngFor="let item of selectStatus" [value]="item.Id">
                                    {{item.Nome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>

                <div class="row justify-content-end" style="height: 10%;">
                    <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button color="primary" [disabled]="filtrosPesquisa.status == 'INVALID'" (click)="getTabela()">Pesquisar</button></div>
                    <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button (click)="limparFiltro()">Limpar
              Filtro</button></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <mat-card>
        <table style="width: 100%; height: auto;" mat-table [dataSource]="dataSourceProdutos" multiTemplateDataRows>
            <ng-container matColumnDef="Categoria">
                <th class="w-20" mat-header-cell *matHeaderCellDef>Categoria</th>
                <td mat-cell *matCellDef="let element">{{element.Categoria}}</td>
            </ng-container>

            <ng-container matColumnDef="Produto">
                <th class="w-20" mat-header-cell *matHeaderCellDef>Produto</th>
                <td mat-cell *matCellDef="let element">{{element.Produto}}</td>
            </ng-container>
            <ng-container matColumnDef="Codigo">
                <th class="w-10" mat-header-cell *matHeaderCellDef>Código</th>
                <td mat-cell *matCellDef="let element">{{element.Codigo}}</td>
            </ng-container>
            <ng-container matColumnDef="Interno">
                <th class="w-5" mat-header-cell *matHeaderCellDef>Interno</th>
                <td mat-cell *matCellDef="let element">{{element.Interno}}</td>
            </ng-container>
            <ng-container matColumnDef="Cliente">
                <th class="w-10" mat-header-cell *matHeaderCellDef>Cliente</th>
                <td mat-cell *matCellDef="let element">{{element.Cliente}}</td>
            </ng-container>
            <ng-container matColumnDef="NomeStatus">
                <th class="w-15" mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">{{element.NomeStatus}}</td>
            </ng-container>
            <ng-container matColumnDef="Reclassificar">
                <th class="w-10" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"><button mat-raised-button color="Sugestao" (click)="abrirReclassificar(element)" [disabled]="idClienteUsuario != 0">Alterar</button></td>
            </ng-container>
            <ng-container matColumnDef="Detalhe">
                <th class="w-10" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"><button mat-raised-button color="primary" (click)="abrirDetalhe(element)" [disabled]="element.Categoria == null">Detalhe</button></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="produtosColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: produtosColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,100]" aria-label="Selecione nº de Clientes"></mat-paginator>
    </mat-card>
</form>