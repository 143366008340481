<form [formGroup]="formAlterarSenha">
    <mat-card>
        <mat-card-title class="matCard-Font">Alterar Senha</mat-card-title>

        <br>
        <div class="row">
            <mat-form-field style="width: 30%;" class="formField-Font" appearance="fill">
                <mat-label>Senha Atual</mat-label>
                <input matInput formControlName="SenhaAntigo" type="password">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field style="width: 30%;" class="formField-Font" appearance="fill">
                <mat-label>Nova Senha</mat-label>
                <input matInput formControlName="SenhaNova" type="password">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field style="width: 30%;" class="formField-Font" appearance="fill">
                <mat-label>Confirmação da Senha</mat-label>
                <input matInput formControlName="VerificacaoSenha" type="password">
            </mat-form-field>
        </div>






        <div class="row justify-content-center">
            <div class="col-sm-1 col-12">
                <button mat-raised-button color="primary" [disabled]="formAlterarSenha.status == 'INVALID'" (click)="postAlterarSenha()">Gravar</button>
            </div>

        </div>
    </mat-card>
</form>
