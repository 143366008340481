import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.hmr';

@Injectable({
  providedIn: 'root'
})
export class ConsultaProdutoService {

  private API = environment.API

constructor(private httpClient: HttpClient) { }

  getConsultaProdutos(corpo){
    return this.httpClient.post(`${this.API}ConsultaProduto/GetConsultaProduto`, corpo)
  }

  alterarProduto(corpo){
    return this.httpClient.post(`${this.API}ConsultaProduto/AlterarProduto`, corpo)
  }

}
