import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { typeMesages } from 'src/app/consts';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent {

  public typeMesages: typeof typeMesages = typeMesages;
  public displayedColumns: Array<string> = ['TipoMensagem', 'DescricaoErro'];
  public dataSource: Array<any> = [];
  public isCollapsed: boolean = true;

  public codigo: string;
  public menssagemComplementar: string
  public mensagemEscolhida = null

  constructor(@Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ErrorPageComponent>) {

      switch(data.tipo){

        case typeMesages.ERRO:    this.montaMensagemErro(data);
                                  break;
        case typeMesages.SISTEMA: this.montaMensagemSistema(data);
                                  break;
        case typeMesages.INTERNO: this.montaMensagemInterna(data);
                                  break;

      }
  
  }

  public montaMensagemErro(data): void{
    this.dataSource = [
      {
        Codigo: data.mensagem.status,
        Descricao: data.mensagem.name,
        MensagemComplementar: data.mensagem.message,
        TipoMensagem: 2
      }
    ]
  }

  public montaMensagemSistema(data): void{
    if (data.mensagem.length) {

      data.mensagem.map(mensagem => mensagem.Codigo).forEach((codigo, i) => {

        if (this.dataSource.length == 0) {
          this.dataSource.push(data.mensagem[i])
        } else {
          if (!this.dataSource.map(mensagem => mensagem.Codigo).includes(codigo)) {
            this.dataSource.push(data.mensagem[i])
          }
        }

      })

      // if (this.dataSource.filter(mensagem => mensagem.Codigo != 'CRM00001' && mensagem.Codigo != 'CRM00003').length > 0) {
      //   this.dataSource = this.dataSource.filter(mensagem => mensagem.Codigo != 'CRM00001' && mensagem.Codigo != 'CRM00003')
      // }

    } else {
      this.dataSource = [data.mensagem]
    }
  }

  public montaMensagemInterna(data): void{
      
    if (typeof (data.mensagem) == "string") {

      this.dataSource = [
        {
          Codigo: "-",
          Descricao: data.mensagem,
          MensagemComplementar: "",
          TipoMensagem: 1
        }
      ]

    } else {

      this.dataSource = data.mensagem.map(mensagem => {
        return {
          Codigo: "-",
          Descricao: mensagem,
          MensagemComplementar: "",
          TipoMensagem: 1
        }
      })

    }
  }

  public verMais(element): void {
    this.codigo = element.codigo;
    this.menssagemComplementar = element.menssagemComplementar
  }

  public fecharJanela(): void {
    this.dialogRef.close();
  }

  public escolherMensagem(mensagemEscolhida): void {
    if (mensagemEscolhida.MensagemComplementar) {
      this.mensagemEscolhida = mensagemEscolhida
    }
  }

  public fecharMensagem(): void {
    this.mensagemEscolhida = null
  }

}
