import { ClienteService } from './../../../services/cliente.service';
import { RevisaoService } from './../../../services/revisao.service';
import { firstValueFrom, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Cliente } from 'src/app/consts/cliente';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ClienteProdutoQtd } from 'src/app/consts/revisao';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { __await } from 'tslib';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

@Component({
  selector: 'app-revisao',
  templateUrl: './revisao.component.html',
  styleUrls: ['./revisao.component.css']
})
export class RevisaoComponent implements OnInit {

  //mat chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true
  readonly separatorKeysCodes = [ENTER, COMMA] as const
  palavrasChaves: string[] = []

  formularioPesquisa: FormGroup;

  //Variáveis da Tabela de Revisão
  showTable: boolean = false
  dataSourceClientes = new MatTableDataSource<ClienteProdutoQtd>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  eventsSubject: Subject<MatSort> = new Subject<MatSort>();

  constructor(public formBuilder: FormBuilder,
    public revisaoService: RevisaoService,
    public clienteService: ClienteService,
    public dialog: MatDialog
    ) {

    this.formularioPesquisa = this.formBuilder.group({
      Cliente: [null],
      idCliente: [null],
      Ncm: [null],
      Codigo: [null],
      DataInicio: [null],
      DataFim: [null],
      Produtos: [[]]
    })

  }

  ngOnInit() {
  }

  dataSort(): MatSort{
    return this.sort
  }

  emitEventToChild(){
    this.eventsSubject.next(this.dataSort());
  }

  //Chips Events
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value
    if(this.palavrasChaves.length < 5){
      if((value || '').trim()){
        this.palavrasChaves.push(value)
      }
    }

    if(input){
      input.value = ''
    }
  }

  remove(palavra: string): void {
    const index = this.palavrasChaves.indexOf(palavra);
    if(index >= 0){
      this.palavrasChaves.splice(index, 1);
    }
  }

  async getTabela(){
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    this.formataFiltro(this.formularioPesquisa);
    firstValueFrom(this.revisaoService.getClientesQtdProdutos(this.formularioPesquisa.value))
    .then((response): any => {
      this.atualizaDataSource(response)
      dialogRef.close()
    })
  }

  pesquisarCliente() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Clientes',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((clienteEscolhido) => {
        if (clienteEscolhido) {
          firstValueFrom(this.clienteService.get(clienteEscolhido.Id)).then(
            (cliente: Cliente) => {
              this.formularioPesquisa.patchValue({
                idCliente: cliente[0].Id,
                Cliente: cliente[0].Nome
              })
            }
          );
        }
      });
  }

  limparNcm(){
    this.formularioPesquisa.patchValue({
      Ncm: null
    })
  }

  pesquisarNcm(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Ncms',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((codigoEscolhido) => {
        if (codigoEscolhido) {
          this.formularioPesquisa.patchValue({
            Ncm: codigoEscolhido.Codigo,
          })
        }
      });

}

  formataFiltro(form){
    this.formularioPesquisa.patchValue({
      idCliente: form.value.idCliente,
        Ncm: form.value.Ncm,
        Produtos: this.palavrasChaves,
        DataInicio: form.value.DataInicio,
        DataFim: form.value.DataFim
    })
  }

  apagarCampoCliente(){
    this.formularioPesquisa.patchValue({
      idCliente: null,
      Cliente: null
    })
  }

  async atualizaDataSource(datasource){
    await (this.dataSourceClientes = new MatTableDataSource<ClienteProdutoQtd>(datasource))
    await (this.dataSourceClientes.paginator = this.paginator)
    await (this.dataSourceClientes.sort = this.sort)
    this.dataSourceClientes._updateChangeSubscription()
  }

  limparDataInicio(){
    this.formularioPesquisa.patchValue({
      DataInicio: null
    })
  }

  limparDataFim(){
    this.formularioPesquisa.patchValue({
      DataFim: null
    })
  }

  limparFiltro(){

    this.dataSourceClientes = new MatTableDataSource()
    this.dataSourceClientes.paginator = this.paginator
    this.palavrasChaves = []
    this.formularioPesquisa.patchValue({
      Cliente: null,
      idCliente: null,
      Ncm: null,
      DataInicio: null,
      DataFim: null,
      Produtos: [],
      Codigo: null
    })
  }
}
