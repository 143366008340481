import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.hmr';
import { permissoes } from '../consts';
import { user } from '../consts/user';

@Injectable({
  providedIn: 'root'
})
export class MontaMenuService {

  API = environment.API
  permissoes: any = permissoes;

  constructor(private http: HttpClient) { }

  public get(): Observable<any[]>{

    return this.http.get<any[]>(`${this.API}MontaMenuSistema/GetPermissaoUsuario`)

  }

  async insertPermissoes(): Promise<void>{

    this.permissoes.limparLista();

    await firstValueFrom(this.get())
      .then(response => {
        response.forEach(item => {
          if(!this.permissoes.listaPermissoes.includes(item)){
            this.permissoes.listaPermissoes.push(item)
          }

    })
    })

  }

  public inserePermissoes(listaParametros: Array<any>): void{

    permissoes.listaPermissoes.forEach(item => {
      item.ListaParametros = listaParametros.filter(parametro => parametro.cdFuncao == item.cdFuncao)
    })
  }
}
