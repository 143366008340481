import { Component } from '@angular/core';

@Component({
  selector: 'app-seguranca',
  template:`
  <router-outlet></router-outlet>
  `,
})
export class SegurancaComponent{

}
