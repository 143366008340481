import { TributacaoEstadual } from './../../../../../consts/categoria';
import { ListaUF } from './../../../../../consts/ListasSelects';
import { MensagemResposta } from './../../../../../consts/mensagem';
import { DetalharCategoriaComponent } from './detalhar-categoria/detalhar-categoria.component';
import { RevisaoService } from './../../../../../services/revisao.service';
import { map } from 'rxjs/operators';
import { CategoriasService } from './../../../../../services/categorias.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProdutoRevisao } from './../../../../../consts/revisao';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InjectableCompiler } from '@angular/compiler/src/injectable_compiler';
import { Component, Inject, OnInit, ViewChild, OnChanges, SimpleChanges, AfterContentInit, AfterViewInit } from '@angular/core';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { firstValueFrom } from 'rxjs';
import { Categoria, ListaSituacaoPISCOFINS, ListaCSTICMS } from 'src/app/consts/categoria';
import { SelectionModel } from '@angular/cdk/collections';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-tabela-cliente-revisao',
  templateUrl: './tabela-cliente-revisao.component.html',
  styleUrls: ['./tabela-cliente-revisao.component.css']
})
export class TabelaClienteRevisaoComponent implements OnInit, AfterViewInit {

  selection = new SelectionModel<ProdutoRevisao>(true, []);
  formularioProdutos: FormGroup
  dataSourceProdutos = new MatTableDataSource<ProdutoRevisao>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ColunasProdutos = ['Select', 'NomeProduto', 'Codigo', 'Interno', 'Ncm', 'DataCadastro'];
  ObjetoCategoria: Categoria
  btnDetalhar: Boolean = true;
  dadosCliente;

  constructor(@Inject(MAT_DIALOG_DATA) data,
  public formBuilder: FormBuilder,
  public dialog: MatDialog,
  private categoriasService: CategoriasService,
  private revisaoService: RevisaoService) {

    this.atribuiDataSource(data)


    this.formularioProdutos = this.formBuilder.group({
      Cliente: data.dadosCliente.Nome,
      IdCliente: data.dadosCliente.Id,
      QuantidadeProduto: data.dadosCliente.QuantidadeProduto,
      Uf: data.dadosCliente.Uf,
      idCategoria: ['00000000-0000-0000-0000-000000000000'],
      Categoria: [null],
      SituacaoIcms: [null],
      Ncm: [null],
      Filtrar: [null],
    })
  }

  ngOnInit() {


  }

  ngAfterViewInit(): void {
      this.dataSourceProdutos.sort = this.sort
      this.dataSourceProdutos.paginator = this.paginator
      this.formularioProdutos.controls.Filtrar.valueChanges.subscribe(data => {
        this.applyFilter(data || '');
        console.log(data)
      })
  }

  applyFilter(filterValue: string): void {
    this.dataSourceProdutos.filter = filterValue.trim().toLowerCase();
  }

  atribuiDataSource(data){
   this.dataSourceProdutos = new MatTableDataSource<ProdutoRevisao>(data.dados)
   this.dataSourceProdutos._updateChangeSubscription()
  }

  pesquisarCategoria(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Categorias',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((categoriaEscolhida) => {
        if (categoriaEscolhida) {
          this.formularioProdutos.patchValue({
            Categoria: categoriaEscolhida.Nome,
            idCategoria: categoriaEscolhida.Id,
            Ncm: categoriaEscolhida.Ncm
          })

          firstValueFrom(this.categoriasService.get(categoriaEscolhida.Id))
          .then((response: Categoria) => {
            if(response){
              this.ObjetoCategoria = response
              this.formularioProdutos.patchValue({
                SituacaoIcms: this.formataSituacao(response.ListaTributacaoEstadual)
              })
              this.btnDetalhar = false
            }
          })
        }
      });
  }

  revisarProduto(){
    //Separa os Id's dos produtos selecionados
    let arrayGuid = []
    this.selection.selected.forEach(e => {
      arrayGuid.push(e.ProdutoId)
    })

    let objetoRevisar = {
      CategoriaId: this.formularioProdutos.value.idCategoria,
      ListaProdutoId: arrayGuid
    }

    firstValueFrom(this.revisaoService.postRevisarProdutos(objetoRevisar))
    .then((response: MensagemResposta) => {
      if(response){
        this.abrirPaginaErro('sistema', response)
        if(response.Codigo = 'CRM00001'){
          this.selection.selected.forEach(item => {
            this.dataSourceProdutos.data.splice(this.dataSourceProdutos.data.indexOf(item), 1)
          })
          this.formularioProdutos.patchValue({
            QuantidadeProduto: this.formularioProdutos.value.QuantidadeProduto - this.selection.selected.length
          })
          this.selection.clear();
        }
      else{
        this.abrirPaginaErro('sistema', response)
      }
        this.dataSourceProdutos._updateChangeSubscription();
      }
    })
  }

  abrirDetalheCategoria(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      Categoria: this.ObjetoCategoria
    }
    dialogConfig.height = '80vh'
    dialogConfig.width = '60vw'
    this.dialog.open(DetalharCategoriaComponent, dialogConfig)
  }

  formataSituacao(tributacao){

    let uf = tributacao.filter(a => a.TxtUf == this.formularioProdutos.value.Uf)
    return uf[0].TxtSituacaoIcmsEntrada
  }

  //bloco de código das checkbox
   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceProdutos.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        // this.dataSourceProdutos.data.forEach(row => this.selection.select(row));
        this.dataSourceProdutos.filteredData.forEach(row => {this.selection.select(row)});
    }

  abrirPaginaErro(tipo, mensagem){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }
}
