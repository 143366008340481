import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.hmr';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  API = environment.API

constructor(private httpClient: HttpClient) {

}

  get(id) {
    return this.httpClient.get(`${this.API}Usuario/${id}`)
  }

  getLoggedUser() {
    return this.httpClient.get(`${this.API}Usuario`)
  }

  post(corpo){
    return this.httpClient.post(`${this.API}Usuario`, corpo)
  }

  delete(id){
    return this.httpClient.delete(`${this.API}Usuario/${id}`)
  }

  alterarSenha(corpo){
    return this.httpClient.post(`${this.API}Usuario/AlterarSenha`, corpo)
  }
}
