import * as _ from 'lodash';

export const listaTipoDeNegocio = [
  'MERCADO',
  'PAPELARIA',
  'HOLDING E ADMINISTRADORA DE BENS',
  'PRODUTOS ALIMENTÍCIOS',
  'SERVIÇOS ADMINISTRATIVOS',
  'BAR, LANCHONETE, RESTAURANTE E AFINS',
  'HORTIFRUTI',
  'FARMÁCIA E AFINS',
  'PADARIA',
  'AÇOUGUE, FRIGORÍFICO, CARNES E AFINS',
  'COSMÉTICOS E PERFUMARIA',
  'TRANSPORTE E LOGÍSTICA',
  'REFEIÇÕES',
  'MATERIAIS DE CONSTRUÇÃO',
  'DEPÓSITO',
  'GESTÃO FINANCEIRA',
  'ESTACIONAMENTO',
  'AGÊNCIA DE VIAGENS',
  'DOCES E AFINS',
  'EMBALAGENS',
  'SERVIÇOS MÉDICOS E AMBULATORIAIS',
  'VESTUÁRIO E ACESSÓRIOS',
  'RELÓGIOS E JOALHERIA',
  'CONSULTORIA',
  'CERVEJARIA',
  'CEREAIS, TEMPEROS, MOLHOS E CONDIMENTOS',
  'LOJA DE DEPARTAMENTOS',
  'ELETRODOMÉSTICOS',
  'REFRIGERAÇÃO',
  'AUTO PEÇAS',
  'ACADEMIA',
  'AÇOUGUE',
  'SERVIÇOS DE ENTREGA',
  'PRODUTOS ELÉTRICOS',
  'GRÁFICA E EDITORA',
  'BEBIDAS',
  'EVENTOS',
  'ÓTICA',
  'GÁS',
  'PARTICIPAÇÕES (EXCETO HOLDING)',
  'VENDAS E REPRESENTAÇÕES',
  'PRESTADORA DE SERVIÇOS',
  'PRODUTOS HOSPITALARES',
  'AGROPECUÁRIA',
  'MÓVEIS',
  'ILUMINAÇÃO, EQUIPAMENTOS ELÉTRICOS E AFINS',
  'PRODUTOS DE LIMPEZA E HIGIENE E AFINS',
  'ALIMENTAÇÃO PARA EVENTOS',
  'FERRAGENS E FERRAMENTAS',
  'TELEFONIA E COMUNICAÇÃO',
  'FABRICAÇÃO DE PÃO',
  'CONSTRUÇÃO CIVIL',
  'CASA LOTÉRICA',
  'CONTABILIDADE',
  'INCORPORADORA',
  'INFORMÁTICA',
  'LATICÍNIO',
  'PETSHOP',]

export const ListaUF = _.orderBy([
    {
      idUF:'',
      UF:''
    },
    {
      idUF: 11,
      UF: 'RO'
    },
    {
      idUF: 12,
      UF: 'AC'
    },
    {
      idUF: 13,
      UF: 'AM'
    },
    {
      idUF: 14,
      UF: 'RR'
    },
    {
      idUF: 15,
      UF: 'PA'
    },
    {
      idUF: 16,
      UF: 'AP'
    },
    {
      idUF: 17,
      UF: 'TO'
    },
    {
      idUF: 21,
      UF: 'MA'
    },
    {
      idUF: 22,
      UF: 'PI'
    },
    {
      idUF: 23,
      UF: 'CE'
    },
    {
      idUF: 24,
      UF: 'RN'
    },
    {
      idUF: 25,
      UF: 'PB'
    },
    {
      idUF: 26,
      UF: 'PE'
    },
    {
      idUF: 27,
      UF: 'AL'
    },
    {
      idUF: 28,
      UF: 'SE'
    },
    {
      idUF: 29,
      UF: 'BA'
    },
    {
      idUF: 31,
      UF: 'MG'
    },
    {
      idUF: 32,
      UF: 'ES'
    },
    {
      idUF: 33,
      UF: 'RJ'
    },

    {
      idUF: 35,
      UF: 'SP'
    },

    {
      idUF: 41,
      UF: 'PR'
    },

    {
      idUF: 42,
      UF: 'SC'
    },

    {
      idUF: 43,
      UF: 'RS'
    },

    {
      idUF: 50,
      UF: 'MS'
    },
    {
      idUF: 51,
      UF: 'MT'
    },
    {
      idUF: 52,
      UF: 'GO'
    },
    {
      idUF: 53,
      UF: 'DF'
    },
  ], ['UF'],['asc'])

export const TipoIntegracao = [
  'API','ARQUIVO','INTEGRADOR','INTEGRADOR WEB'
]
