<mat-card>
    <mat-card-title class="matCard-Font">Sistemas</mat-card-title>
</mat-card>

<mat-card>
    <mat-card-subtitle>Dados do sistema</mat-card-subtitle>

    <mat-card-content>
        <form [formGroup]="formularioSistema">
            <div class="row">
                <div class="col-sm-5 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Nome do sistema</mat-label>
                        <input formControlName="Nome" matInput type="text">
                        <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="procurarSistema()">
                    <mat-icon>search</mat-icon>
                    </button>
                    </mat-form-field>
                </div>
                <div class="col-sm-3 col-12">
                    <mat-form-field class="formField-Font" appearance="fill">
                        <mat-label>Tipo de Integração</mat-label>
                        <mat-select formControlName="TipoIntegracao">
                            <mat-option *ngFor="let integracao of tipoDeIntegracao" [value]="integracao">
                                {{integracao}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<app-crud-buttons [showPost]="formularioSistema.value.Id == 0" [showPut]="formularioSistema.value.Id != 0" [showDelete] [labelBotaoPost]="'Gravar'" [labelBotaoPut]="'Gravar'" [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="formularioSistema.status == 'INVALID'"
    [disablePut]="formularioSistema.status == 'INVALID'" [disableDelete]="formularioSistema.value.Id == 0" (OnClickButtonPost)="post()" (OnClickButtonPut)="post()" (OnClickButtonAuxUm)="limpar()" (OnClickButtonDelete)="delete()">
</app-crud-buttons>
