import { NgModule } from '@angular/core';
import { SegurancaComponent } from './seguranca.component';
import { RouterModule, Routes } from '@angular/router';
import { PerfilAcessoComponent } from './perfil-acesso/perfil-acesso.component';
import { PerfilAcessoResolve } from './perfil-acesso/perfil-acesso.resolve';

const routes: Routes = [
  {
    path: '',
    component: SegurancaComponent,
    children: [
      {
        path:'perfil-de-acesso',
        component: PerfilAcessoComponent,
        resolve: { dadosPerfilAcesso: PerfilAcessoResolve }
      },
    ]
  }
]
@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class SegurancaRoutingModule { }

export const routedComponents = [
  PerfilAcessoComponent,
  SegurancaComponent
]
