import { InformativoCategoria, TipoArquivo } from './../../../consts/categoria';
import { first, indexOf } from 'lodash';
import { MensagemResposta } from './../../../consts/mensagem';
import { CategoriasService } from 'src/app/services/categorias.service';
import { firstValueFrom } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, NgModule } from '@angular/core';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

@Component({
  selector: 'app-conteudo-tributacao',
  templateUrl: './conteudo-tributacao.component.html',
  styleUrls: ['./conteudo-tributacao.component.css'],
})
export class ConteudoTributacaoComponent implements OnInit {
  formularioConteudos!: FormGroup;

  ListaTiposArquivo = TipoArquivo;

  get ListaLinks(): FormArray {
    return this.formularioConteudos.get('ListaLinks') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public categoriaService: CategoriasService
  ) {}

  ngOnInit() {
    this.formularioConteudos = this.fb.group({
      Categoria: [null],
      CategoriaId: ['00000000-0000-0000-0000-000000000000'],
      ListaLinks: this.fb.array([
        this.fb.group({
          Id: [0],
          LinkConteudo: [null],
          Tipo: [null],
          Descricao: [null],
        }),
      ]),
    });
  }

  removeConteudo(index: number) {
    this.ListaLinks.removeAt(index);
  }

  limparFormulario() {
    this.formularioConteudos.patchValue({
      CategoriaId: '00000000-0000-0000-0000-000000000000',
      Categoria: null,
    });
    this.limparListaLinks();
  }

  limparListaLinks() {
    this.ListaLinks.value.forEach((element, index) => {
      this.ListaLinks.removeAt(0);
    });
  }

  buildConteudo(link) {
    this.ListaLinks.push(
      this.fb.group({
        Id: link.Id,
        LinkConteudo: link.LinkConteudo,
        Tipo: link.Tipo,
        Descricao: link.Descricao,
      })
    );
  }

  addNovoConteudo() {
    this.ListaLinks.push(
      this.fb.group({
        Id: [0],
        LinkConteudo: [''],
        Tipo: [''],
        Descricao: [''],
      })
    );
  }

  pesquisarCategorias() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Categorias',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((categoriaEscolhida) => {
        if (categoriaEscolhida) {
          this.formularioConteudos.patchValue({
            Categoria: categoriaEscolhida.Nome,
            CategoriaId: categoriaEscolhida.Id,
          });
        }
      });
  }

  PostInformativo() {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    firstValueFrom(
      this.categoriaService.postInformativo(
        this.formataObjetoPost(
          this.formularioConteudos.value.CategoriaId,
          this.ListaLinks.value
        )
      )
    ).then((response: MensagemResposta) => {
      this.abrirPaginaErro('sistema', response);
      this.limparListaLinks()
      dialogRef.close()
    });
  }

  getInformativo(id) {
    firstValueFrom(this.categoriaService.getInformativo(id)).then(
      (response: InformativoCategoria) => {
        if (response.ListaLinks) {
          this.limparListaLinks();
          response.ListaLinks.forEach((link) => this.buildConteudo(link));
        } else {
          this.abrirPaginaErro('sistema', response);
        }
      }
    );
  }

  delete(id, index) {
    firstValueFrom(this.categoriaService.deleteInformativo(id)).then(
      (response: MensagemResposta) => {
        if (response) {
          this.abrirPaginaErro('sistema', response);
          this.removeConteudo(index)
        }
      }
    );
  }

  formataObjetoPost(categoria, lista) {
    let obj;

    obj = {
      CategoriaId: categoria,
      ListaLinks: lista,
    };
    return obj;
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }
}
