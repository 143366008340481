import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services';

import { firstValueFrom, lastValueFrom } from 'rxjs';
import { permissoes, routes } from 'src/app/consts';
import { MontaMenuService } from 'src/app/services/monta-menu.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { user } from 'src/app/consts/user';
import { MatSnackBar } from '@angular/material/snack-bar'
import { duration } from 'moment';
import { LoginFormComponent } from '../../components';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {
  public todayDate: Date = new Date();
  public routers: typeof routes = routes;
  public permissoes = permissoes
  @ViewChild(LoginFormComponent) loginForm


  constructor(
    private service: AuthService,
    private router: Router,
    private montaMenuService: MontaMenuService,
    private clienteService: ClienteService,
    private snackBar: MatSnackBar,
  ) { }

  public async sendLoginForm(event) {
    await this.login(event);
    await this.montaMenuService.insertPermissoes();
    // await this.montaMenuService.getParametrosPermissaoPerfilAcesso();
    // await this.set_cliente_usuario_logado();

    this.router.navigate(['/pages' + this.routers.DASHBOARD])


  }

  public sendSignForm(): void {
    this.service.sign();
    this.router.navigate([this.routers.DASHBOARD]).then();
  }


  public async login(event){
    await firstValueFrom(this.service.login(event))
      .then(
        (response: any) => {
          if (response.token) {
            this.service.loggedIn.next(
              {
                userInfo: JSON.stringify(response),
                loggedStatus: true
              }
            )

          }
          else {
            alert(response)
          }
        }
      )
      .catch(
        err => {
          this.snackBar.open('Login ou Senha incorretos.','Fechar',
          {
            verticalPosition: 'top',
            panelClass: ['whiteSnackBar'],
            duration: 5000,
          })
          this.loginForm.ativaLogin = false
        }
      )
  }

  // async set_cliente_usuario_logado(){
  //   await firstValueFrom(this.clienteService.get_cliente_usuario_logado())
  //     .then(r => {

  //       if(r){
  //         user.insertUserClient(r[0])
  //       }
  //     })

  // }


}
