import { Component, OnInit } from '@angular/core';
import { Grafico, Tabela } from 'src/app/consts/graficos';

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.css']
})
export class GraficosComponent implements OnInit {

  public ListaGraficos: Array<Grafico> = []
  public ListaTabelas: Array<Tabela> = []

  constructor() { }

  ngOnInit() {
  }

}
