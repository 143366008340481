import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataPipe } from './data.pipe';
import { DataSemHoraPipe } from './data-sem-hora.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { CepPipe } from './cep.pipe';
import { MomentPipe } from './Moment.pipe';



@NgModule({
  declarations: [	
    DataPipe,
    DataSemHoraPipe,
    CnpjPipe,
    CepPipe,
      MomentPipe
   ],
  imports: [
    CommonModule
  ],
  exports: [
    DataPipe,
    DataSemHoraPipe,
    CnpjPipe,
    CepPipe
  ]
})
export class PipesModule { }
