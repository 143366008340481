<table style="width: 100%; height: 100%; overflow: scroll;" mat-table [dataSource]="dataSourceEmpresas" matSort>
    <ng-container matColumnDef="Nome">
        <th class="w-45" mat-header-cell *matHeaderCellDef mat-sort-header>Nome do cliente</th>
        <td mat-cell *matCellDef="let element">{{ element.Nome }}</td>
    </ng-container>

    <ng-container matColumnDef="QuantidadeProduto">
        <th class="w-45" mat-header-cell *matHeaderCellDef mat-sort-header>Qtd.Produtos</th>
        <td mat-cell *matCellDef="let element">{{element.QuantidadeProduto}}</td>
    </ng-container>

    <ng-container matColumnDef="QuantidadeSugestao">
        <th class="w-45" mat-header-cell *matHeaderCellDef mat-sort-header>Qtd.Sugestao</th>
        <td mat-cell *matCellDef="let element">{{element.QuantidadeSugestao}}</td>
    </ng-container>

    <ng-container matColumnDef="Sugestao">
        <th class="w-10" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="Sugestao" (click)="sugestaoCategoria(element)">Sugestão</button>
        </td>
    </ng-container>

    <ng-container matColumnDef="Detalhe">
        <th class="w-10" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary" (click)="detalheCliente(element)">Detalhe</button>
        </td>
    </ng-container>

    <tr style="height: 40px;" mat-header-row *matHeaderRowDef="ColunasEmpresas"></tr>
    <tr style="height: 60px;" mat-row *matRowDef="let row; columns: ColunasEmpresas"></tr>
</table>
