<form [formGroup]="formularioConteudos" class="form-container">
    <mat-card>
        <mat-card-title class="matCard-Font">Informativos de Categoria</mat-card-title>
        <br>
        <div class="col-sm-6 col-12">
            <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                <mat-label>Categoria</mat-label>
                <input formControlName="Categoria" matInput type="text">
                <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCategorias()">
          <mat-icon>search</mat-icon>
        </button>
            </mat-form-field>
        </div>
        <div class="row justify-content-end">
            <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button color="primary" (click)="getInformativo(formularioConteudos.value.CategoriaId)">Pesquisar</button></div>
            <div class="col-sm-2 col-12"><button class="format-button" mat-raised-button (click)="addNovoConteudo()">Adicionar
          novo link</button></div>


        </div>
    </mat-card>

    <ng-container formArrayName="ListaLinks">
        <ng-container *ngFor="let _ of ListaLinks.controls; let idx = index" [formGroupName]="idx">
            <mat-card>
                <mat-card-title class="matCard-Font">Arquivo {{idx + 1}} </mat-card-title>
                <br>
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Link</mat-label>
                            <input formControlName="LinkConteudo" matInput type="url">
                        </mat-form-field>
                    </div>

                    <div class="col-sm-6 col-12">
                        <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                            <mat-label>Descrição</mat-label>
                            <input formControlName="Descricao" matInput type="text">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">


                    <div class="col-sm-6 col-12">
                        <mat-form-field class="formField-Font" appearance="fill">
                            <mat-label>Tipo</mat-label>
                            <mat-select formControlName="Tipo">
                                <mat-option *ngFor="let item of ListaTiposArquivo" [value]="item">
                                    {{item}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row justify-content-end">
                    <div class="col-sm-2 col-12" *ngIf="_.value.Id != 0">
                        <button class="format-button" mat-raised-button color="warn" (click)="delete(_.value.Id, idx)"><span>Excluir</span></button>
                    </div>

                    <div class="col-sm-2 col-12" *ngIf="_.value.Id == 0">
                        <button class="format-button" mat-raised-button color="warn" (click)="removeConteudo(idx)"><span>Excluir</span></button>
                    </div>
                    <div class="col-sm-2 col-12">
                        <a [disabled]="_.value.Id == 0" style="width: 100%;" mat-raised-button color="primary" target="_blank" [href]="_.value.LinkConteudo"><span>Abrir Link</span></a>

                    </div>

                </div>
            </mat-card>

        </ng-container>

    </ng-container>

    <app-crud-buttons [showPost]="true" [showPut]="false" [showDelete]="false" [labelBotaoPost]="'Gravar'" [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="formularioConteudos.status == 'INVALID'" [disableDelete]="formularioConteudos.value.Id == null"
        (OnClickButtonPost)="PostInformativo()" (OnClickButtonAuxUm)="limparFormulario()">
    </app-crud-buttons>
</form>
