<!--CARD-->
<mat-card style="min-width: none;">
    <mat-card-title>Perfil Acesso</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content>

        <form [formGroup]="formulario">
            <mat-form-field class="campo-descricao-perfil" appearance="outline">
                <mat-label>Nome do Perfil</mat-label>

                <input matInput type="text" [readonly]="formulario.value.Id > 0" maxlength="50" formControlName="DSPerfil">

                <button *ngIf="!formulario.value.Id" mat-button mat-icon-button matSuffix aria-label="search" (click)="procurarPerfilAcesso()"><mat-icon>search</mat-icon>
          </button>

                <button *ngIf="formulario.value.Id" matSuffix mat-icon-button aria-label="Clear" (click)="removerPerfilAcesso()"> <mat-icon>close</mat-icon>
          </button>

                <mat-error *ngIf="formulario.controls.DSPerfil.hasError('required')">
                    A descrição do perfil é <strong>obrigatória</strong>
                </mat-error>

                <mat-error *ngIf="formulario.controls.DSPerfil.hasError('caracteresEspeciaisParcialValidator') && !formulario.controls.DSPerfil.hasError('required')">
                    O campo possui caracteres indevidos
                </mat-error>


            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Cliente</mat-label>

                <input readonly matInput type="text" formControlName="Cliente">

                <button *ngIf="formulario.value.idCliente" matSuffix mat-icon-button aria-label="Clear" (click)="removerCliente()"> <mat-icon>close</mat-icon>
          </button>

                <button *ngIf="!formulario.value.idCliente" matSuffix mat-icon-button aria-label="Clear" (click)="procurarCliente()"><mat-icon>search</mat-icon>
          </button>

            </mat-form-field>
        </form>

    </mat-card-content>

</mat-card>

<!-- TABELA -->
<mat-table [dataSource]="registros" multiTemplateDataRows class="mat-elevation-z2">

    <ng-container matColumnDef="dsFuncao">
        <th mat-header-cell *matHeaderCellDef> Componente </th>
        <td mat-cell *matCellDef="let permissao"> {{permissao.dsFuncao}} </td>
    </ng-container>

    <ng-container matColumnDef="Permissao">
        <th mat-header-cell *matHeaderCellDef> Permissão </th>
        <td mat-cell *matCellDef="let permissao; let i = dataIndex">

            <app-select-permissao [valor]="permissao.Permissao" [desabilitar]="validaPermissao()" (onChange)="alterarPermissao($event, permissao.cdFuncao, i)">
            </app-select-permissao>

        </td>
    </ng-container>

    <ng-container matColumnDef="Parametro">
        <th mat-header-cell *matHeaderCellDef> Ações </th>
        <td mat-cell *matCellDef="let permissao">

            <button *ngIf="permissao.ListaParametroPermissaoPerfilAcesso.length > 0" mat-button color="primary" style="width: 100px;" (click)="expandir = expandir === permissao ? null : permissao">
                  {{expandir === permissao ? 'Ocultar' : 'Parâmetros'}}
        </button>

        </td>
    </ng-container>

    <ng-container matColumnDef="parametros">

        <td mat-cell *matCellDef="let permissao; let i = dataIndex" [attr.colspan]="colunas.length">

            <div [@detailExpand]="permissao == expandir ? 'expanded' : 'collapsed'">

                <div *ngFor="let linha of permissao.ListaParametroPermissaoPerfilAcesso" class="parametro">
                    <p class="parametro-descricao">{{linha.Descricao}}</p>

                    <mat-checkbox (change)="linha.Valor == '1' ? linha.Valor = '0' : linha.Valor = '1';
                                    alterarParametro(permissao, linha, i);" [checked]="linha.Valor == '0' ? false : true" color="primary" [disabled]="!validaPermissao()" style="padding-right: 30px;">
                    </mat-checkbox>
                </div>

            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="colunas"></tr>
    <tr mat-row *matRowDef="let permissao; columns: colunas;" class="tabela-linha" [class.example-expanded-row]="expandir === permissao">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['parametros']" class="linha-parametros">
    </tr>
</mat-table>

<!-- BOTÕES -->
<app-crud-buttons [showPost]="formulario.value.Id == 0 && validaPermissao('btnGravar')" [showPut]="formulario.value.Id != 0 && validaPermissao('btnGravar')" [showDelete]="validaPermissao('btnExcluir')" [labelBotaoPost]="'Adicionar'" [labelBotaoPut]="'Alterar'"
    [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="formulario.status == 'INVALID'" [disablePut]="formulario.status == 'INVALID'" [disableDelete]="formulario.value.Id == 0" (OnClickButtonAuxUm)="limparFormulario()" (OnClickButtonPost)="post()" (OnClickButtonPut)="post()"
    (OnClickButtonDelete)="delete()"></app-crud-buttons>