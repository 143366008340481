import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegurancaComponent } from './seguranca.component';
import { RouterModule } from '@angular/router';

//Material Imports
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
//Components Imports
import { PerfilAcessoComponent } from './perfil-acesso/perfil-acesso.component';
import { SegurancaRoutingModule } from './seguranca-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectPermissaoComponent } from './perfil-acesso/select-permissao/select-permissao.component';
import {MatDividerModule} from '@angular/material/divider';
import { SharedModule } from 'src/app/shared/shared.module';

const materialModules = [
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatInputModule,
  MatCheckboxModule,
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatSlideToggleModule,
  MatCardModule,
  MatStepperModule,
  MatListModule,
  MatDividerModule,
  FormsModule,
  ReactiveFormsModule
]

const internalComponents = [
  SegurancaComponent,
  PerfilAcessoComponent,
]

@NgModule({
  imports: [
    ...materialModules,
    RouterModule,
    CommonModule,
    SegurancaRoutingModule,
    SharedModule
  ],
  declarations: [
    ...internalComponents,
    SelectPermissaoComponent,
  ]
})
export class SegurancaModule { }
