<form [formGroup]="formAuditoria">
    <mat-card>
        <div class="row">
            <div class="col-sm-3 col-12">
                <mat-form-field class="formField-Font" appearance="outline" style="width: 100%; margin-top: 5%;">
                    <mat-label>Data de cadastro</mat-label>
                    <input matInput formControlName="DataCadastro" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3 col-12">
                <mat-form-field class="formField-Font" appearance="outline" style="width: 100%; margin-top: 5%;">
                    <mat-label>Usuario de cadastro</mat-label>
                    <input matInput formControlName="UsuarioCadastro" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3 col-12">
                <mat-form-field class="formField-Font" appearance="outline" style="width: 100%; margin-top: 5%;">
                    <mat-label>Data de Modificação</mat-label>
                    <input matInput formControlName="DataAlteracao" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3 col-12">
                <mat-form-field class="formField-Font" appearance="outline" style="width: 100%; margin-top: 5%;">
                    <mat-label>Usuário da modificação</mat-label>
                    <input matInput formControlName="UsuarioAlteracao" readonly>
                </mat-form-field>
            </div>
        </div>
    </mat-card>
</form>