<div style="margin-top: 12px;" class="row button-row">
    <button mat-raised-button class="format-Button" *ngIf="idClienteUsuario == 0 || idClienteUsuario == null" (click)="navigateCadastroClientes()">
      <img class="formatImg" src="./assets/principal/CadastroCliente.png">
      <div class="row justify-content-center" style="margin-bottom: 5px;">Cadastro de Clientes</div>
    </button>
    <br>
    <button mat-raised-button class="format-Button" *ngIf="idClienteUsuario == 0 || idClienteUsuario == null" (click)="navigateCadastroCategorias()">
      <img class="formatImg" src="./assets/principal/tagcategoria.png">
      <div class="row justify-content-center" style="margin-bottom: 5px;">Cadastro de Categorias</div>
    </button>
    <br>
    <button mat-raised-button class="format-Button" (click)="navigateConsultaProdutos()">
      <img class="formatImg" src="./assets/principal/iconproduto.png">
      <div class="row justify-content-center" style="margin-bottom: 5px;">Consulta de Produtos</div>
    </button>
    <br>
    <button mat-raised-button class="format-Button" *ngIf="idClienteUsuario == 0 || idClienteUsuario == null" (click)="navigateRevisaoProdutos()">
      <img class="formatImg" src="./assets/principal/iconrevisao.png">
      <div class="row justify-content-center" style="margin-bottom: 5px;">Revisão de Produtos</div>
    </button>
</div>
<div class="row">
    <app-graficos></app-graficos>
</div>
<!-- <mat-toolbar class="page-header" role="heading">
    <h1>Dashboard</h1>
    <button mat-flat-button color="warn">Latest Reports</button>
  </mat-toolbar>

  <div class="charts-wrapper">
    <div class="chart">
      <app-visits-chart [visitsChartData]="visitsChartData$ | async"></app-visits-chart>
    </div>
    <div class="chart">
      <app-performance-chart [performanceChartData]="performanceChartData$ | async"></app-performance-chart>
    </div>
    <div class="chart">
      <app-server-chart [serverChartData]="serverChartData$ | async"></app-server-chart>
    </div>
    <div class="chart">
      <app-revenue-chart [revenueCharData]="revenueChartData$ | async"></app-revenue-chart>
    </div>
  </div>

  <app-daily-line-chart [dailyLineChartData]="dailyLineChartData$ | async"></app-daily-line-chart>

  <app-project-stat-chart [projectsStatsData]="projectsStatsData$ | async"></app-project-stat-chart>

  <app-support-requests [supportRequestData]="supportRequestData$ | async"></app-support-requests>

  <app-footer></app-footer> -->