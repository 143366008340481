import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.hmr';

export class FormValidations {
  //NÃO PERMITE NENHUM CARACTER ESPECIAL
  static caracteresEspeciaisValidator(control: FormControl) {
    let valor = control.value;
    let regExp = /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/;
    return regExp.test(valor) ? null : { caracteresEspeciaisValidator: true };
  }

  //PERMITE SOMENTE _ . - COMO CARACTERES ESPECIAIS
  static caracteresEspeciaisParcialValidator(control: FormControl) {
    let valor = control.value;
    let regExp = /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ |_|.|-]+$/;
    return regExp.test(valor)
      ? null
      : { caracteresEspeciaisParcialValidator: true };
  }

  static somenteLetrasValidator(control: FormControl) {
    let valor = control.value;
    let regExp = /^[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/;
    return regExp.test(valor) ? null : { caracteresEspeciaisValidator: true };
  }

  static cepValidator(control: FormControl) {
    let cep = control.value;
    let regExp = /^\d{5}-?\d{3}$/;
    return regExp.test(cep) ? null : { cepValidator: true };
  }

  static cnpjFormatValidator(control: FormControl) {
    let cnpj = control.value;
    let regExp = /^\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2}$/;
    return regExp.test(cnpj) ? null : { cnpjFormatValidator: true };
  }

  static cnpjValidator(control: FormControl) {
    let cnpj = control.value;

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return { cnpjValidator: true };

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;

      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return { cnpjValidator: true };

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return { cnpjValidator: true };

    return null;
  }

  static cnpjJaInformadoValidator(listaCnpj: any[] = []) {
    const validator = (control: FormControl) => {
      return listaCnpj.includes(
        control.value.replace(
          /(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/,
          '$1$2$3$4$5'
        )
      )
        ? { cnpjJaInformadoValidator: true }
        : null;
    };

    return validator;
  }

  /**
   *
   * @param idEmpresa Deve receber o Id da empresa para que a verificação de CNPJ ignore esta empresa, caso a empresa não possua um Id válido, irá receber o Id 0 e a pesquisa será feita a partir de todos os CNPJ já cadastrados
   * @param httpClient Deve receber a classe responsável pelas requisições http, uma vez que a classe de forms validations não é instanciada e têm seus métodos estático, portanto não é possível o uso do httpclient por injeção de dependência
   * @returns
   */
  static cnpjJaCadastradoValidator(
    idEmpresa: number = 0,
    httpClient: HttpClient
  ) {
    const validator = (control: FormControl) => {
      let httpParams = new HttpParams({
        fromObject: {
          cnpj: control.value.replace(
            /(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/,
            '$1$2$3$4$5'
          ),
          idEmpresa: String(idEmpresa),
        },
      });

      return httpClient
        .get<any[]>(`${environment.API}Cliente/VerificaCNPJRepetido`, {
          params: httpParams,
        })
        .pipe(
          map((response) =>
            response ? { cnpjJaCadastradoValidator: true } : null
          )
        );
    };

    return validator;
  }

  static somenteLetraValidator(control: FormControl) {
    let valor = control.value;
    let regExp = /^\d*$/;
    return regExp.test(valor) ? { naoPermitirEspeciais: true } : null;
  }

  static telefoneValidator(control: FormControl) {
    let telefone = control.value;

    if (telefone && telefone != '') {
      let regExp = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/;
      return regExp.test(telefone) ? null : { telefoneValidator: true };
    }
    return null;
  }

  static somenteNumero(control: FormControl) {
    let valor = control.value;

    if (valor && valor != '') {
      let regExp = /^[A-Za-zzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]*$/;
      return regExp.test(valor) ? { somenteNumero: true } : null;
    }

    return null;
  }

  static formatarCampo(valor, formatacao) {
    if (valor && valor != '') {
      switch (formatacao) {
        case 'cep':
          return valor.replace(/(\d{5})-?(\d{3})/, '$1-$2');
        case 'cpf':
          return valor.replace(
            /(\d{3}).?(\d{3}).?(\d{3})-?(\d{2})/,
            '$1.$2.$3-$4'
          );
        case 'cnpj':
          return valor.replace(
            /(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})/,
            '$1.$2.$3/$4-$5'
          );
        case 'data':
          return valor.replace(/(\d{2})\/?(\d{2})/, '$1/$2');
        case 'dataDMA':
          return valor.replace(/(\d{2})\/?(\d{2})\/?(\d{4})/, '$1/$2/$3');
        case 'dataAMDtoDMA':
          return valor.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');
        case 'dataDMAtoAMD':
          return valor.replace(/(\d{2})-?\/?(\d{2})-?\/?(\d{4})/, '$3-$2-$1');
        case 'telefone':
          valor = valor.replace(/\D/g, '');
          valor = valor.replace(/^(\d\d)(\d)/g, '($1)$2');

          if (valor.length < 13) {
            valor = valor.replace(/(\d{4})(\d)/, '$1-$2');
          } else {
            valor = valor.replace(/(\d{5})(\d)/, '$1-$2');
          }

          return valor;
      }
    }
  }

  static userChanges(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      {
        const formGroup = control as FormGroup
        const Id = formGroup.get('Id')?.value
        const Nome = formGroup.get('Nome')?.value
        const Login = formGroup.get('Login')?.value
        const Senha = formGroup.get('Senha')?.value
        const Email = formGroup.get('Email')?.value

        if(Id == 0){
          if(Nome == null || Login == null || Senha == null || Email == null){
            return { formInvalido: true }
          }
          else{
            return null
          }
        }
        else{
          return null
        }
      }
    };
  }
}
