import { Produto } from './../../../../consts/produtos';
import { InformativoCategoria, ListaPalavrasChaves, Link } from './../../../../consts/categoria';
import { Categoria, ListaCSTICMS, ListasCSTPisCofinsFederal, ListaSituacaoPISCOFINS } from 'src/app/consts/categoria';
import { firstValueFrom } from 'rxjs';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { CategoriasService } from 'src/app/services/categorias.service';
import { ListaUF } from 'src/app/consts/ListasSelects';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { VideoComponent } from 'src/app/shared/custom-components/video/video.component';

@Component({
  selector: 'app-detalhe-produto',
  templateUrl: './detalhe-produto.component.html',
  styleUrls: ['./detalhe-produto.component.css']
})
export class DetalheProdutoComponent implements OnInit {

  dadosProduto
  FormProduto: FormGroup;
  palavrasChaves: ListaPalavrasChaves
  InformativoCategoria: InformativoCategoria
  InformativoVideos: Array<Link>
  InformativoArquivos: Array<Link>

  ListaSituacaoIcms = ListaCSTICMS.SituacaoICMS
  ListasCSTPisCofinsFederal = ListasCSTPisCofinsFederal;
  ListaCSTICMS = ListaCSTICMS;
  ListaSituacaoPISCOFINS = ListaSituacaoPISCOFINS;
  ListaUf = ListaUF;
  readonly separatorKeysCodes = [ENTER, COMMA] as const
  addOnBlur = true

  constructor(@Inject(MAT_DIALOG_DATA) data,
              private formBuilder: FormBuilder,
              private categoriaService: CategoriasService,
              public dialog: MatDialog) {
    this.dadosProduto = data.Produto

    firstValueFrom(this.categoriaService.get(data.Produto.CategoriaId))
    .then((response: Categoria) => {

      let Estados = response.ListaTributacaoEstadual.map((element) =>
      this.buildTributacaoEstadual(element)
    );


      this.FormProduto = this.formBuilder.group({
        Produto: data.Produto.Produto,
        Codigo: data.Produto.Codigo,
        Id: [response.Id],
        Nome: [data.Produto.Categoria],
        ListaPalavraChave: [response.ListaPalavraChave],
        Ncm: [response.Ncm],
        Cest: [response.Cest],
        NaturezaReceita: [response.NaturezaReceita],
        AliquotaPisCumulativoEntrada: [response.AliquotaPisCumulativoEntrada],
        AliquotaPisNaoCumulativoEntrada: [response.AliquotaPisNaoCumulativoEntrada],
        AliquotaCofinsCumulativoEntrada: [response.AliquotaCofinsCumulativoEntrada],
        AliquotaCofinsNaoCumulativoEntrada: [response.AliquotaCofinsNaoCumulativoEntrada],
        AliquotaPisCumulativoSaida: [response.AliquotaPisCumulativoSaida],
        AliquotaPisNaoCumulativoSaida: [response.AliquotaPisNaoCumulativoSaida],
        AliquotaCofinsCumulativoSaida: [response.AliquotaCofinsCumulativoSaida],
        AliquotaCofinsNaoCumulativoSaida: [response.AliquotaCofinsNaoCumulativoSaida],
        CstPisCumulativoEntrada: [response.CstPisCumulativoEntrada],
        CstPisNaoCumulativoEntrada: [response.CstPisNaoCumulativoEntrada],
        CstCofinsCumulativoEntrada: [response.CstCofinsCumulativoEntrada],
        CstCofinsNaoCumulativoEntrada: [response.CstCofinsNaoCumulativoEntrada],
        CstPisCumulativoSaida: [response.CstPisCumulativoSaida],
        CstPisNaoCumulativoSaida: [response.CstPisNaoCumulativoSaida],
        CstCofinsCumulativoSaida: [response.CstCofinsCumulativoSaida],
        CstCofinsNaoCumulativoSaida: [response.CstCofinsNaoCumulativoSaida],
        SituacaoPisCofinsEntrada: [response.SituacaoPisCofinsEntrada],
        SituacaoPisCofinsSaida: [response.SituacaoPisCofinsSaida],
        SituacaoCsosnEntrada: [response.SituacaoCsosnEntrada],
        SituacaoCsosnSaida: [response.SituacaoCsosnSaida],
        CstIcmsEntrada: [response.CstIcmsEntrada],
        CstIcmsSaida: [response.CstIcmsSaida],
        AliquotaIcmsEntrada: [response.AliquotaIcmsEntrada],
        AliquotaIcmsSaida: [response.AliquotaIcmsSaida],
        AliquotaIcmsStEntrada: [response.AliquotaIcmsStEntrada],
        AliquotaIcmsStSaida: [response.AliquotaIcmsStSaida],
        MvaInternoEntrada: [response.MvaInternoEntrada],
        MvaInternoSaida: [response.MvaInternoSaida],
        MvaExterno12Entrada: [response.MvaExterno12Entrada],
        MvaExterno12Saida: [response.MvaExterno12Saida],
        MvaExterno4Entrada: [response.MvaExterno4Entrada],
        MvaExterno4Saida: [response.MvaExterno4Saida],
        SituacaoIcmsEntrada: [response.SituacaoIcmsEntrada],
        SituacaoIcmsSaida: [response.SituacaoIcmsSaida],
        AliquotaCalculoDesoneracao: [response.AliquotaCalculoDesoneracao],
        CsosnEntrada: [response.CsosnEntrada],
        CsosnSaida: [response.CsosnSaida],
        Fecp: [response.Fecp],
        MotivoDesoneracao: [response.MotivoDesoneracao],
        ReducaoBaseCalculo: [response.ReducaoBaseCalculo],
        TipoDesoneracao: [response.TipoDesoneracao],
        DataAlteracao: [response.DataAlteracao],
        ListaTributacaoEstadual: this.formBuilder.array(Estados),
      })
      this.requisicaoInformativoCategoria(data)

      this.EstadualControls.forEach((a) => {
        a.patchValue({ Uf: this.ListaUf.find((b) => b.idUF == a.value.Uf) });
      });
      this.palavrasChaves = response.ListaPalavraChave
      this.formataDados()
    })

  }

  ngOnInit() {

  }

  get EstadualControls() {
    return (<FormArray>this.FormProduto.get('ListaTributacaoEstadual'))
      .controls;
  }

  buildTributacaoEstadual(a): FormGroup {
    return this.formBuilder.group({
      Id: [a.Id],
      CategoriaId: [a.CategoriaId],
      Uf: [a.Uf],
      CstIcmsEntrada: [a.CstIcmsEntrada],
      CstIcmsSaida: [a.CstIcmsSaida],
      AliquotaIcmsEntrada: [a.AliquotaIcmsEntrada],
      AliquotaIcmsSaida: [a.AliquotaIcmsSaida],
      AliquotaIcmsStEntrada: [a.AliquotaIcmsStEntrada],
      AliquotaIcmsStSaida: [a.AliquotaIcmsStSaida],
      MvaInternoEntrada: [a.MvaInternoEntrada],
      MvaInternoSaida: [a.MvaInternoSaida],
      MvaExterno12Entrada: [a.MvaExterno12Entrada],
      MvaExterno12Saida: [a.MvaExterno12Saida],
      MvaExterno4Entrada: [a.MvaExterno4Entrada],
      MvaExterno4Saida: [a.MvaExterno4Saida],
      SituacaoIcmsEntrada: [a.SituacaoIcmsEntrada],
      SituacaoIcmsSaida: [a.SituacaoIcmsSaida],
      MvaExterno7Entrada: [a.MvaExterno7Entrada],
      MvaExterno7Saida: [a.MvaExterno7Saida],
      AliquotaCalculoDesoneracao: [a.AliquotaCalculoDesoneracao],
      CsosnEntrada: [a.CsosnEntrada],
      CsosnSaida: [a.CsosnSaida],
      Fecp: [a.Fecp],
      MotivoDesoneracao: [a.MotivoDesoneracao],
      ReducaoBaseCalculo: [a.ReducaoBaseCalculo],
      SituacaoCsosnEntrada: [a.SituacaoCsosnEntrada],
      SituacaoCsosnSaida: [a.SituacaoCsosnSaida],
      TipoDesoneracao: [a.TipoDesoneracao],
      DataAlteracao: [a.DataAlteracao],
    })
  }

  formataDados(){
    let situacaoPis = this.FormProduto.get('SituacaoPisCofinsEntrada').value
    let ValorPisCofins = ListaSituacaoPISCOFINS.filter(s => s.Id == situacaoPis)

    this.FormProduto.patchValue({
      SituacaoPisCofinsEntrada: ValorPisCofins[0].Nome
    })

    this.EstadualControls.forEach(e => {
      e.patchValue({
        SituacaoIcmsEntrada: this.ListaSituacaoIcms.filter(si => si.Id == e.value.SituacaoIcmsEntrada)[0].Nome,
        SituacaoCsosnEntrada: this.ListaCSTICMS.SituacaoCSOSN.filter(si => si.Id == e.value.SituacaoCsosnEntrada)[0].Nome,
        CsosnEntrada: e.value.CsosnEntrada + this.ListaCSTICMS.ListaCSOSN.filter(si => si.Id == e.value.CsosnEntrada)[0].Descricao,
      })

      if(e.value.MotivoDesoneracao != null && e.value.MotivoDesoneracao != 0){
        e.patchValue({
          MotivoDesoneracao: this.ListaCSTICMS.MotivoDesoneracao.filter(si => si.Id == e.value.MotivoDesoneracao)[0].Nome
        })
      }
    })
  }

  requisicaoInformativoCategoria(dataProduto){
    firstValueFrom(this.categoriaService.getInformativo(dataProduto.Produto.CategoriaId))
    .then((response: InformativoCategoria) => {
      if(response){
        this.InformativoCategoria = response

        this.InformativoVideos = this.InformativoCategoria.ListaLinks.filter((item: Link) => {
          return item.Tipo.includes('Video')
        })

        this.InformativoArquivos = this.InformativoCategoria.ListaLinks.filter((item: Link) => {
          return item.Tipo.includes('Arquivo')
        })

      }
    })
  }

  abrirVideo(video){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'custom-dialog-container'
    dialogConfig.data = {
      Link: video
    }

    this.dialog.open(VideoComponent,dialogConfig)
  }



}
