<form [formGroup]="formulario">

    <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Campo</mat-label>
        <mat-select (selectionChange)="MudaTipoCampo($event.value); EmitirValoresPesquisa()" formControlName="Coluna">
            <mat-option *ngFor="let item of ValoresSelect" [(value)]="item.Coluna">
                {{item.Campo}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <br>

    <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>Filtro</mat-label>
        <input [type]="TipoDado" formControlName="Filtro" matInput (input)="EmitirValoresPesquisa()" (keyup.enter)="EmitePesquisar()">
        <mat-error *ngIf="formulario.controls.Filtro.hasError('required')">O campo deve ser preencido</mat-error>
    </mat-form-field>

</form>