<mat-card>
    <mat-card-content class="row" style="padding-top: 10px;">

        <mat-form-field appearance="fill" 
                        class="col-md-3">

            <mat-label> Usuário de Cadastro </mat-label>

            <input type="text" 
                   [value]="UsuarioCadastro" 
                   readonly 
                   matInput>

        </mat-form-field>
        
        <mat-form-field appearance="fill" 
                        class="col-md-3">

            <mat-label> Data de Cadastro </mat-label>

            <input type="text" 
                   [value]="DataCadastro | date: 'dd/MM/yyyy hh:mm:ss'"
                   readonly 
                   matInput>

        </mat-form-field>

        <mat-form-field appearance="fill" 
                        class="col-md-3">

            <mat-label> Usuário de Alteração </mat-label>

            <input type="text" 
                   [value]="UsuarioAlteracao" 
                   readonly 
                   matInput>
                   
        </mat-form-field>
        
        <mat-form-field appearance="fill" 
                        class="col-md-3">

            <mat-label>Data de Alteração</mat-label>

            <input type="text" 
                   [value]="DataAlteracao | date: 'dd/MM/yyyy hh:mm:ss'" 
                   readonly 
                   matInput>

        </mat-form-field>    
      
    </mat-card-content>
  </mat-card>