<mat-card>
    <mat-card-title class="matCard-Font">Cadastro de NCM</mat-card-title>
</mat-card>
<mat-card>
    <mat-card-subtitle>Dados Ncm</mat-card-subtitle>
    <mat-card-content>
        <form [formGroup]="formularioNCM">
            <div class="row">
                <div class="col-sm-5 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Código</mat-label>
                        <input maxlength="8" formControlName="Codigo" matInput type="text">
                        <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarNcm()">
                          <mat-icon>search</mat-icon>
                          </button>
                    </mat-form-field>
                </div>
                <div class="col-sm-7 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Descrição</mat-label>
                        <input formControlName="Descricao" matInput type="text">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>



<app-crud-buttons [showPost]="formularioNCM.value.Id == 0" [showPut]="formularioNCM.value.Id != 0" [showDelete] [labelBotaoPost]="'Gravar'" [labelBotaoPut]="'Gravar'" [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="formularioNCM.status == 'INVALID'"
    [disablePut]="formularioNCM.status == 'INVALID'" [disableDelete]="formularioNCM.value.Id == '00000000-0000-0000-0000-000000000000'" (OnClickButtonPost)="post()" (OnClickButtonPut)="post()" (OnClickButtonAuxUm)="limpar()" (OnClickButtonDelete)="delete()">
</app-crud-buttons>
