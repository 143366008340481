import { FormCategorias } from './../../../form-validations/form-categorias';
import { SpinnerComponent } from './../../../shared/custom-components/spinner/spinner.component';
import { UsuarioService } from 'src/app/services/usuario.service';
import { AlterarProdutoComponent } from './alterar-produto/alterar-produto.component';
import { DetalheProdutoComponent } from './detalhe-produto/detalhe-produto.component';
import { ClienteService } from 'src/app/services/cliente.service';
import { ConsultaProdutoService } from './../../../services/consulta-produto.service';
import { RevisaoService } from './../../../services/revisao.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Produto, ListaProdutosClientes, ListaProdutos } from './../../../consts/produtos';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { createViewChild } from '@angular/compiler/src/core';
import { MatPaginator } from '@angular/material/paginator';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { firstValueFrom } from 'rxjs';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { Cliente } from 'src/app/consts/cliente';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-consulta-produtos',
  templateUrl: './consulta-produtos.component.html',
  styleUrls: ['./consulta-produtos.component.css'],
})
export class ConsultaProdutosComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  filtrosExpanded = true;
  filtrosPesquisa: FormGroup
  dataSourceProdutos: MatTableDataSource<ListaProdutos>;
  produtosColumns = ['Categoria', 'Produto', 'Codigo', 'Interno', 'NomeStatus', 'Cliente', 'Detalhe', 'Reclassificar'];
  selectStatus = [{Id: null, Nome: null}, {Id: 0, Nome: 'Revisão pendente'},{Id: 2, Nome:'Atualização Pendente'}, {Id: 1, Nome:'Revisado'}, {Id: 3, Nome:'Bloqueado'}]

  //MatChips
  //mat chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true
  readonly separatorKeysCodes = [ENTER, COMMA] as const
  palavrasChaves: string[] = []
  usuarioLogado;
  public idClienteUsuario

  constructor(public formBuilder: FormBuilder,
    public consultaService: ConsultaProdutoService,
    public clienteService: ClienteService,
    public dialog: MatDialog,
    public usuarioService: UsuarioService) {

    this.idClienteUsuario = JSON.parse(localStorage.getItem('usuarioCompleto'))

    this.filtrosPesquisa = this.formBuilder.group({
      Produtos: [null],
      Cliente: [null],
      idCliente: [null],
      DataInicio: [null],
      DataFim: [null],
      Categoria: [null],
      idCategoria: ['00000000-0000-0000-0000-000000000000'],
      Ncm: [null],
      Codigo: [''],
      Status: [null]
    },
    {validators: [FormCategorias.consultaProdutoActivate()]})

  }

  ngOnInit() {
      if(this.idClienteUsuario != 0){
        this.filtrosPesquisa.patchValue({
          idCliente: this.idClienteUsuario
        })
      }
      this.filtroValueChanges()
  }

  filtroValueChanges(){
    this.filtrosPesquisa.valueChanges.forEach(a => {
      this.filtrosPesquisa.updateValueAndValidity()
    })
  }

  async getUsuarioLogado(){
    await firstValueFrom(this.usuarioService.getLoggedUser())
    .then(response => {
      this.usuarioLogado = response[0]
      if(this.usuarioLogado.idCliente != 0){
        this.filtrosPesquisa.patchValue({
          idCliente: this.usuarioLogado.idCliente
        })
      }
    })
  }

  async getTabela(){


    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})
    firstValueFrom(this.consultaService.getConsultaProdutos(this.formatObjetoPesquisar(this.filtrosPesquisa.value)))
    .then((response): any => {
      this.gerarDataSource(response)
      dialogRef.close()
    })
  }

  formatObjetoPesquisar(filtro){
    let objeto = {
      idCliente: this.filtrosPesquisa.value.idCliente,
      Produtos: this.palavrasChaves,
      idCategoria: this.filtrosPesquisa.value.idCategoria,
      Codigo: this.filtrosPesquisa.value.Codigo,
      Ncm: this.filtrosPesquisa.value.Ncm,
      DataInicio: this.filtrosPesquisa.value.DataInicio,
      DataFim: this.filtrosPesquisa.value.DataFim,
      Status: this.filtrosPesquisa.value.Status
    }
    return objeto
  }

  pesquisarCliente() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Clientes',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((clienteEscolhido) => {
        if (clienteEscolhido) {
          firstValueFrom(this.clienteService.get(clienteEscolhido.Id)).then(
            (response: Cliente) => {
              this.filtrosPesquisa.patchValue({
                idCliente: response[0].Id,
                Cliente: response[0].Nome
              })
            }
          );
        }
      });
  }

  apagarCampoCliente(){
    this.filtrosPesquisa.patchValue({
      idCliente: null,
      Cliente: null
    })
  }

  pesquisarCategorias() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Categorias',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((categoriaEscolhida) => {
        if (categoriaEscolhida) {
         this.filtrosPesquisa.patchValue({
          idCategoria: categoriaEscolhida.Id,
          Categoria: categoriaEscolhida.Nome
         })
        }
      });
  }

  abrirDetalhe(produto){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      Produto: produto
    }

    dialogConfig.width = '90vw';
    dialogConfig.height = '90vh';

    this.dialog.open(DetalheProdutoComponent, dialogConfig)
  }

  abrirReclassificar(produto){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      Produto: produto
    }

    dialogConfig.width = '90vw';

    this.dialog.open(AlterarProdutoComponent, dialogConfig)
  }

  apagarCampoCategoria(){
    this.filtrosPesquisa.patchValue({
      idCategoria: '00000000-0000-0000-0000-000000000000',
      Categoria: null
    })
  }

  gerarDataSource(produtos): void{
    this.dataSourceProdutos = new MatTableDataSource(produtos)
    this.dataSourceProdutos.paginator = this.paginator
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value
    if(this.palavrasChaves.length < 5){
      if((value || '').trim()){
        this.palavrasChaves.push(value)

        this.filtrosPesquisa.patchValue({
          Produtos: this.palavrasChaves
        })
      }
    }

    if(input){
      input.value = ''
    }
  }

  remove(palavra: string): void {
    const index = this.palavrasChaves.indexOf(palavra);
    if(index >= 0){
      this.palavrasChaves.splice(index, 1);
      if(this.palavrasChaves.length == 0){
        this.filtrosPesquisa.patchValue({
          Produtos: null
        })
      }
    }
  }

  limparDataInicio(){
    this.filtrosPesquisa.patchValue({
      DataInicio: null
    })
  }

  limparDataFim(){
    this.filtrosPesquisa.patchValue({
      DataFim: null
    })
  }

  limparFiltro(){
    this.palavrasChaves = []
    if(this.idClienteUsuario == 0){
      this.filtrosPesquisa.patchValue({
        Produto: null,
        Cliente: null,
        idCliente: null,
        DataInicio: null,
        DataFim: null,
        Categoria: null,
        idCategoria: '00000000-0000-0000-0000-000000000000',
        Ncm: null,
        Codigo: '',
        Status: null
      })
    }
    else{
      this.filtrosPesquisa.patchValue({
        Produto: null,
        DataInicio: null,
        DataFim: null,
        Categoria: null,
        idCategoria: '00000000-0000-0000-0000-000000000000',
        Ncm: null,
        Codigo: '',
        Status: null
      })
    }

    this.dataSourceProdutos = new MatTableDataSource()
    this.dataSourceProdutos.paginator = this.paginator
  }
}
