import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Regex } from '../regex/regex';

@Pipe({
  name: 'cep'
})

@Injectable({
  providedIn: 'root'
})

export class CepPipe implements PipeTransform {

  transform(value: any): string {
    return Regex.cep(value)
  }

}
