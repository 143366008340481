import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()

export class AuthInterceptor  {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const userInfo = JSON.parse(localStorage.getItem('userInfo'))

        if (userInfo) {
            const clone = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + userInfo.token)
            });
            return next.handle(clone)
        }
        else {
            return next.handle(req);
        }
    }
}
