import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternosService {

  public httpClient


  constructor(private httpBackend: HttpBackend) {

    this.httpClient = new HttpClient(this.httpBackend)

  }

  getViaCep(cep: number) {
    return this.httpClient.get(`https://viacep.com.br/ws/${cep}/json/`)
  }

  getIBGE(id: string) {
    return this.httpClient.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id}`)
  }

  async buscaEndereco(cep) {

    let respostaViaCep
    let respostaIbge

    await firstValueFrom(this.getViaCep(cep))
      .then(resposta => {
        respostaViaCep = resposta
      }
      )
      .catch(
        err => console.log(err)
      )

    await firstValueFrom(this.getIBGE(respostaViaCep.ibge))
      .then(resposta => {
        respostaIbge = resposta
      }
      )
      .catch(
        err => console.log(err)
      )

    let endereco = {
      respostaIbge: respostaIbge,
      respostaViaCep: respostaViaCep
    }

    return endereco
  }

}
