import { PagesGuard } from './../pages-guard.guard';
import { AlterarProdutoComponent } from './consulta-produtos/alterar-produto/alterar-produto.component';
import { DetalheProdutoComponent } from './consulta-produtos/detalhe-produto/detalhe-produto.component';
import { DetalharSugestoesComponent } from './revisao/tabela-revisao/tabela-sugestao-cliente/detalhar-sugestoes/detalhar-sugestoes.component';
import { TabelaSugestaoClienteComponent } from './revisao/tabela-revisao/tabela-sugestao-cliente/tabela-sugestao-cliente.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DetalharCategoriaComponent } from './revisao/tabela-revisao/tabela-cliente-revisao/detalhar-categoria/detalhar-categoria.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { TabelaClienteRevisaoComponent } from './revisao/tabela-revisao/tabela-cliente-revisao/tabela-cliente-revisao.component';
import { TabelaRevisaoComponent } from './revisao/tabela-revisao/tabela-revisao.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { RevisaoComponent } from './revisao/revisao.component';
import { CalculoCustoComponent } from './calculo-custo/calculo-custo.component';
import { ConsultaProdutosComponent } from './consulta-produtos/consulta-produtos.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OperacoesComponent } from './operacoes.component';
import { SegurancaRoutingModule } from './operacoes-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import * as internal from 'events';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_DATE_FORMAT } from 'src/app/consts';
import { MomentPipe } from 'src/app/pipes/Moment.pipe';

const materialModules = [
  MatExpansionModule,
  MatCardModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatIconModule,
  MatButtonModule,
  MatDatepickerModule,
  MatChipsModule,
  MatPaginatorModule,
  MatDialogModule,
  MatCheckboxModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule
];

const internalComponents = [
  ConsultaProdutosComponent,
  CalculoCustoComponent,
  RevisaoComponent,
  TabelaRevisaoComponent,
  TabelaClienteRevisaoComponent,
  DetalharCategoriaComponent,
  TabelaSugestaoClienteComponent,
  DetalharSugestoesComponent,
  DetalheProdutoComponent,
  AlterarProdutoComponent
];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SegurancaRoutingModule,
    SharedModule,
    ...materialModules,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [OperacoesComponent, ...internalComponents],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMAT,
    },
    MomentPipe,
    PagesGuard
  ],
})
export class OperacoesModule {}
