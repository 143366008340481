<form [formGroup]="FormAlterarProduto">
    <div class="row">
        <div class="col-sm-6 col-12">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Produto</mat-label>
                <input formControlName="Produto" matInput type="text" readonly>
            </mat-form-field>
        </div>
        <div class="col-sm-6 col-12">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Cliente</mat-label>
                <input formControlName="Cliente" matInput type="text" readonly>
            </mat-form-field>
        </div>

    </div>

    <div class="row justify-content-center">
        <div class="col-sm-6 col-12">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Categoria anterior</mat-label>
                <input formControlName="CategoriaAnterior" matInput type="text" readonly>
            </mat-form-field>
        </div>
        <div class="col-sm-6 col-12">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Nova Categoria</mat-label>
                <input formControlName="CategoriaNova" matInput type="text" readonly>
                <button *ngIf="FormAlterarProduto.value.Categoria == null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCategorias()">
          <mat-icon>search</mat-icon>
        </button>
                <button *ngIf="FormAlterarProduto.value.Categoria != null" mat-button mat-icon-button matSuffix aria-label="Clear">
          <mat-icon>close</mat-icon>
        </button>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-12">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Status</mat-label>
                <mat-select formControlName="Status">
                    <mat-option *ngFor="let item of selectStatus" [value]="item.Id">
                        {{item.Status}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row justify-content-end"><button mat-raised-button [mat-dialog-close]="closeDialog" style="width: 15%;" color="primary" (click)="alterar(FormAlterarProduto.value)">Gravar</button></div>
</form>
