<button class="user-button" mat-button [matMenuTriggerFor]="userMenu">

    <mat-icon class="user-button__icon">person</mat-icon>&nbsp;
    <span class="user-button__name">{{usuario}}</span>
</button>



<mat-menu #userMenu="matMenu" xPosition="before">
    <!-- <div class="user-menu-title">
        <h4 class="user-menu-title__name">{{user.name}} {{user.lastName}}</h4>
    </div> -->
    <!-- <button mat-menu-item class="user-menu__item-title">
    <mat-icon class="user-menu-icon">person</mat-icon>Perfil
  </button>
    <button mat-menu-item class="user-menu__item-title">
    <mat-icon class="user-menu-icon">description</mat-icon>Tarefas
  </button>
    <button mat-menu-item class="user-menu__item-title">
    <mat-icon class="user-menu-icon">email</mat-icon>Mensagens
  </button> -->

    <button mat-menu-item (click)="navigateAlterarSenha()">Alterar Senha</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="signOutEmit()">Sair</button>

</mat-menu>
