import { Sistema } from './../../../consts/sistema';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MensagemResposta } from './../../../consts/mensagem';
import { firstValueFrom } from 'rxjs';
import { SistemaService } from './../../../services/sistema.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { TipoIntegracao } from 'src/app/consts/ListasSelects';
import { ErrorPageComponent } from 'src/app/shared/custom-components/error-page/error-page.component';
import { SearchPageComponent } from 'src/app/shared/custom-components/search-page/search-page.component';
import { SpinnerComponent } from 'src/app/shared/custom-components/spinner/spinner.component';

@Component({
  selector: 'app-sistemas',
  templateUrl: './sistemas.component.html',
  styleUrls: ['./sistemas.component.css'],
})
export class SistemasComponent implements OnInit {
  public tipoDeIntegracao = TipoIntegracao;
  formularioSistema: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public sistemaService: SistemaService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.formularioSistema = this.formBuilder.group({
      Id: [0],
      Nome: ['', Validators.required],
      TipoIntegracao: ['', Validators.required],
    });
  }

  post() {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    firstValueFrom(this.sistemaService.post(this.formularioSistema.value)).then(
      (response: MensagemResposta) => {
        if (response) {
          this.abrirPaginaErro('sistema', response);
          if (response[0].Codigo == 'CRM00001') {
            this.limpar();

          }
          dialogRef.close();
        }
      }
    );
  }

  get() {
    firstValueFrom(
      this.sistemaService.get(this.formularioSistema.value.Id)
    ).then((response: any) => {
      this.formularioSistema.patchValue({
        Id: response.Id,
        Nome: response.Nome,
        TipoIntegracao: response.TipoIntegracao,
      });
    });
  }

  delete() {
    const dialogRef = this.dialog.open(SpinnerComponent, {panelClass: 'spinner'})

    firstValueFrom(
      this.sistemaService.delete(this.formularioSistema.value.Id)
    ).then((response: MensagemResposta) => {
      this.abrirPaginaErro('sistema', response);
      if (response[0].Codigo == 'CRM00003') {
        this.limpar();
      }
      dialogRef.close()
    });
  }

  abrirPaginaErro(tipo, mensagem) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tipo: tipo,
      mensagem: mensagem,
    };

    this.dialog.open(ErrorPageComponent, dialogConfig);
  }

  limpar() {
    this.formularioSistema.patchValue({
      Id: 0,
      Nome: null,
      TipoIntegracao: null,
    });
  }

  procurarSistema() {
    this.limpar();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      tabela: 'Sistemas',
    };

    this.dialog
      .open(SearchPageComponent, dialogConfig)
      .afterClosed()
      .subscribe((sistemaEscolhido) => {
        if (sistemaEscolhido) {
          firstValueFrom(this.sistemaService.get(sistemaEscolhido.Id))
          .then((response: Sistema) => {
            this.formularioSistema.patchValue({
              Id: response[0].Id,
              Nome: response[0].Nome,
              TipoIntegracao: response[0].TipoIntegracao
            })
          })
        }
      });
  }
}
