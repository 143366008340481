import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.hmr';

@Injectable({
  providedIn: 'root'
})
export class PerfilAcessoService {

  API = environment.API

  constructor(private httpClient: HttpClient,
  ) { }
  getTabelaPerfilAcesso() {
    return this.httpClient.get(`${this.API}PerfilAcesso`)
  }

  get(id) {
    return this.httpClient.get(`${this.API}PerfilAcesso/${id}`)
  }

  post(corpo) {
    return this.httpClient.post(`${this.API}PerfilAcesso/`, corpo)
  }

  delete(id) {
    return this.httpClient.delete(`${this.API}PerfilAcesso/${id}`)
  }
}
