<table *ngIf="ValoresTabela.length > 0; else semResultado" mat-table [dataSource]="ValoresTabela">  

    <ng-container *ngFor="let coluna of ColunasTabela; let colIndex = index" matColumnDef="{{coluna.valor}}">
        <th [ngClass]="{'pequeno': coluna.tamanho == 'Pequeno', 'medio': coluna.tamanho == 'Medio', 'grande': coluna.tamanho == 'Grande'}" mat-header-cell *matHeaderCellDef> {{coluna.visualizacao}} </th>
        <td [ngClass]="{'pequeno': coluna.tamanho == 'Pequeno', 'medio': coluna.tamanho == 'Medio', 'grande': coluna.tamanho == 'Grande'}" mat-cell *matCellDef="let element" (click)="SelecionarLinha(element)"> {{element[coluna.valor]}} </td>
    </ng-container>  
    
    <tr mat-header-row *matHeaderRowDef="colunasExibidas"></tr>
    <tr class="mat-row" mat-row *matRowDef="let row; columns: colunasExibidas; let i = index"></tr>

</table>

<ng-template #semResultado>
    Sua pesquisa não retornou resultados
</ng-template>