import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment.hmr';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  API = environment.API

  constructor(private http: HttpClient) { }

  public getCamposPesquisa(componente: string){
    return this.http.get(`${this.API}TabelaPesquisa/${componente}`);
  }

  public search(formulario): Observable<any[]>{

    let httpParams = new HttpParams({
        fromObject: {
          tabela: formulario.tabela,
          coluna: formulario.coluna,
          valor: formulario.valor,
          condicaoPadrao: formulario.condicaoPadrao
        }
      })    

    return this.http.get<any[]>(`${this.API}TabelaPesquisa/GetPesquisa`, {params: httpParams})
      //.pipe(delay(5000));
  }

}
