<mat-dialog-content class="mat-typography">

  <form [formGroup]="formulario">

    <!-- PRIMEIRA LINHA -->
    <mat-form-field appearance="outline" 
                    style="width: 100%">

        <mat-label> Razão Social </mat-label>

        <input type="text" 
               formControlName="RazaoSocial" 
               maxlength="50"
               matInput>

        <mat-error *ngIf="formulario.controls.RazaoSocial.hasError('required')">
          A razão social é <strong>obrigatória</strong>
        </mat-error>

        <mat-error *ngIf="!formulario.controls.RazaoSocial.hasError('required') && formulario.controls.RazaoSocial.hasError('caracteresEspeciaisParcialValidator')">
          A razão social não pode possuir caracteres inválidos
        </mat-error>

    </mat-form-field>  
  
    <!-- SEGUNDA LINHA -->
    <div class="row">
  
      <mat-form-field appearance="outline" 
                      class="col-md-4">

        <mat-label> CNPJ </mat-label>

        <input type="text" 
               formControlName="CNPJ" 
               [value]="formulario.value.CNPJ | cnpj"
               matInput
               maxLength="18">

        <mat-error *ngIf="formulario.controls.CNPJ.hasError('required')">
          O CNPJ deve ser informado
        </mat-error>

        <mat-error *ngIf="formulario.controls.CNPJ.hasError('cnpjFormatValidator') && !formulario.controls.CNPJ.hasError('required')">
          O CNPJ deve possuir um formato válido
        </mat-error>

        <mat-error *ngIf="formulario.controls.CNPJ.hasError('cnpjValidator') && !formulario.controls.CNPJ.hasError('cnpjFormatValidator') && !formulario.controls.CNPJ.hasError('required')">
          CNPJ inválido
        </mat-error>

        <mat-error *ngIf="formulario.controls.CNPJ.hasError('cnpjJaInformadoValidator') && !formulario.controls.CNPJ.hasError('required')">
          CNPJ já informado para este cliente
        </mat-error>

        <mat-error *ngIf="formulario.controls.CNPJ.hasError('cnpjJaCadastradoValidator') && !formulario.controls.CNPJ.hasError('cnpjJaInformadoValidator') && !formulario.controls.CNPJ.hasError('required')">
          CNPJ já cadastrado parao outro cliente
        </mat-error>

        

      </mat-form-field>
        
      <mat-form-field appearance="fill" 
                      class="col-md-2" 
                      style="margin-top: 4px">

        <mat-label> Status </mat-label>

        <mat-select formControlName="Status" >
          <mat-option *ngFor="let option of statusOptions" 
                      [value]="option">
            {{option}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="formulario.controls.Status.hasError('required')">
          Informe o status
        </mat-error>
      </mat-form-field>
    
      <mat-form-field appearance="fill" 
                      class="col-md-6" 
                      style="margin-top: 4px">
        <mat-label> Tipo de negócio </mat-label>
        <mat-select formControlName="TipoDeNegocio">
          <mat-option *ngFor="let option of listaTipoNegocio" 
                      [value]="option">
            {{option}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="formulario.controls.TipoDeNegocio.hasError('required')">
          Informe o tipo de negócio
        </mat-error>
      </mat-form-field>    
  
    </div>

    <!-- TERCEIRA LINHA -->
    <div class="row">
  
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label> CNAE Principal </mat-label>
        <input type="text"
               maxlength="50"
               formControlName="CNAE" 
               matInput>

        <mat-error *ngIf="formulario.controls.CNAE.hasError('required')">
          O CNAE é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>
    
      <mat-form-field appearance="fill" class="col-md-4">
        <mat-label> Regime de Tributação </mat-label>
        <mat-select formControlName="RegimeTributario" >
          <mat-option *ngFor="let option of listaRegimeTributario" [value]="listaRegimeTributario.indexOf(option)">
            {{option}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="formulario.controls.RegimeTributario.hasError('required')">
          Informe o regime de tributação
        </mat-error>
      </mat-form-field>
    
      <mat-form-field appearance="fill" class="col-md-4">
        <mat-label> Natureza Jurídica </mat-label>
        <mat-select formControlName="NaturezaJuridica" >
          <mat-option *ngFor="let option of listaNaturezaJuridica" [value]="listaNaturezaJuridica.indexOf(option)">
            {{option}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="formulario.controls.NaturezaJuridica.hasError('required')">
          Informe a natureza jurídica
        </mat-error>
      </mat-form-field>
  
    </div> 
  
    <!-- QUARTA LINHA -->
    <div class="row">
  
      <mat-form-field appearance="outline"
                      class="col-md-3">
                        
          <mat-label>CEP</mat-label>      
          <input [readonly]="pesquisaCepRealizada" 
                 type="text" 
                 formControlName="{{'CEP' | cep}}" 
                 [value]="formulario.value.CEP | cep"
                 maxlength="9"
                 matInput>

          <button  *ngIf="!pesquisaCepRealizada && validaPermissao()" 
                   matSuffix 
                   mat-icon-button 
                   aria-label="Clear" 
                   [disabled]="formulario.controls.CEP.hasError('cepValidator')"
                   (click)="buscarEndereco($event)"><mat-icon>search</mat-icon>
          </button>   

          <button *ngIf="pesquisaCepRealizada && validaPermissao()" 
                   matSuffix 
                   mat-icon-button 
                   aria-label="Clear" 
                   (click)="removerEndereco()"> <mat-icon>close</mat-icon>
          </button>

          <mat-error *ngIf="formulario.controls.CEP.hasError('required')">
            Informe o CEP
          </mat-error>
          
          <mat-error *ngIf="!formulario.controls.CEP.hasError('required') && formulario.controls.CEP.hasError('cepValidator')">
            Informe um CEP válido
          </mat-error>
      </mat-form-field>    
    
      <mat-form-field appearance="outline" class="col-md-9">
          <mat-label>
            Endereço
          </mat-label>
          <input [readonly]="pesquisaCepRealizada" 
                 type="text" 
                 maxlength="100"
                 formControlName="Endereco"  
                 matInput>
                 
          <mat-error *ngIf="formulario.controls.Endereco.hasError('required')">
            Informe o endereço
          </mat-error>
  
          <mat-error *ngIf="!formulario.controls.Endereco.hasError('required') && formulario.controls.Cidade.hasError('caracteresEspeciaisParcialValidator')">
            O endereço não pode possuir caracteres inválidos
           </mat-error>
      </mat-form-field>
  
    </div>
  
    <!-- QUINTA LINHA -->
    <div class="row">
  
      <mat-form-field style="padding-top: 4px;" appearance="fill" class="col-md-2">
        <mat-label> UF </mat-label>
        <mat-select formControlName="UF" >
          <mat-option *ngFor="let uf of listaUF" [value]="uf">
            {{uf}}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="formulario.controls.UF.hasError('required')">
          Informe a UF
        </mat-error>

      </mat-form-field>
  
      <mat-form-field appearance="outline" class="col-md-5">
        <mat-label> Cidade </mat-label>
        <input [readonly]="pesquisaCepRealizada" 
               type="text" 
               maxlength="50"
               formControlName="Cidade" 
               matInput>

              <mat-error *ngIf="formulario.controls.Cidade.hasError('required')">
                Informe a cidade
              </mat-error>

              <mat-error *ngIf="!formulario.controls.Cidade.hasError('required') && formulario.controls.Cidade.hasError('somenteLetraValidator')">
                A cidade possui caracteres inválidos
              </mat-error>

      </mat-form-field>
  
      <mat-form-field appearance="outline" class="col-md-5">
        <mat-label> Bairro </mat-label>
        <input [readonly]="pesquisaCepRealizada" 
               type="text" 
               maxlength="50"
               formControlName="Bairro" 
               matInput>

              <mat-error *ngIf="formulario.controls.Bairro.hasError('required')">
                Informe o bairro
              </mat-error>

              <mat-error *ngIf="!formulario.controls.Bairro.hasError('required') && formulario.controls.Bairro.hasError('somenteLetraValidator')">
                O bairro possui caracteres inválidos
              </mat-error>

      </mat-form-field>  
  
    </div>
  
    <!-- SEXTA LINHA -->
    <div class="row">
  
      <mat-form-field appearance="outline" class="col-md-5">
        <mat-label> Região </mat-label>
        <input [readonly]="pesquisaCepRealizada" 
               type="text" 
               maxlength="70"
               formControlName="Regiao" 
               matInput>

              <mat-error *ngIf="formulario.controls.Regiao.hasError('required')">
                Informe a região
              </mat-error>

              <mat-error *ngIf="!formulario.controls.Regiao.hasError('required') && formulario.controls.Regiao.hasError('caracteresEspeciaisValidator')">
                A região não pode possuir caracteres especiais
              </mat-error>

      </mat-form-field>
  
      <mat-form-field appearance="outline" class="col-md-7">
        <mat-label> Complemento </mat-label>
        <input type="text" 
               maxlength="50"
               formControlName="Complemento" 
               matInput>

              <mat-error *ngIf="formulario.controls.Complemento.hasError('caracteresEspeciaisParcialValidator')">
                O complemento não pode possuir caracteres inválidos
              </mat-error>

      </mat-form-field>
          
    </div>      
    
  </form>

</mat-dialog-content>

<mat-dialog-actions class="botoes-crud">
  <button class="botao-fechar" mat-raised-button color="warn" style="margin-left: 1%;" (click)="fecharJanela()">Fechar</button>
  <button class="botao-confirm" 
          [disabled]="formulario.status == 'INVALID'"
          mat-raised-button 
          *ngIf="validaPermissao()"
          (click)="adicionarEmpresa()">{{labelBtnAdicionar}}
  </button>
</mat-dialog-actions>

