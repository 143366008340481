import { SemPermissaoModule } from './sem-permissao/sem-permissao.module';
import { SemPermissaoComponent } from './sem-permissao/sem-permissao.component';
import { PagesGuard } from './pages-guard.guard';
import { AlterarSenhaModule } from './alterar-senha/alterar-senha.module';
import { OperacoesModule } from './operacoes/operacoes.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PagesComponent } from './pages.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CadastrosModule } from './cadastros/cadastros.module';
import { CadastrosComponent } from './cadastros/cadastros.component';
import { PagesRoutingModule } from './pages-routing.module';
import { SegurancaModule } from './seguranca/seguranca.module';
import { SegurancaRoutingModule } from './seguranca/seguranca-routing.module';



@NgModule({
  declarations: [
    PagesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    PagesRoutingModule,
    CadastrosModule,
    SegurancaModule,
    OperacoesModule,
    AlterarSenhaModule,
    SemPermissaoModule
  ],
  providers: [
    DatePipe,
    PagesGuard
  ],
  exports: [PagesComponent]
})
export class PagesModule { }
