import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NivelPermissao } from 'src/app/consts';
import { FormValidations } from 'src/app/form-validations/form-validations';
import { CepPipe } from 'src/app/pipes/cep.pipe';
import { CnpjPipe } from 'src/app/pipes/cnpj.pipe';
import { ClienteService } from 'src/app/services/cliente.service';
import { ExternosService } from 'src/app/services/externos.service';

@Component({
  selector: 'app-empresa-cliente',
  templateUrl: './empresa-cliente.component.html',
  styleUrls: ['./empresa-cliente.component.css']
})
export class EmpresaClienteComponent implements OnInit, AfterViewInit {

  public formulario: FormGroup;
  public permissao: NivelPermissao;

  public statusOptions: any[] = ["Ativo", "Inativo"]

  public pesquisaCepRealizada: boolean = false;

  // VARIÁVEIS DOS CAMPOS DE SELECT
  public listaUF: string[] = [
    'RO',
    'AC',
    'AM',
    'RR',
    'PA',
    'AP',
    'TO',
    'MA',
    'PI',
    'CE',
    'RN',
    'PB',
    'PE',
    'AL',
    'SE',
    'BA',
    'MG',
    'ES',
    'RJ',
    'SP',
    'PR',
    'SC',
    'RS',
    'MS',
    'MT',
    'GO',
    'DF'
  ].sort();
  public listaTipoNegocio: String[] = [
    'MERCADO',
    'PAPELARIA',
    'HOLDING E ADMINISTRADORA DE BENS',
    'PRODUTOS ALIMENTÍCIOS',
    'SERVIÇOS ADMINISTRATIVOS',
    'BAR, LANCHONETE, RESTAURANTE E AFINS',
    'HORTIFRUTI',
    'FARMÁCIA E AFINS',
    'PADARIA',
    'AÇOUGUE, FRIGORÍFICO, CARNES E AFINS',
    'COSMÉTICOS E PERFUMARIA',
    'TRANSPORTE E LOGÍSTICA',
    'REFEIÇÕES',
    'MATERIAIS DE CONSTRUÇÃO',
    'DEPÓSITO',
    'GESTÃO FINANCEIRA',
    'ESTACIONAMENTO',
    'AGÊNCIA DE VIAGENS',
    'DOCES E AFINS',
    'EMBALAGENS',
    'SERVIÇOS MÉDICOS E AMBULATORIAIS',
    'VESTUÁRIO E ACESSÓRIOS',
    'RELÓGIOS E JOALHERIA',
    'CONSULTORIA',
    'CERVEJARIA',
    'CEREAIS, TEMPEROS, MOLHOS E CONDIMENTOS',
    'LOJA DE DEPARTAMENTOS',
    'ELETRODOMÉSTICOS',
    'REFRIGERAÇÃO',
    'AUTO PEÇAS',
    'ACADEMIA',
    'AÇOUGUE',
    'SERVIÇOS DE ENTREGA',
    'PRODUTOS ELÉTRICOS',
    'GRÁFICA E EDITORA',
    'BEBIDAS',
    'EVENTOS',
    'ÓTICA',
    'GÁS',
    'PARTICIPAÇÕES (EXCETO HOLDING)',
    'VENDAS E REPRESENTAÇÕES',
    'PRESTADORA DE SERVIÇOS',
    'PRODUTOS HOSPITALARES',
    'AGROPECUÁRIA',
    'MÓVEIS',
    'ILUMINAÇÃO, EQUIPAMENTOS ELÉTRICOS E AFINS',
    'PRODUTOS DE LIMPEZA E HIGIENE E AFINS',
    'ALIMENTAÇÃO PARA EVENTOS',
    'FERRAGENS E FERRAMENTAS',
    'TELEFONIA E COMUNICAÇÃO',
    'FABRICAÇÃO DE PÃO',
    'CONSTRUÇÃO CIVIL',
    'CASA LOTÉRICA',
    'CONTABILIDADE',
    'INCORPORADORA',
    'INFORMÁTICA',
    'LATICÍNIO'
  ].sort()
  public listaRegimeTributario: any[]
  public listaNaturezaJuridica: any[]

  public labelBtnAdicionar: string = "Adicionar";

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<EmpresaClienteComponent>,
              private clienteService: ClienteService,
              private externosService: ExternosService,
              private httpClient: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data,
  ) {
    
    this.preencheSelects()
  }
  ngAfterViewInit(): void {
    if(this.data.permissao){
      this.verificaPermissao(this.data.permissao);
    }

    if(this.data.empresa){
      this.preencheEmpresa(this.data.empresa);
    }    
  }

  ngOnInit(): void {

    this.criarFormulario();
        
  }

  public criarFormulario(): void{
    this.formulario = this.formBuilder.group({
      Id: 0,
      NomeCliente: [''],
      idCliente: [''],
      RazaoSocial: ["", [Validators.required, FormValidations.caracteresEspeciaisParcialValidator]],
      Status: ["", [Validators.required]],
      CNPJ: ["", [Validators.required, FormValidations.cnpjFormatValidator, FormValidations.cnpjValidator, FormValidations.cnpjJaInformadoValidator(this.data?.listaCnpjInformados)], [FormValidations.cnpjJaCadastradoValidator(this.data?.empresa?.Id, this.httpClient)]],
      TipoDeNegocio: ["", [Validators.required]],
      CEP: ["", [Validators.required, FormValidations.cepValidator]],
      Endereco: ["", [Validators.required, FormValidations.caracteresEspeciaisParcialValidator]],
      Complemento: [''],
      Bairro: ['', [Validators.required, FormValidations.somenteLetraValidator]],
      Cidade: ['', [Validators.required, FormValidations.somenteLetraValidator]],
      UF: ['', [Validators.required]],
      Regiao: ['', [Validators.required, FormValidations.somenteLetraValidator]],
      CNAE: ['', [Validators.required]],
      RegimeTributario: ['', [Validators.required]],
      NaturezaJuridica: ['', [Validators.required]]
    })
  }
 
  public adicionarEmpresa(): void {
    this.dialogRef.close(this.formulario.value)
  }

  public fecharJanela(): void {
    this.dialogRef.close()
  }

  public preencheEmpresa(empresa): void {

    this.labelBtnAdicionar = "Alterar"

    this.formulario.patchValue({
      Id: empresa.Id,
      NomeCliente: empresa.NomeCliente,
      idCliente: empresa.idCliente,
      RazaoSocial: empresa.RazaoSocial,
      Status: empresa.Status,
      CNPJ: empresa.CNPJ,
      TipoDeNegocio: empresa.TipoDeNegocio,
      CEP: empresa.CEP,
      Endereco: empresa.Endereco,
      Complemento: empresa.Complemento,
      Bairro: empresa.Bairro,
      Cidade: empresa.Cidade,
      UF: empresa.UF,
      Regiao: empresa.UF,
      CNAE: empresa.CNAE,
      RegimeTributario: empresa.RegimeTributario,
      NaturezaJuridica: empresa.NaturezaJuridica,
    })
  }

  async preencheSelects(): Promise<void> {

    await firstValueFrom(this.clienteService.getRegimeTributario())
      .then((r: any) => this.listaRegimeTributario = r)

    await firstValueFrom(this.clienteService.getNaturezaJuridica())
      .then((r: any) => this.listaNaturezaJuridica = r)

  }
  
  public buscarEndereco(event): void{

    event.preventDefault();

    this.externosService.getViaCep(this.formulario.value.CEP)
      .pipe(
        catchError(() => of({error: true})),
        tap((r: any) => {
          if(!r.error){
            this.formulario.patchValue({
              Endereco: r.logradouro,
              Complemento: r.complemento,
              Bairro: r.bairro,
              Cidade: r.localidade,
              UF: r.uf
            })
          }
        }),
        tap((r: any) => console.log(r.ibge)),
        switchMap((r: any) => this.externosService.getIBGE(r.ibge))
      )
      .pipe(map((r: any) => r.microrregiao?.mesorregiao?.nome))
      .subscribe(
        {next: r => {
                  if(typeof(r) == "string"){
                    this.formulario.patchValue({Regiao: r})
                    this.pesquisaCepRealizada = true
                  } else {
                    this.pesquisaCepRealizada = false
                  }               
               }
        },
        {error: () => this.pesquisaCepRealizada = false}

      )
    
  }

  public removerEndereco(): void{
       
    this.pesquisaCepRealizada = false;

    this.formulario.patchValue(
      {
        CEP: "",
        Endereco: "",
        Complemento: "",
        Bairro: "",
        Cidade: "",
        UF: "",
        Regiao: ""
      }
    )

  }

  public validaPermissao(): boolean{

    switch(this.permissao){

      case NivelPermissao.VISUALIZAR: return false;
      case NivelPermissao.GRAVAR: return true;
      case NivelPermissao.EXCLUIR: return true;

    }
  }

  public verificaPermissao(permissao: NivelPermissao): void{

    if(permissao == NivelPermissao.VISUALIZAR){
      this.formulario.disable()
    }

    this.permissao = permissao;
  }

  public limparValidacoes(): void{
    Object.keys(this.formulario.controls).forEach(key => {
      this.formulario.get(key).setErrors(null) ;
    });
  }


}



