<form [formGroup]="formularioCategorias">
    <div class="row">
        <div class="col-sm-6 col-12">
            <mat-form-field class="f-14" appearance="fill" style="width: 100%">
                <mat-label>Cliente</mat-label>
                <input formControlName="Cliente" matInput type="text" readonly>
            </mat-form-field>
        </div>

        <div class="col-sm-6 col-12">
            <mat-form-field class="f-14" appearance="fill" style="width: 100%">
                <mat-label>Quantidade de sugestões para revisão</mat-label>
                <input formControlName="QuantidadeSugestao" matInput type="text" readonly>
            </mat-form-field>
        </div>
    </div>
    <table style="width: 100%; height: 100%;" mat-table [dataSource]="dataSourceSugestoes" matSort>

        <ng-container matColumnDef="Categoria">
            <th class="w-40" mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
            <td class="f-12" mat-cell *matCellDef="let element">{{ element.Categoria }}</td>
        </ng-container>

        <ng-container matColumnDef="QuantidadeProduto">
            <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Quantidade de produtos</th>
            <td class="f-12" style="text-align:center;" mat-cell *matCellDef="let element">{{ element.QuantidadeProduto }}</td>
        </ng-container>


        <ng-container matColumnDef="Ncm">
            <th class="w-10" mat-header-cell *matHeaderCellDef>Ncm</th>
            <td class="f-12" mat-cell *matCellDef="let element">{{element.Ncm}}</td>
        </ng-container>

        <ng-container matColumnDef="Detalhar">
            <th class="w-10" mat-header-cell *matHeaderCellDef></th>
            <td class="f-12" mat-cell *matCellDef="let element">
                <button mat-raised-button color="primary" (click)="detalheProduto(element)">Detalhe</button>
            </td>
        </ng-container>

        <tr style="height: 40px;" mat-header-row *matHeaderRowDef="ColunasCategorias"></tr>
        <tr style="height: 60px;" mat-row *matRowDef="let row; columns: ColunasCategorias"></tr>
    </table>
</form>