<div class="auth-page">
    <div class="auth-page__content-block">
        <div class="auth-page__content-wrapper">
            <div class="auth-page__group">
                <div class="auth-page__group-title">
                    <img src="./assets/login/Logo - Como Tributar.png">
                </div>
                <div class="auth-page__border-wrapper">
                    <div class="auth-page__border-line"></div>
                </div>
                <app-login-form (sendLoginForm)="sendLoginForm($event)"></app-login-form>
            </div>
            <p class="auth-page__footer-title">© 2014-{{todayDate | year}} <a target="_blank" href="https://www.focuscontabil.com/">Focus Contábil</a>, LLC. All rights reserved.</p>
        </div>
    </div>

    <div class="auth-page__logo">
        <div class="auth-page__logo-wrapper">
        </div>
    </div>
</div>