<table style="width: 100%; margin-top: 0;" mat-table [dataSource]="dataSource">     
  
    <ng-container matColumnDef="TipoMensagem">
      <th style="height: 1px;" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon style="font-size: 20px; margin-top: 5px; color: green;" *ngIf="element.TipoMensagem == 0">check_circle</mat-icon>
        <mat-icon style="font-size: 20px; margin-top: 5px; color: #c5c513;" *ngIf="element.TipoMensagem == 1">report</mat-icon>
        <mat-icon style="font-size: 20px; margin-top: 5px; color: red;" *ngIf="element.TipoMensagem == 2">cancel</mat-icon>
      </td>
    </ng-container>
  
    <!-- COLUNA DA MENSSAGEM -->
    <ng-container matColumnDef="DescricaoErro">
      <th style="height: 1px;" mat-header-cell *matHeaderCellDef></th> 
      <td style="margin-top: 10px;" mat-cell *matCellDef="let element"><p style="margin-top: 7px; word-wrap: break-word;"> {{element.Descricao}} </p></td>
    </ng-container>
  
    <tr (click)="escolherMensagem(row)" mat-row style="cursor: pointer" class="example-element-row" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  <div *ngIf="mensagemEscolhida" style="margin-top: 10px; margin-bottom: 0;">
  
    <div *ngIf="mensagemEscolhida.TipoMensagem == 0" class="alert alert-success" role="alert">
      <p> Código da mensagem: {{mensagemEscolhida.Codigo}}</p>
      <hr>
      <p style="max-width: 300px; word-wrap: break-word;"> {{mensagemEscolhida.MensagemComplementar}} </p>
    </div>
  
    <div *ngIf="mensagemEscolhida.TipoMensagem == 1" class="alert alert-warning" role="alert">
      <p> Código da mensagem: {{mensagemEscolhida.Codigo}}</p>
      <hr>
      <p style="max-width: 300px; word-wrap: break-word;"> {{mensagemEscolhida.MensagemComplementar}} </p>
    </div>
  
    <div *ngIf="mensagemEscolhida.TipoMensagem == 2" class="alert alert-danger" role="alert">
      <p> Código da mensagem: {{mensagemEscolhida.Codigo}}</p>
      <hr>
      <p style="max-width: 300px; word-wrap: break-word;"> {{mensagemEscolhida.MensagemComplementar}} </p>
    </div>
  
  </div>

  <button style="width: 100%;" (click)="fecharJanela()" mat-raised-button color="warn"> Fechar </button> 
