import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.hmr';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  API = environment.API

  constructor(private httpCliente: HttpClient) {

  }

  public get_cliente_usuario_logado(): Observable<any>{
    return this.httpCliente.get<any>(`${this.API}Cliente`)
  }

  get(id) {
    return this.httpCliente.get(`${this.API}Cliente/${id}`)
  }

  post(corpo) {
    return this.httpCliente.post(`${this.API}Cliente`, corpo)
  }

  delete(id) {
    return this.httpCliente.delete(`${this.API}Cliente/${id}`)
  }

  getNaturezaJuridica() {
    return this.httpCliente.get(`${this.API}Cliente/GetNaturezaJuridica`)
  }
  
  getRegimeTributario() {
    return this.httpCliente.get(`${this.API}Cliente/GetRegimeTributario`)
  }
}
