<mat-card>
    <mat-card-title class="matCard-Font">Cadastro de usuários</mat-card-title>
</mat-card>

<mat-card>
    <mat-card-subtitle>Dados do Usuário</mat-card-subtitle>
    <form [formGroup]="formulario">

        <div class="row">
            <div class="col-sm-4 col-12">
                <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                    <mat-label>Usuário</mat-label>
                    <input formControlName="Nome" matInput type="text">
                    <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarUsuario()">
            <mat-icon>search</mat-icon>
          </button>
                </mat-form-field>
                <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                    <mat-label>Login</mat-label>
                    <input formControlName="Login" matInput type="text" [readonly]="formulario.value.Id != 0">
                </mat-form-field>
                <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;" *ngIf="formulario.value.Id == 0">
                    <mat-label>Senha</mat-label>
                    <input formControlName="Senha" matInput type="password">
                    <mat-hint>Mín. 8 caracteres</mat-hint>
                </mat-form-field>
            </div>

            <div class="col-sm-4 col-12">

                <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                    <mat-label>E-mail</mat-label>
                    <input formControlName="Email" matInput type="text">
                </mat-form-field>
                <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                    <mat-label>Cliente</mat-label>
                    <input formControlName="Cliente" matInput type="text" readonly>
                    <button *ngIf="formulario.value.Cliente == null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCliente()">
            <mat-icon>search</mat-icon>
          </button>
                    <button *ngIf="formulario.value.Cliente != null" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparCliente()">
            <mat-icon>close</mat-icon>
          </button>
                </mat-form-field>
                <mat-checkbox formControlName="Bloqueado">Bloqueado</mat-checkbox>
            </div>
        </div>

    </form>
</mat-card>

<app-auditoria *ngIf="formulario.value.Id != 0" [DataCadastro]="formulario.value.DataCadastro" [DataAlteracao]="formulario.value.DataAlteracao" [UsuarioCadastro]="formulario.value.UsuarioCadastro" [UsuarioModificacao]="formulario.value.UsuarioAlteracao"></app-auditoria>

<app-crud-buttons [showPost]="formulario.value.Id == 0" [showPut]="formulario.value.Id != 0" [showDelete] [labelBotaoPost]="'Gravar'" [labelBotaoPut]="'Gravar'" [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="formulario.status == 'INVALID'" [disablePut]="formulario.status == 'INVALID'"
    [disableDelete]="formulario.value.Id == 0" (OnClickButtonPost)="post()" (OnClickButtonPut)="post()" (OnClickButtonAuxUm)="limpar()" (OnClickButtonDelete)="delete()">
</app-crud-buttons>